import { Form, Input, Select, Button, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";

const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const Employment = () => {
  const [employerName, setEmployerName] = useState("");
  const [employementSector, setEmployementSector] = useState("");
  const [occupation, setOccupation] = useState("");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        // const personDetail = displaySingpassData.singpass_data[0].person;
        // const entityDetail = displaySingpassData.singpass_data[0].entity;

        const personDetail = displaySingpassData.singpass_data.person;
        const entityDetail = displaySingpassData.singpass_data.entity;
        console.log("entityDetail value:", personDetail);
        setEmployerName(personDetail.employment.value);
        setEmployementSector(personDetail.employmentsector.value);
        setOccupation(personDetail.occupation.value);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("value occupation:", occupation);
  });

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={isMobile ? 24 : 12}>
          <Form.Item label="Employer Name">
            <Input
              type="text"
              style={{ width: "95%" }}
              value={employerName}
              onChange={(value) => setEmployerName(value)}
              disabled={!!employerName || !employerName}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 12}>
          <Form.Item
            style={{ marginLeft: isMobile ? "0px" : "5%" }}
            label="Occupation"
          >
            <Input
              type="text"
              style={{ width: "95%" }}
              value={occupation}
              onChange={(value) => setOccupation(value)}
              disabled={!!occupation || !occupation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={isMobile ? 24 : 12}>
          <Form.Item label="Employment Sector">
            <Input
              type="text"
              style={{ width: "95%" }}
              value={employementSector}
              onChange={(value) => setEmployementSector(value)}
              disabled={!!employementSector || !employementSector}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div>

      {/* <Form.Item {...tailLayout}>
            <Button type="default" htmlType="button">
              Edit
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "10px" }}
            >
              Save
            </Button>
          </Form.Item> */}
    </Form>
  );
};

export default Employment;
