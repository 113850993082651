import { Space, Table, Tag, Radio, Button } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";

const TableAdminOngoingLoan = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [loanIdFilters, setLoanIdFilters] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/ongoingLoan/viewAllOngoingLoan",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const loans = json.ongoing_loan.map((item) => item.loan[0]); // Flattening the nested loan array
        setData(loans);

        const uniqueLoanIds = new Set();
        loans.forEach((loan) => {
          uniqueLoanIds.add(loan.loan_id);
        });

        const filters = Array.from(uniqueLoanIds).map((loanId) => ({
          text: loanId,
          value: loanId,
        }));

        setLoanIdFilters(filters);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Loan ID
        </span>
      ),
      dataIndex: "loan_id",
      key: "loan_id",
      // filters: loanIdFilters,
      // filterMode: 'tree',
      // filterSearch: true,
      // onFilter: (value, record) => record.loan_id.startsWith(value),
      // width: '24%',
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Lender Name
        </span>
      ),
      dataIndex: "lender_name",
      key: "lender_name",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Borrower Name
        </span>
      ),
      dataIndex: "borrower_name",
      key: "borrower_name",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Period
        </span>
      ),
      dataIndex: "period",
      key: "period",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text + " Months"}` : "-"}
        </span>
      ),
    },

    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Repay Total Amount
        </span>
      ),
      dataIndex: "repay_total_amount",
      key: "repay_total_amount",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Repay Principal
        </span>
      ),
      dataIndex: "repay_principal",
      key: "repay_principal",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Repay Interest
        </span>
      ),
      dataIndex: "repay_interest",
      key: "repay_interest",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Repay Due Date
        </span>
      ),
      dataIndex: "repay_due_date",
      key: "repay_due_date",
      fixed: "right",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >{`${formatDate(text)}`}</span>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="loan_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "100%",
        }}
        scroll={{
          x: 1200,
        }}
      />
    </div>
  );
};

export default TableAdminOngoingLoan;
