import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import TinyLineChart from "../../components/TinyLineChart";
import HalfPieChart from "../../components/HalfPieChart";
import { Modal, message, Button } from "antd";
import Cookies from "js-cookie";
import { format } from "date-fns";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import applyLoan from "../../assets/img/apply-loan.png";
import defaultIcon from "../../assets/img/Profile.png";

const ContainerActivity2 = ({ noData }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "12.5px",
      border: "1px solid none",
      borderRadius: "20px",
      backgroundColor: "#FFFFFFB3",
      height: "60px",
    }}
  >
    <p
      style={{
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "18px",
        color: "#142C44CC",
        textAlign: "center",
        paddingTop: "15px",
      }}
    >
      {noData}
    </p>
  </div>
);

const OngoingLoans = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [data, setData] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataClick, setDataClick] = useState([]);
  const history = useHistory();
  const [loanBalance, setLoanBalance] = useState(null);
  const [periodLeft, setPeriodLeft] = useState(null);
  const [makePayment, setMakePayment] = useState();
  const [hovered3, setHovered3] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [ppicture, setppicture] = useState(null);

  const getProfilePicture = async (fileName) => {
    console.log(fileName);
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getProfilePicture?profile_picture=${fileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      setppicture(imageUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const viewAgreement = (loanId, event) => {
    console.log("loanId viewAgreement", loanId);
    event.preventDefault();
    history.push("/b-agreements", { loanId: loanId });
  };

  const handleApplyLoan = (event) => {
    event.preventDefault();
    history.push("/b-apply-loan");
  };
  const reformatDateString = (originalDateString) => {
    // Create a Date object from the original date string
    const originalDate = new Date(originalDateString);

    // Define options for formatting the date
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format the date into "Mon dd, yyyy" format
    const formattedDate = originalDate.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const Container = ({
    icon,
    item,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    index,
    loan_id,
    open_modal,
    cancel_modal,
    onClickModal,
  }) => {
    const shouldAddMarginTop = index > 3;
    const [hovered, setHovered] = useState(false);

    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "calc(33% - 10px)" : "calc(50% - 10px)",
            textAlign: "center",
            marginRight: "0px",
            border: "1px solid none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            padding: "20px",
          }}
          onClick={(event) => handleRowClick(loan_id, event, item.lender_id)}
        >
          {title || subtitle ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // margin: "0px 15px 0px 15px",
              }}
            >
              {/* <img src={icon} alt={title} style={{ width: "55px", height: "55px", borderRadius: "1000px", border: "2px solid #f0f0f0" }} /> */}
              <div
                style={{
                  marginLeft: "15px",
                  marginBottom: "10px",
                  marginTop: "7px",
                  marginRight: "1px",
                }}
              >
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "left",
                    marginTop: "0px",
                  }}
                >
                  {title}
                </h3>

                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "200",
                    fontSize: "11px",
                    textAlign: "left",
                    marginTop: "-10px",
                    opacity: ".6",
                  }}
                >
                  {subtitle}
                </p>
              </div>
            </div>
          ) : null}
          <div
            style={{
              textAlign: "left",
            }}
          >
            {screenWidth < 800 ? (
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  width: "60px",
                  textAlign: "left",
                  margin: "0px 0px -8px 0px",
                }}
              >
                Loan Value
              </p>
            ) : null}
            {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6", marginTop: "-3px" }}>{label}</p> */}
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "20px" : "40px",
                textAlign: "left",
                color: "#142C44",
                margin: "0px 0px 10px 0px",
                lineHeight: "45px",
              }}
            >
              {description}
            </p>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                flexDirection: screenWidth < 800 ? "column" : "row",
              }}
            >
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  width: "60px",
                  textAlign: "left",
                  margin: screenWidth < 800 ? "0px 0px -2px 0px" : "0px",
                }}
              >
                {label1}
              </p>
              <p
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                }}
              >
                {description1}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "-20px",
                flexDirection: screenWidth < 800 ? "column" : "row",
              }}
            >
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  width: "60px",
                  textAlign: "left",
                  margin: screenWidth < 800 ? "8px 0px -2px 0px" : "0px",
                }}
              >
                {label2}
              </p>
              <p
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                }}
              >
                {description2}
              </p>
            </div>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                margin: "0px",
              }}
            >
              {label4}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div style={{ textAlign: "left", marginTop: "-22px" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    margin: "40px 0px 0px 0px",
                  }}
                >
                  {label3}
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "#142C44CC",
                    margin: "0px 0px 0px 0px",
                  }}
                >
                  {reformatDateString(description3)}
                </p>
              </div>
              {/* <a href={`b-agreements?loan_id=${loan_id}`} style={{ textDecoration: "none", color: "#0E5AA5" }}>View Agreement</a> */}
            </div>{" "}
            <button
              className="apply_now_button"
              onClick={(event) => viewAgreement(loan_id, event)}
              style={{
                color: "#142C44",
                background: "transparent",
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "Poppins, sans-serif",
                padding: "5px 25px",
                border: "1px solid grey",
                borderRadius: "10px",
                margin: "20px 0px 0px 0px",
              }}
            >
              View Agreement
            </button>
          </div>
        </div>
      </>
    );
  };

  const Container4 = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    index,
    loan_id,
    open_modal,
    cancel_modal,
    onClickModal,
    marginTop,
  }) => {
    const [hovered, setHovered] = useState(false);

    return (
      <>
        <div
          style={{
            width: screenWidth > 800 ? "32.5%" : "calc(50% - 5px)",
            textAlign: "center",
            border: "1px solid none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "10px",
            padding: "20px",
            alignItems: "center",
          }}
        >
          <img
            src={applyLoan}
            alt="Icon"
            style={{ width: "25%", height: "auto", margin: "20px 0" }}
          />
          <button
            className="all_button"
            style={{
              borderRadius: "20px",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Poppins, sans-serif",
              padding: "5px 25px",
              background: hovered3
                ? "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)"
                : "transparent",
              outline: hovered3 ? "2px solid transparent" : "2px solid grey",
              border: "none",
              color: hovered3 ? "white" : " grey",
              transition: "0.3s all ease-in-out",
            }}
            onClick={(event) => handleApplyLoan(event)}
            onMouseEnter={() => setHovered3(true)} // set the state hovered to true  when mouse enter
            onMouseLeave={() => setHovered3(false)} // set the state hovered to false  when mouse leave
          >
            Apply Loan
          </button>
        </div>
      </>
    );
  };

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "12px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "rgba(20, 44, 68, 0.8)",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  const handleRowClick = async (record, event, lender_id) => {
    console.log("record value", record);
    const loanId = record;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/ongoingLoan/viewOngoingLoanDetail?loan_id=${loanId}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setDataClick(json["ongoing_loan_detail"]);
      setLoanBalance(json["loan_balance"]);
      setPeriodLeft(json["period_left"]);
    } catch (error) {
      console.error("Error retrieving Ongoing Loan Detail:", error);
    }
  };

  //   const handleSelectionChange = (selectedBorrowerIds) => {
  //     console.log('Selected loan IDs:', selectedBorrowerIds);
  //     setselectedBorrowerIds(selectedBorrowerIds);
  //   };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/ongoingLoan/viewOngoingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        let loanData = data["Ongoing Loan"].reduce((acc, curr) => {
          return acc.concat(curr.loan);
        }, []);

        setData(loanData);
        console.log(loanData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [refreshData]);

  const [modalStates, setModalStates] = useState({});

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.repay_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const [open, setOpen] = useState(false);

  const showModal = (repay_id, event) => {
    event.preventDefault();
    console.log("repay_id modal", repay_id);
    setModalStates((prevStates) => ({
      ...prevStates,
      [repay_id]: true,
    }));
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const handleMakePayment = async (repay_id, lender_id) => {
    console.log("value of repay_id", repay_id);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/makePayment?repay_id=${repay_id}&lender_id=${lender_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setMakePayment(data);

      // Display the message from the Ant Design library
      message.success(data.message);
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/ongoingLoan/viewOngoingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        let loanData = data["Ongoing Loan"].reduce((acc, curr) => {
          return acc.concat(curr.loan);
        }, []);

        setData(loanData);
        console.log(loanData);
      } catch (error) {
        console.error(error);
      }
      // If the payment was successful
      if (
        data.message === "Payment made successfully!" ||
        "Payment has already been made for this repayment."
      ) {
        setRefreshData((prevState) => !prevState); // Toggle the state
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        <div
          style={{
            width: "55px",
            height: "55px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "5px",
          }}
        >
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              fontSize: "18px",
              textAlign: "left",
              color: "rgba(20, 44, 68, 0.8)",
              margin: "0px 0px 0px 0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px 0px 0px 0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginRight: "0px",
        borderRadius: "20px",
      }}
    >
      <TinyLineChart title="$12,000" subtitle="Net profit to date" />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "20px",
      }}
    >
      <HalfPieChart title="$802,000" subtitle="Forecast collection" />
    </div>
  );

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateLoanStartDate = (
    loanPeriodInMonths,
    periodLeftInMonths,
    paymentDueDate
  ) => {
    console.log(loanPeriodInMonths, periodLeftInMonths, paymentDueDate);
    const dueDate = new Date(paymentDueDate);

    // Calculate the start date
    const startDate = new Date(
      dueDate.getFullYear(),
      dueDate.getMonth() - (loanPeriodInMonths - periodLeftInMonths),
      dueDate.getDate()
    );

    console.log(startDate);

    return startDate;
  };

  return (
    <Box m="4px 20px 20px 20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          height={isMobile ? "78vh" : "88.7vh"}
          borderRadius="10px"
          border="0px solid "
          opacity="0.95"
          style={{
            overflowY: "scroll",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          }}
        >
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            border-radius="20px"
            opacity="0.95"
            style={{ width: "100%", padding: "20px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
              borderRadius="10px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Loan In Progress
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: isMobile ? "auto" : "250px",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "60%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  gap: "15px",
                  marginTop: "15px",
                }}
              >
                {data && data.length > 0 ? (
                  data

                    .sort((a, b) => {
                      if (sortBy === "loan-amount") {
                        return a.offer_amount - b.offer_amount;
                      } else if (sortBy === "date") {
                        return new Date(a.created_at) - new Date(b.created_at);
                      } else {
                        // Default sorting option
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                    })
                    .map((item, index) => (
                      <Container
                        key={index}
                        // icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        // title={item.principal_name || "-"}
                        // subtitle={item.express === 1 ? "Express Applicant" : "Approved Borrower"}
                        label="Loan Amount"
                        description={
                          item.repay_total_amount
                            ? item.repay_total_amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label1="Principal:"
                        description1={
                          item.repay_principal
                            ? item.repay_principal.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label2="Interest:"
                        description2={
                          item.repay_interest
                            ? item.repay_interest.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label3="Due Date:"
                        description3={
                          item.repay_due_date
                            ? formatDate(item.repay_due_date)
                            : "-"
                        }
                        label4={`The details of your loan is shown as above, to see a more detailed view of the loans that are ongoing head over to the desktop view and click on the loans you'd like to view.`}
                        description4={item.offer_interest_rate + " %" || "-"}
                        index={index} // pass the index prop to each Container instance
                        loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                        item={item}
                      />
                    ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      You have no loans in progress
                    </div>
                  </div>
                )}
                {data && data.length > 0 && (
                  <Container4 marginTop={data.length > 3 ? "10px" : "0px"} />
                )}
              </div>
            </Box>
          </Box>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            height="89vh"
            borderRadius="20px"
            border="0px solid white"
            style={{
              background:
                "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <div>
              <p
                style={{
                  margin: "0px",
                  color: "#142C44CC",
                  fontWeight: "800",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "18px",
                }}
              >
                Loan Details
              </p>
            </div>
            <div
              style={{
                background: "rgba(255,255,255,0.7)",
                borderRadius: "10px",
                padding: "20px",
                marginTop: "15px",
                minHeight: "100%",
              }}
            >
              {dataClick && dataClick.length > 0 ? (
                dataClick.map((item) => (
                  <div key={item.id}>
                    <ContainerActivity
                      title={item.lender_name || "No Name"}
                      subtitle={
                        item.category
                          ? item.category.charAt(0).toUpperCase() +
                            item.category.slice(1)
                          : "-"
                      }
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "100%",
                        opacity: "0.3",
                      }}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={warn_ico}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "25px",
                    }}
                  />
                  <div
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "18px",
                      lineHeight: "20px",
                      textAlign: "center",
                      color: "rgba(20, 44, 68, 0.8)",
                      width: "90%",
                      marginTop: "15px",
                    }}
                  >
                    Please Click on Container to View Details
                  </div>
                </div>
              )}
              {!dataClick && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  colors={colors.grey[910]}
                  textAlign="center"
                  margin="1px 1px 1px 30px"
                  flexDirection="column"
                >
                  <ContainerActivity
                    icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                    title="NO VALUE HERE"
                    subtitle="NO VALUE HERE"
                  />
                  <div
                    style={{
                      borderBottom: "2px solid black",
                      width: "100%",
                      opacity: "0.3",
                    }}
                  />
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Loan Amount"
                      label={
                        item.loan_amount
                          ? item.loan_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                    />
                  ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
                marginTop="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Start date"
                      label={formatDate(
                        calculateLoanStartDate(
                          item.loan_period,
                          periodLeft,
                          item.repay_due_date
                        )
                      )}
                    />
                  ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
              >
                {dataClick && dataClick.length > 0 && (
                  <ContainerTransaction
                    title="Loan Outstanding"
                    label={
                      loanBalance
                        ? loanBalance.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })
                        : "-"
                    }
                  />
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Principal Paid So Far"
                      label={
                        item.loan_amount && loanBalance
                          ? (-loanBalance + item.loan_amount).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "SGD",
                              }
                            )
                          : "-"
                      }
                    />
                  ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
                marginTop="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Monthly Installment"
                      label={
                        item.monthly_payment
                          ? item.monthly_payment.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                    />
                  ))}
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
                marginTop="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Interest Rate"
                      label={
                        (item.interest_rate * 100).toFixed(1) + " %" || "-"
                      }
                    />
                  ))}
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
                marginTop="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Loan Period"
                      label={item.loan_period + " Months" || "-"}
                    />
                  ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginLeft="0px"
                marginTop="0px"
              >
                {dataClick && dataClick.length > 0 && (
                  <ContainerTransaction
                    title="Period Left"
                    label={periodLeft + " Months" || "-"}
                  />
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginTop="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => (
                    <ContainerTransaction
                      title="Payment Due Date"
                      label={
                        item.repay_due_date
                          ? formatDate(item.repay_due_date)
                          : "-"
                      }
                    />
                  ))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                marginTop="0px"
              >
                {dataClick &&
                  dataClick.length > 0 &&
                  dataClick.map((item) => {
                    const paymentDueDate = dayjs(item.repay_due_date);
                    const currentDate = dayjs();
                    const daysUntilDue = paymentDueDate.diff(
                      currentDate,
                      "day"
                    );
                    return (
                      <ContainerTransaction
                        title="Next Payment Date"
                        label={
                          <span style={{ color: "#0DC45C" }}>
                            {daysUntilDue +
                              (daysUntilDue === 1 ? " Day" : " Days")}
                          </span>
                        }
                      />
                    );
                  })}
              </Box>
              {dataClick && dataClick.length > 0 ? (
                dataClick.map((item) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        onClick={(event) => showModal(item.repay_id, event)}
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#0DC45C",
                          borderRadius: "20px",
                          color: "#FFFFFF",
                          border: "none",
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                          padding: "8px 30px",
                        }}
                      >
                        Pay Now
                      </button>
                    </div>
                    <Modal
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        margin: "10% auto",
                      }}
                      open={modalStates[item.repay_id]}
                      onCancel={() =>
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.repay_id]: false,
                        }))
                      }
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 16,
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() =>
                              handleMakePayment(item.repay_id, item.lender_id)
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            type="default"
                            onClick={() =>
                              setModalStates((prevStates) => ({
                                ...prevStates,
                                [item.repay_id]: false,
                              }))
                            }
                          >
                            No
                          </Button>
                        </div>,
                      ]}
                    >
                      Are you sure you want to confirm this payment?
                    </Modal>
                  </>
                ))
              ) : (
                <></>
              )}
            </div>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default OngoingLoans;
