import React, { useState, useEffect } from "react";
import { format } from "date-fns";

const LoanDetails = ({ offeredLoanDetail }) => {
  console.log("OfferedLoanDetail", offeredLoanDetail);
  const [loanDetail] = offeredLoanDetail || [{}]; // Destructure the array and assign a default value

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: "15px",
          color: "#142C44CC",
          fontWeight: "800",
          fontFamily: "Manrope, sans-serif",
          fontSize: "18px",
        }}
      >
        Loan Details
      </div>
      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Lender Name
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {loanDetail.lender_name || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Loan ID
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {loanDetail.loan_id || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Loan Amount
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {loanDetail.offer_amount.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Interest Rate (Month)
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {(parseFloat(loanDetail.offer_interest_rate) * 100).toFixed(1) +
                "%" || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Processing Fee
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {(parseFloat(loanDetail.processing_fee) * 100).toFixed(1) + "%" ||
                "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Loan Period (Month)
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {loanDetail.offer_period + " Months" || "-"}
            </p>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "40%",
              }}
            >
              Loan Type
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "15px",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "3px 20px",
                width: "50%",
                borderRadius: "10px",
              }}
            >
              {loanDetail.type.charAt(0).toUpperCase() +
                loanDetail.type.slice(1) || "-"}
            </p>
          </div>
          {/* <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <label
              htmlFor="offer_period"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginRight: 8,
              }}
            >
              Additional File Requested
            </label>
            {loanDetail?.add_document?.map((doc, index) => (
              <p
                key={index}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "grey",
                  margin: 0,
                }}
              >
                {doc.document_name || "-"}
              </p>
            ))}
          </div> */}
        </div>

        <div
          style={{
            flex: 1,
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: "10px",
            width: "50%",
            padding: "15px",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "18px",
              }}
            >
              Loan Estimation
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "50%",
              }}
            >
              Loan Principal
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
              }}
            >
              {loanDetail.offer_amount.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "50%",
              }}
            >
              Processing Fee
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
              }}
            >
              {(
                loanDetail.processing_fee * loanDetail.offer_amount
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) || "-"}{" "}
              {"(" + (parseFloat(loanDetail.processing_fee) * 100).toFixed(1) + "%)"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "50%",
              }}
            >
              Loan Amount Received
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
              }}
            >
              {(
                loanDetail.offer_amount *
                (1 - loanDetail.processing_fee)
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "50%",
              }}
            >
              Total Interest Payable
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
              }}
            >
              {loanDetail.offer_revenue.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) || "-"}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                width: "50%",
              }}
            >
              Expected Monthly Installment
            </label>
            <p
              style={{
                margin: "0px",
                color: "#142C44CC",
                fontWeight: "800",
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
              }}
            >
              {loanDetail.offer_monthly_installment.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) || "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDetails;
