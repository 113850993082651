import { Space, Table, Tag, Radio } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const TableDelayedPayment = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [screenWidth, setScreenWidth] = useState("1920");
  const badStatuses = ["broken", "error"];

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      const selectedLoanIds = selectedRows.map((row) => row.borrower_id);
      onSelectionChange(selectedLoanIds);
    },
    getCheckboxProps: (record) => {
      const rowIndex = data.findIndex((item) => item.key === record.key);
      return {
        disabled: rowIndex < 4, //disable the first 4 rows only
        style: { display: "none" },
      };
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewDelayedPayment",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        setData(json["Delayed Payments"]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Borrower Name
        </span>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >{`${text}`}</span>
      ),
    },

    // {
    //   title: (
    //     <span
    //       style={{
    //         fontFamily: "Poppins, sans-serif",
    //         fontWeight: 700,
    //         fontSize: "13px",
    //         color: "#435669",
    //         margin: "0px",
    //       }}
    //     >
    //       Amount
    //     </span>
    //   ),
    //   dataIndex: "amount",
    //   key: "amount",
    //   render: (text) => (
    //     <span
    //       style={{
    //         fontFamily: "Poppins, sans-serif",
    //         fontWeight: 700,
    //         fontSize: "13px",
    //         color: "#435669",
    //         margin: "0px",
    //       }}
    //     >
    //       {text
    //         ? `${text.toLocaleString("en-US", {
    //             style: "currency",
    //             currency: "SGD",
    //           })}`
    //         : "-"}
    //     </span>
    //   ),
    // },

    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Period
        </span>
      ),
      dataIndex: "period",
      key: "period",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >{`${text} Months`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Unpaid Amount
        </span>
      ),
      dataIndex: "monthly_installment",
      key: "monthly_installment",
      // fixed: 'right',
      render: (text) => (
        <span
          style={{
            color: "#B13434",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            margin: "0px",
          }}
        >{`${text.toLocaleString("en-US", {
          style: "currency",
          currency: "SGD",
        })}`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Late Day(s)
        </span>
      ),
      dataIndex: "late_day",
      key: "late_day",
      // fixed: "right",
      render: (text) => (
        <span
          style={{
            color: "#B13434",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            margin: "0px",
          }}
        >
          {" "}
          {`${text} ${text === 1 ? "Day" : "Days"}`}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="borrower_id"
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        // scroll={{
        //   x: 900,
        // }}
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px" : "0px",
          width: screenWidth > 1200 ? "100%" : "100%",
        }}
      />
    </div>
  );
};

export default TableDelayedPayment;
