import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import cookies from "js-cookie";

import {
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  BarChartOutlined,
  PieChartOutlined,
  TeamOutlined,
  MenuOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import dashboard_pressed from "../../assets/img/Dahsboard_pressed.png";
import dashboard_ico from "../../assets/img/Dashboard.png";
import loan_ico from "../../assets/img/LoanLogo.png";
import loan_pressed from "../../assets/img/LoanLogo_pressed.png";
import analytic_ico from "../../assets/img/Analyticlogo.png";
import analytic_pressed from "../../assets/img/Analyticlogo_pressed.png";
import agreements_ico from "../../assets/img/Agreementlogo.png";
import agreements_pressed from "../../assets/img/Agreementlogo_pressed.png";
import subscription_ico from "../../assets/img/Subscription.png";
import subscription_pressed from "../../assets/img/SubscriptionLogo_pressed.png";
import profile_ico from "../../assets/img/Profile.png";
import profile_pressed from "../../assets/img/Profile_selected.png";
import logout_ico from "../../assets/img/Logout.png";
import Hamburger from "hamburger-react";
import { fallDown as BurgerMenu } from "react-burger-menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LockIcon from "@mui/icons-material/Lock";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import StorageIcon from "@mui/icons-material/Storage";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ReviewsIcon from "@mui/icons-material/Reviews";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MessageIcon from "@mui/icons-material/Message";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Item = ({
  title,
  title2,
  to,
  icon,
  selected,
  setSelected,
  subMenu,
  icon2,
}) => {
  const match = useRouteMatch(to);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Menu.Item
      // key={title}
      icon={match ? icon : icon2}
      style={{
        paddingLeft: match ? "24px" : "30px",
        borderLeft: match ? "6px solid #0E5AA5" : "none",
        marginLeft: "-0.03vw",
        borderRadius: "0px 8px 8px 0px",
        height: title ? "35px" : "30px",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => setSelected(title)}
    >
      <div>
        {title ? (
          <p
            style={{
              color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              textAlign: "Left",
              margin: "0px",
            }}
          >
            {title}
          </p>
        ) : (
          <></>
        )}

        {title2 ? (
          <p
            style={{
              color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "Left",
              paddingLeft: "35px",
              margin: "0px",
            }}
          >
            {title2}
          </p>
        ) : (
          <></>
        )}
      </div>
      <Link to={to} />
    </Menu.Item>
  );
};

const Item3 = ({
  title,
  title2,
  to,
  icon,
  selected,
  setSelected,
  subMenu,
  icon2,
  onClick, // New prop for onClick function
  disabled, // New prop for disabling button
}) => {
  const match = useRouteMatch(to);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hovered, setHovered] = useState(false);

  return (
    <Menu.Item
      key={title}
      icon={hovered ? icon : icon2}
      style={{
        marginLeft: "15px",
        marginBottom: "20px",
        paddingLeft: "20px",
        background: hovered ? "#0E5AA5" : "#FFFFFF",
        opacity: 1,
        width: "80%",
        color: hovered ? "#FFFFFF" : "#0E5AA5",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
      onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
      onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
      onClick={(event) => onClick(event)} // Attaching onClick here
      disabled={disabled} // Attaching disabled here
    >
      <div>
        <Typography
          style={{
            color: hovered ? "#FFFFFF" : "rgba(0,0,0,0.6)",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            textAlign: "Left",
          }}
        >
          {title}
        </Typography>

        <Typography
          style={{
            color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "13px",
            textAlign: "Left",
            paddingLeft: "20px",
          }}
        >
          {title2}
        </Typography>
      </div>
      <Link to={to} />
    </Menu.Item>
  );
};

const Sidebar = (to) => {
  const match = useRouteMatch(to);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/logout",
        {
          method: "POST",
          redirect: "manual", // don't follow the redirect
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      console.log("response status value", response.status);
      if (response.status === 200) {
        // Clear user session and navigate to login page
        cookies.remove("token");
        window.location.href = "/auth/signin";
      } else if (response.status === 0) {
        window.location.href = "/auth/signin";
      }
      // if (!response.ok) {
      //   throw new Error("Logout failed");
      // }
    } catch (error) {
      console.error(error);
      // Display error message to user
      alert("Logout failed. Please try again.");
    }
    setIsLoading(false);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Users");
  const [openKeys, setOpenKeys] = useState([]);
  const [latestOpenKey, setLatestOpenKey] = useState();

  // Keep track of whether a click happened on a submenu
  const [clickedOnSubmenu, setClickedOnSubmenu] = useState(false);

  const onOpenChange = (keys) => {
    if (clickedOnSubmenu) {
      setClickedOnSubmenu(false);
      return;
    }
    if (openKeys.length < keys.length) {
      // This means a submenu has been opened, so simply update the state.
      setOpenKeys(keys);
    } else {
      // This means a submenu has been closed.
      // You should keep it in the state if it was closed by a click on a submenu item
      const keyThatHasBeenClosed = openKeys.find((key) => !keys.includes(key));
      if (latestOpenKey === keyThatHasBeenClosed) {
        setOpenKeys(openKeys);
      } else {
        setOpenKeys(keys);
      }
    }
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const history = useHistory();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function handleToggleMenu(event) {
    setMenuIsOpen(!menuIsOpen);
  }

  return (
    <>
      {screenWidth > 1200 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          style={{
            height: "100vh",
            backgroundColor: "#F1F6F9",
          }}
        >
          {/* LOGO AND MENU ICON */}
          <Menu
            // theme="dark"
            mode="inline"
            defaultSelectedKeys={[selected]}
            selectedKeys={[selected]}
            style={{
              background: "#F1F6F9",
              width: "250px",
            }}
          >
            <Menu.Item style={{ marginTop: "30px" }}>
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="34px 20px 20px 0px"
                >
                  <Typography
                    variant="h3"
                    color={colors.grey[930]}
                    opacity="1"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "800",
                      fontSize: "25px",
                      textAlign: "center",
                    }}
                  >
                    SmartLend.
                  </Typography>
                </Box>
              )}
            </Menu.Item>
            <div style={{ paddingBottom: "32px" }}></div>
            <Item
              title="Dashboard"
              to="/a-users"
              icon={
                <img
                  src={dashboard_pressed}
                  alt=""
                  width={12}
                  height={12}
                  style={{ marginLeft: "-3px" }}
                />
              }
              icon2={
                <img
                  src={dashboard_ico}
                  alt=""
                  width={12}
                  height={12}
                  style={{ marginLeft: "-3px" }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="Messages"
              to="/a-message"
              icon={
                <MessageIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <MessageIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <SubMenu
              key="Loan"
              title="Loan"
              style={{
                color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "13px",
                textAlign: "Left",
                margin: "0px",
              }}
              icon={<img src={loan_ico} alt="" width={12} height={12} />}
              icon2={<img src={loan_ico} alt="" width={12} height={12} />}
            >
              <Item
                title2="Requested Loan"
                to="/a-requested-loan"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Offered Loan"
                to="/a-offered-loan"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Ongoing Loan"
                to="/a-ongoing-loan"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Red Flagged"
                to="/a-red-flagged"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Corporate Structure Loan"
                to="/a-corporate-structure-loan"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
            </SubMenu>
            {/* </SubMenu> */}

            {/* <SubMenu
              key="Agreement"
              title="Agreement"
              style={{
                color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "13px",
                textAlign: "Left",
                margin: "0px",
              }}
              icon={<img src={agreements_ico} alt="" width={12} height={12} />}
              icon2={<img src={agreements_ico} alt="" width={12} height={12} />}
            >
              <Link
                to="/a-agreement"
                style={{ color: "inherit", textDecoration: "none" }}
              />

              <Item
                title2="Approve Agreement"
                to="/a-pending-sign"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Ongoing Agreement"
                to="/a-ongoing-agreement"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Generate Agreement"
                to="/a-generate-agreement"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
            </SubMenu> */}

            <Item
              title="Agreement"
              to="/a-ongoing-agreement"
              icon={
                <CopyAllIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <CopyAllIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            {/* </SubMenu> */}
            <Item
              title="Analytics"
              to="/a-analytic"
              icon={
                <img src={analytic_pressed} alt="" width={12} height={12} />
              }
              icon2={<img src={analytic_ico} alt="" width={12} height={12} />}
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            {/* <SubMenu
              key="Payment"
              title="Payment"
              style={{
                color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "13px",
                textAlign: "Left",
                margin: "0px",
              }}
              icon={
                <img src={subscription_ico} alt="" width={12} height={12} />
              }
              icon2={
                <img src={subscription_ico} alt="" width={12} height={12} />
              }
            >
              <Item
                title2="Lender Payment"
                to="/a-lender-payment"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
              <Item
                title2="Borrower Payment"
                to="/a-borrower-payment"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
                setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
              />
            </SubMenu> */}
            <Item
              title="Borrower Payment"
              to="/a-borrower-payment"
              icon={
                <LocalAtmIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <LocalAtmIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="Advertisement"
              to="/a-advertisement"
              icon={
                <FeaturedVideoIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <FeaturedVideoIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="User Database"
              to="/a-user-database"
              icon={
                <GroupIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <GroupIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="News & Events"
              to="/a-news-events"
              icon={
                <NewspaperIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <NewspaperIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="Verify Borrowers"
              to="/a-verify-borrower"
              icon={
                <LocalPoliceIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <LocalPoliceIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="Feedback"
              to="/a-feedback"
              icon={
                <ReviewsIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <ReviewsIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="Property Valuation"
              to="/a-propvalue"
              icon={
                <HomeWorkIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <HomeWorkIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
            <Item
              title="Employee"
              to="/a-employee"
              icon={
                <GroupIcon
                  style={{
                    fontSize: "18px",
                    color: "#0E5AA5",
                    marginLeft: "-5px",
                  }}
                />
              }
              icon2={
                <GroupIcon
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.4)",
                    marginLeft: "-5px",
                  }}
                />
              }
              selected={selected}
              setSelected={setSelected}
              setClickedOnSubmenu={setClickedOnSubmenu} // Passing setClickedOnSubmenu here
            />
          </Menu>
          <Menu
            mode="inline"
            defaultSelectedKeys={[selected]}
            selectedKeys={[selected]}
            style={{
              background: "#F1F6F9",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              height: "100%",
            }}
          >
            <Item3
              title="Log Out"
              icon={
                <LockIcon
                  className="colored_white_icon"
                  style={{ fontSize: "18px", marginLeft: "-3px" }}
                />
              }
              icon2={
                <LockIcon
                  style={{
                    fontSize: "18px",
                    marginLeft: "-3px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                />
              }
              to="#"
              onClick={(event) => handleLogout(event)}
              disabled={isLoading}
            />
          </Menu>
        </Box>
      ) : (
        <BurgerMenu
          left
          width={"100%"}
          isOpen={menuIsOpen}
          customCrossIcon={false}
          customBurgerIcon={
            <Hamburger
              toggled={menuIsOpen}
              toggle={handleToggleMenu}
              size={24}
            />
          }
        >
          <div>
            <div
              style={{
                marginTop: "20px",
                fontSize: "35px",
                fontWeight: "800",
                color: "#142C44CC",
              }}
            >
              SmartLend
            </div>
            <div
              onClick={() => setMenuIsOpen(false)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <div
                onClick={() => {
                  history.push("/a-users");
                  window.scrollTo(0, 0);
                }}
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Dashboard
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => {
                history.push("/a-offered-loan");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Loan
              </div>
              <NavigateNextIcon />
            </div>

            <div
              onClick={() => {
                history.push("/a-requested-loan");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Requested Loan
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/a-offered-loan");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Offered Loan
              </div>
              <NavigateNextIcon />
            </div>

            <div
              onClick={() => {
                history.push("/a-ongoing-loan");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Ongoing Loan
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/a-red-flagged");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Red Flagged
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => {
                history.push("/a-pending-sign");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Agreement
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/a-pending-sign");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Aprrove Agreement
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/a-ongoing-agreement");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Ongoing Agreement
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/a-generate-agreement");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Generate Agreement
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => {
                history.push("/a-analytic");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Analytics
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => {
                history.push("a-lender-payment");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Lender Payment
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("a-borrower-payment");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Borrower Payment
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => {
                history.push("/a-advertisement");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Advertisement
              </div>
              <NavigateNextIcon />
            </div>
            <div
              style={{
                width: "90%",
                textAlign: "center",
                padding: "15px 20px",
                background: "#51A22E",
                color: "white",
                marginTop: "35px",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={(event) => {
                handleLogout(event);
                history.push("#");
                window.scrollTo(0, 0);
              }}
            >
              Logout
            </div>
          </div>
        </BurgerMenu>
      )}
    </>
  );
};
export default Sidebar;
