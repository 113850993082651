import { Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { format } from "date-fns";




const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMMM dd, yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};



const TableRepaymentUserDatabase = ({ dataRepaymentSchedule, onRowClick }) => {

  const processedData = dataRepaymentSchedule.map((item, index) => ({
    key: index + 1,
    ...item,
  }));

  const columns = [
   
    {
      title: '',
      dataIndex: 'key',
      rowScope: 'row',
      align: 'center',  // Add this line to align the content to the center
      width:'10%'
    },
    {
      title: <span style={{fontFamily:"Poppins, sans-serif", fontSize: "15px", fontWeight: "600"}}>Monthly Installment</span>,
      dataIndex: 'repay_amount',
      key: 'repay_amount',
      width: '20%',
      render: (text) => (
        <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800"}}>{`${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'SGD' }) : "-"}`}</span>
      ),
    },
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Loan Balance</span>,
      dataIndex: 'loan_balance',
      key: 'loan_balance',
      width: '30%', 
      render: (text, record) => (
        <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800" }}>
        {record.status === 'pending' ? '-' : `${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'SGD' }) : "-"}`}
      </span>      ),
      
    },
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Late Day</span>,
      dataIndex: 'late_day',
      key: 'late_day',
        width: '25%',
      render: (text) => (
        <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800" }}>{`${text + " Day" || "0"}`}</span>
      ),
      
    },
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Repayment Date</span>,
      dataIndex: 'repay_date',
      key: 'repay_date',
        width: '25%',
      render: (text) => (
        <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800" }}>{`${text ? formatDate(text) : "-"}`}</span>
      ),
      
    },
    {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Repayment Due Date</span>,
        dataIndex: 'repay_due_date',
        key: 'repay_due_date',
        width: '30%', 
        render: (text) => (
          <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800" }}>{`${text ? formatDate(text) : "-"}`}</span>
        ),
        
      },
      {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Status</span>,
        dataIndex: 'status',
        key: 'status',
        fixed:"right",
        width: '20%', 
        render: (text) => (
          <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800" }}>{`${text ? text.charAt(0).toUpperCase() + text.slice(1) : "-"}`}</span>
        ),
        
      },
    
      
    
  ];

  return <Table columns={columns} dataSource={processedData} pagination={false}  style={{ borderRadius: "10px 10px 10px 10px"}}  scroll={{
    x: 1000,
  }} bordered/>;
};

export default TableRepaymentUserDatabase;