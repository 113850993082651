import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
  Slider,
} from "@mui/material";
import { tokens } from "../../src/theme";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mockTransactions } from "../../src/data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import { border } from "@mui/system";
import { format } from "date-fns";
import { Progress, Button, Modal, Avatar } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useHistory } from "react-router-dom";
import adspot_ico from "../../src/assets/img/businessman-receiving-money-after-contract-signing.png";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ##141414`,
  },
});
const getInitials = (name) => {
  const textToSplit = name.split(" ");
  const initials =
    textToSplit?.[0]?.[0].toUpperCase() +
    (textToSplit?.[1]?.[0].toUpperCase() || "");
  return initials;
};

const ContainerActivity = ({ title, subtitle }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "7px",
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#FFFFFFB3",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
      }}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          background: "rgb(140,140,170)",
          marginLeft: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          color: "white",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {title ? getInitials(title) : "SL"}
      </div>
      <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// const ContainerLineChart = () => (
//   <div style={{ width: "100%", height: "200px", marginRight: "0px", borderRadius: "20px" }}>
//     <TinyLineChart title="$12,000" subtitle="Net profit to date" />
//   </div>
// );

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

// const ContainerPieChart = () => (
//   <div style={{ width: "100%", height: "200px", marginRight: "0px", color: "grey", borderRadius: "20px" }}>
//     <HalfPieChart title="$802,000" subtitle="Forecast collection" />
//   </div>
// );

const reformatDateString = (originalDateString) => {
  // Create a Date object from the original date string
  const originalDate = new Date(originalDateString);

  // Define options for formatting the date
  const options = { year: "numeric", month: "short", day: "numeric" };

  // Format the date into "Mon dd, yyyy" format
  const formattedDate = originalDate.toLocaleDateString("en-US", options);

  return formattedDate;
};

const ContainerAgreement = ({
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
}) => (
  <div
    style={{
      width: "45%",
      margin: "30px 1px 1px 30px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        flex: 1,
        marginBottom: "20px",
      }}
    >
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "400",
          fontSize: "14px",
          color: "#606264",

          flex: "0 0 auto",
        }}
      >
        {title}
      </h3>

      <h3
        style={{
          fontFamily: "Manrope, sans-serif",
          fontWeight: "600",
          fontSize: "30px",
          color: "#3E5367",
          // flex: 1,
          marginBottom: "15px",
          marginLeft: "10px",
          flex: "0 0 auto",
        }}
      >
        {title1}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",
          // flex: 1,
          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title2}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
          marginTop: "3px",
        }}
      >
        {title3}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#B13434",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title4}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title5}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title6}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title7}
      </h3>
    </div>
    <div
      style={{
        marginTop: "-40px",
      }}
    ></div>
  </div>
);

const Transactions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataSummary, setSummary] = useState([]);
  const [loanPeriod, setLoanPeriod] = useState(0);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [offer_amount, setLoanAmount] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const [type, setLoanType] = useState("simple_loan");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("default");
  const [displayAds, setDisplayAds] = useState([]);
  const [adImage, setAdImage] = useState(null);
  const [adLink, setAdLink] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    index,
    credit_score,
    loan_id,
  }) => {
    const shouldAddMarginTop = index >= 4;
    return (
      <>
        <div
          style={{
            width: "23.5%",
            textAlign: "center",
            marginRight: "-15px",
            marginLeft: "25px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: shouldAddMarginTop ? "10px" : "0px", // add margin top if index is greater than 3
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 15px 0px 15px",
            }}
          >
            <img
              src={icon}
              alt={title}
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "1000px",
                border: "2px solid #f0f0f0",
              }}
            />
            <div
              style={{
                marginLeft: "15px",
                marginBottom: "10px",
                marginTop: "7px",
                marginRight: "1px",
              }}
            >
              <h3
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "left",
                  marginTop: "18px",
                }}
              >
                {title}
              </h3>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "200",
                  fontSize: "11px",
                  textAlign: "left",
                  marginTop: "-10px",
                  opacity: ".6",
                }}
              >
                {subtitle}
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginLeft: "10px",
              margin: "0px 15px 0px 15px",
            }}
          >
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                marginBottom: "-3px",
                opacity: ".6",
              }}
            >
              {label}
            </p>
            <Progress
              percent={credit_score}
              showInfo={false}
              strokeColor={
                subtitle === "Express Applicant"
                  ? { "0%": "#FFE6B2", "100%": "#FED065" }
                  : { "0%": "#b1c3e0", "100%": "#1557a9" }
              }
            />
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "15px",
                marginTop: "-10px",
              }}
            >
              {description}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "-17px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "-17px",
              }}
            >
              {description2}
            </p>
          </div>
          <div key={loan_id}>
            <Button
              type="primary"
              size="middle"
              onClick={(event) => showModal(loan_id, event)}
              style={
                subtitle === "Express Applicant"
                  ? buttonStyleExpress
                  : buttonStyleBorrower
              }
            >
              Make an offer
            </Button>
          </div>
        </div>
      </>
    );
  };

  const Container2 = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    index,
  }) => {
    const shouldAddMarginTop = index > 3;
    return (
      <>
        <div
          style={{
            width: "35%",
            textAlign: "center",
            marginRight: "0px",
            marginLeft: "15px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: shouldAddMarginTop ? "10px" : "0px", // add margin top if index is greater than 3
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "0px 15px 0px 15px",
            }}
          >
            <div
              style={{
                marginBottom: "2px",
                marginTop: "7px",
                marginRight: "1px",
              }}
            >
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "45px",
                  marginTop: "10px",
                  textAlign: "left",
                  color: "#142C44",
                }}
              >
                {description1}
              </p>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "200",
                  fontSize: "11px",
                  textAlign: "left",
                  marginTop: "-25px",
                  opacity: ".6",
                }}
              >
                {subtitle}
              </p>
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "7px",
                marginRight: "1px",
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                  fontSize: "10px",
                  opacity: ".6",
                  marginTop: "18px",
                  marginRight: "52px",
                }}
              >
                {label3}
              </p>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "18px",
                  marginTop: "-15px",
                }}
              >
                {description3}
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginLeft: "10px",
              margin: "0px 15px 0px 15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                    margin: "0",
                  }}
                >
                  {label2}
                </p>
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    margin: "0",
                  }}
                >
                  {description2}
                </p>
              </div>
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6", margin: "0" }}>{label3}</p>
                                <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", margin: "0" }}>{description3}</p> */}
                <Button
                  type="primary"
                  size="middle"
                  onClick={""}
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "4px",
                    borderRadius: "20px",
                    backgroundColor: "#00DA5E",
                    border: "1px solid #00DA5E",
                  }}
                >
                  Pay Now
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  onClick={""}
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                    opacity: "0.4",
                    background: "transparent",
                    boxShadow: "none",
                  }}
                >
                  Extend
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Container2Summary = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: "23.5%",
            textAlign: "center",
            marginRight: "0px",
            marginLeft: "15px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container3 = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "96%" : "100%",
            textAlign: "center",
            height: "240px",
            marginRight: "-15px",
            marginLeft: screenWidth < 800 ? "0px" : "25px",
            border: "1px solid none",
            borderRadius: screenWidth > 1200 ? "10px" : "10px 10px 10px 10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: screenWidth < 800 ? "400" : "600",
              fontSize: screenWidth < 800 ? "12px" : "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
          color: "#142C44CC",
          textAlign: "center",
          paddingTop: "15px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/viewBorrowerTransaction",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setData(data["Latest Transaction"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/ads/getAdsByScreen?screen=bTransaction",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setDisplayAds(data["ads"]);
        setAdImage(data.ads[0].image);
        setAdLink(data.ads[0].link);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (displayAds.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % displayAds.length);
      }, 3200);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [displayAds]);

  useEffect(() => {
    if (displayAds.length > 0) {
      console.log(currentIndex);
      setAdImage(displayAds[currentIndex].image);
      setAdLink(displayAds[currentIndex].link);
    }
  }, [currentIndex, displayAds]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const processedData = await Promise.all(
          data.map(async (item) => {
            const profilePictureResponse = await fetch(
              `https://www.smart-lend.com/api/loan/loanRequest/viewListProfilePicture?profile_picture=${item.profile_picture}`,
              options
            );

            if (!profilePictureResponse.ok) {
              throw new Error("Failed to fetch profile picture data");
            }

            const blob = await profilePictureResponse.blob();
            const imageUrl = URL.createObjectURL(blob);

            return {
              ...item,
              icon: imageUrl, // use the blob URL as the icon
            };
          })
        );

        const reversedData = processedData.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/lender/viewSummary",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setSummary(data["Summary"]);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple_loan") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.6 || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective_loan") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            0.6 || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setLoanDetails(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [modalStates, setModalStates] = useState({});

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.loan_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const [open, setOpen] = useState(false);

  const showModal = (loanId, event) => {
    event.preventDefault();
    console.log("loanId modal", loanId);
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleGridPath = () => {
    // history.push('../loan-request-grid')
  };

  const handleListPath = () => {
    history.push("../loan-request");
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleLoanPeriodChange = (e) => {
    setLoanPeriod(e.target.value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const handleMakeOffer = async (loan_id) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        offer_amount: parseFloat(offer_amount),
        offer_interest_rate: parseFloat(offer_interest_rate),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(offer_principal),
        offer_monthly_installment: parseFloat(offer_monthly_installment),
        offer_revenue: parseFloat(offer_revenue),
        total_admin_fee: parseFloat(total_admin_fee),
        admin_fee: parseFloat(admin_fee),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      path = "/pending-acceptance";

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };
  const buttonStyleExpress = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background: "#FED065",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m="4px 20px 20px 20px">
      {/* HEADER */}
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      </Box> */}

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}

        {/* ROW 2 */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          borderRadius="10px"
          border="0px solid "
          opacity="0.95"
          style={{
            overflowY: "scroll",
            height: isMobile ? "78vh" : "100vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "20px 20px 120px 20px",
          }}
        >
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            // background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            border-radius="10px"
            opacity="0.95"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[910]}
              opacity="1.00"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Transactions
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "250px",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "60%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </Box>
            <div>
              <div
                style={{
                  width: "100%",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    background: "rgba(255,255,255,0.7)",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "1%",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center", // use "center" to vertically center the titles
                      colors: colors.grey[910],
                      backgroundColor: "#ffff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "12px",
                      marginBottom: "7px",
                    }}
                  >
                    <div style={{ textAlign: "left", width: "25%" }}>
                      <h3
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        Lender Name
                      </h3>
                    </div>
                    <div style={{ textAlign: "center", width: "25%" }}>
                      <h3
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        Date & Time
                      </h3>
                    </div>
                    <div style={{ textAlign: "center", width: "25%" }}>
                      <h3
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        Amount
                      </h3>
                    </div>
                    <div style={{ textAlign: "center", width: "25%" }}>
                      <h3
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        Status
                      </h3>
                    </div>
                  </div>
                  {data && data.length > 0 ? (
                    (showAllTransactions ? data : data.slice(0, 3)).map(
                      (item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center", // use "center" to vertically center the titles
                            colors: colors.grey[910],
                            width: "100%",
                            padding: "8px 12px",
                          }}
                        >
                          <div style={{ textAlign: "left", width: "25%" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{}}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 700,
                                      fontSize: "13px",
                                      color: "#435669",
                                      margin: "0px",
                                    }}
                                  >
                                    {item.lender_name || "-"}
                                  </h3>
                                  {/* <span style={{ color: 'blue', fontFamily: "Manrope, sans-serif", fontSize: "13px", fontWeight: "400", marginTop: "20px", marginLeft: "5px" }}>{' #3057'}</span> */}
                                </div>
                                <p
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                    textAlign: "left",
                                    margin: "0px",
                                    opacity: ".6",
                                    color: "#435669",
                                  }}
                                >
                                  {item.Date || "-"}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div style={{ textAlign: "center", width: "25%" }}>
                            <h3
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 700,
                                fontSize: "13px",
                                color: "#435669",
                                margin: "0px",
                              }}
                            >
                              {item.repay_date
                                ? reformatDateString(
                                    formatDate(item.repay_date)
                                  )
                                : "-"}
                            </h3>
                          </div>

                          <div style={{ textAlign: "center", width: "25%" }}>
                            <h3
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 700,
                                fontSize: "13px",
                                color: "#435669",
                                margin: "0px",
                              }}
                            >
                              {item.repay_total_amount
                                ? item.repay_total_amount.toLocaleString(
                                    "en-US",
                                    { style: "currency", currency: "SGD" }
                                  )
                                : "-"}
                            </h3>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              width: "25%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  item.status === "done"
                                    ? "#D6F2D8"
                                    : "#B13434",
                                color:
                                  item.status === "done"
                                    ? "#139F2ACC"
                                    : "#FFFFFF",
                                border: "1px solid",
                                borderColor:
                                  item.status === "done"
                                    ? "#D6F2D8"
                                    : "#B13434",
                                borderRadius: "10px",
                                width: "40%",
                                height: "30px",

                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 700,
                                fontSize: "13px",
                                margin: "0px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {item.status
                                ? item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1)
                                : "-"}
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 15px",
                          // background: "rgba(255,255,255,0.7)",
                          borderRadius: "10px",
                          height: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Manrope, sans-serif",
                          fontSize: "0.8em",
                          fontWeight: "600",
                          color: "#00000066",
                        }}
                      >
                        No transactions in record
                      </div>
                    </div>
                  )}

                  {data && data.length > 3 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center", // use "center" to vertically center the titles
                        colors: colors.grey[910],
                        backgroundColor: "#ffff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowAllTransactions(
                          showAllTransactions ? false : true
                        )
                      }
                    >
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h5
                          className="all_button"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#435669",
                            margin: "0px",
                          }}
                        >
                          {showAllTransactions
                            ? "View Less Transactions"
                            : "View All Transactions"}
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            overflow="auto"
            height="100vh"
          >
            {/* <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"

                        marginTop="-17px"
                        p="15px"
                    >
                        <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px">
                            Ad Spot
                        </Typography>
                    </Box> */}
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="34%"
              position="relative" // ensure the Box is relatively positioned
            >
              <img
                src={adImage}
                onClick={(e) => {
                  e.preventDefault();
                  if (adLink) {
                    let url = adLink;

                    // Check if adLink starts with http:// or https://
                    if (!/^https?:\/\//i.test(adLink)) {
                      url = `https://${adLink}`;
                    }

                    console.log(url);
                    window.open(url, "_blank");
                  }
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  position: "absolute", // make the image positioned absolutely
                  top: "0",
                  left: "0",
                  zIndex: "1", // layer the image below the button
                  objectFit: "cover",
                  cursor: adLink ? "pointer" : "default",
                }}
              />

              {/* <Button
              style={{
                position: "absolute", // This makes the button float on top of the image
                top: "280px", // position it at the top of the box
                zIndex: "2", // layer the button above the image
                borderRadius: "20px",
                fontSize: "18px",
                fontWeight: "400",
                fontFamily: "Manrope, sans-serif",
                color: "#115CA6",
              }}
            >
              Apply Now
            </Button> */}
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="10px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Recent Activitiesasd
                </Typography>
              </Box>
              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 6).map((item, index) => (
                  <div
                    style={{
                      opacity:
                        index === 4
                          ? 0.6
                          : index === 5
                          ? 0.2
                          : index === 3
                          ? 0.9
                          : 1,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <ContainerActivity
                      key={index}
                      icon={item.icon} // use the icon URL from the API
                      title={item.principal_name || "-"}
                      subtitle={item.description || "-"}
                      index={index}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "350px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No recent activities
                  </div>
                </div>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Transactions;
