import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import cookies from "js-cookie";

const COLORS = ["#0083b0", "#FFFF"];

const HalfPieChartBorrower = ({ title, subtitle }) => {
  const [data, setData] = useState([]);
  const [loanAmount, setLoanAmount] = useState(null);
  const [paidPercentage, setPaidPercentage] = useState(null);
  const [amountReceived, setAmountReceived] = useState(null);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/dashboard/loanAmountPaid",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const amountPaid = json["Amount Paid"];
      const loanAmount = json["Loan Amount"];
      const paidPercentage = `${json["Paid Percentage"]}%`;
      const unpaid = loanAmount - amountPaid;
      const percent = (amountPaid / (amountPaid + unpaid)) * 100;
      setPercentage(percent);
      setData([{ value: amountPaid }, { value: unpaid }]);
      setLoanAmount(loanAmount);
      setPaidPercentage(paidPercentage);
      setAmountReceived(amountPaid);
    };

    fetchData();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div
          className="custom-tooltip"
          style={{
            width: "100%",
            height: "100%",
            background: "white",
            border: "none",
            outline: "none",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            padding: "10px",
          }}
        >
          <p className="label">{`${
            payload[0].name === 0 ? "Total paid" : "Outstanding Amount"
          } : ${Number(payload[0].value).toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const screenWidth = Math.floor(window.innerWidth / 18.5);

  const outerScreenWidth = Math.floor(window.innerWidth / 15.5);
  const radius = 35; // Radius of the semi-circle
  const circumference = 2 * Math.PI * radius;
  const dashOffset = circumference - (75 / 100 / 2 + 0.5) * circumference;
  const startAngle = -180; // Start angle (top of the circle)
  const endAngle = startAngle + (percentage / 100) * 180; // Calculate the end angle based on percentage

  // Calculate the coordinates of the start and end points of the arc
  const startX = 50 + radius * Math.cos((startAngle * Math.PI) / 180);
  const startY = 50 + radius * Math.sin((startAngle * Math.PI) / 180);
  const endX = 50 + radius * Math.cos((endAngle * Math.PI) / 180);
  const endY = 50 + radius * Math.sin((endAngle * Math.PI) / 180);

  const [screenWidthx, setScreenWidthx] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidthx(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "170px",
        border: "1px solid none",
        backgroundColor: "#FFFFFFB3",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        paddingTop: "1vh",
        position: "relative",
      }}
    >
      <div>
        <svg
          height="190"
          viewBox="0 0 100 100"
          style={{
            width: screenWidth < 800 ? "90%" : "100%",
            marginLeft: screenWidth < 800 ? "5%" : "0px",
            marginTop: screenWidth < 800 ? "-20px" : "0px",
          }}
        >
          <path
            d={`M 50 50 m -${radius}, 0 a ${radius},${radius} 0 0,1 ${
              radius * 2
            },0`}
            fill="transparent"
            strokeWidth="10"
            stroke="rgba(0,0,0,0.1)" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
          />
          <path
            transform={`translate(0, 1)`} // Adjust the vertical position here
            d={`M 50 50 m -${radius}, 0 a ${radius},${radius} 0 0,1 ${
              radius * 2
            },0`}
            fill="transparent"
            strokeWidth="7.5"
            stroke="white" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
            filter="url(#blur)" // Apply blur filter to this path
          />

          {/* Define the filter for the inner shadow */}
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="1.1" />
          </filter>

          <path
            d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${
              percentage <= 50 ? "0" : "1"
            } 1 ${endX} ${endY}`}
            fill="transparent"
            strokeWidth="10"
            stroke="#115CA6" // Color of the progress arc
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round" // Rounded ends
          />
        </svg>
      </div>

      <div
        style={{
          textAlign: screenWidthx < 800 ? "left" : "center",
          position: "absolute",
          left: screenWidthx < 800 ? 10 : 0,
          right: 0,
          bottom: 10,
        }}
      >
        <h5
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            color: "#142C44",
            letterSpacing: "-1px",
            fontSize: screenWidthx < 800 ? "18px" : "25px",
          }}
        >
          {amountReceived
            ? `${amountReceived.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </h5>
        <p
          style={{
            paddingRight: "0px",
            marginTop: "-7px",
            marginBottom: "0px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "800",
            color: "#142C44",
          }}
        >
          Total Loaned Amount Paid{" "}
        </p>
        <p
          style={{
            paddingRight: "0px",
            marginTop: "-2px",
            marginBottom: "0px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "600",
            color: "#00000066",
          }}
        >
          out of{" "}
          {loanAmount
            ? `${loanAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </p>
      </div>
    </div>
  );
};

export default HalfPieChartBorrower;
