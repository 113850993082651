import { Space, Table, Tag, Radio, Button, Modal, message, Skeleton  } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const TableGenerateAgreement = ({ onRowClick, onSelectionChange, reloadTable, setReloadTable }) => {
  const [data, setData] = useState([]);
  const [modalStates, setModalStates] = useState({});
  const [screenWidth, setScreenWidth] = useState("1920");
  const [loadingLoanId, setLoadingLoanId] = useState(null);


  const handleDownloadDocument = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=delete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      console.log("User deleted successfully");
    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  async function handleGenerateAgreement(loan_id) {
    setLoadingLoanId(loan_id); // Set the loading loan_id
    message.success("Please wait for your agreement to be generated");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/agreement/generateAgreement",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify({ loan_id }),
        }
      );
     
     if(response.status === 200) {

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/adobeSign/createSignAgreement",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify({ loan_id }),
          }
        );
  
       
       if(response.status === 200) {
      // Refresh your table data here
      // setReloadTable(true); 
      message.success("Sign Agreement Generated");
       } else if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
  
        const agreementData = await response.json();
      } catch (error) {
        console.error("Error fetching loan data:", error);
        message.error("Sign Agreement Not Generated");
      }


    // Refresh your table data here
    setReloadTable(true); // <-- trigger table reload
    message.success("Agreement Generated");
     } else if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      

      const agreementData = await response.json();
      // Display the message from the Ant Design library
      setLoadingLoanId(null);
    } catch (error) {
      console.error("Error fetching loan data:", error);
      message.error("Agreement Not Generated");
      setLoadingLoanId(null);
    } 
  }

  // async function handleGenerateSign(loan_id) {
  //   message.success("Please wait for your sign agreement to be generated");
  //   try {
  //     const response = await fetch(
  //       "https://www.smart-lend.com/api/agreement/adobeSign/createSignAgreement",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: "Bearer " + Cookies.get("token"),
  //         },
  //         body: JSON.stringify({ loan_id }),
  //       }
  //     );

     
  //    if(response.status === 200) {
  //   // Refresh your table data here
  //   // setReloadTable(true); 
  //   message.success("Sign Agreement Generated");
  //    } else if (!response.ok) {
  //       throw new Error(`HTTP error ${response.status}`);
  //     }

  //     const agreementData = await response.json();
  //   } catch (error) {
  //     console.error("Error fetching loan data:", error);
  //     message.error("Sign Agreement Not Generated");
  //   }
  // }

  // useEffect(() => {
  //     const initialStates = {};

  //     data.forEach(item => {
  //       initialStates[item.loan_id] = false;

  //     });
  //     setModalStates(initialStates);

  //   }, [data]);

  //   const showModal = (loan_id) => {
  //     setModalStates(prevStates => ({
  //       ...prevStates,
  //       [loan_id]: true
  //     }));
  //   };

  useEffect(() => {
    console.log("reloadTable data", reloadTable);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/viewListAgreement?status=pending",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        setData(json["Accepted Loan"]);
        // Reset `reloadTable` after data fetch is complete
        if (reloadTable !== false) { // or: if (reloadTable !== baselineValue)
            setReloadTable(false); // or: setReloadTable(baselineValue)
          }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadTable, setReloadTable]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Lender
        </span>
      ),
      dataIndex: "lender",
      key: "lender",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Borrower
        </span>
      ),
      dataIndex: "borrower",
      key: "borrower",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Admin Fee
        </span>
      ),
      dataIndex: "admin_fee",
      key: "admin_fee",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Type
        </span>
      ),
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      key: "action",
      width: 30,
      render: (text, record) => (
          <Button
              type="primary"
              onClick={() => handleGenerateAgreement(record.loan_id)}
              disabled={record.document === null ? false : true}
              loading={record.loan_id === loadingLoanId}
          >
              Generate Sign Agreements
          </Button>
      ),
  },
  
  
    // {

    //     key: 'action',
    //     width: 38,
    //     render: (text, record) => (
    //         record.agreement_id !== "" && (
    //             <Button
    //                 type="default"
    //                 onClick={() => showModal(record.loan_id)}
    //                 disabled={record.agreement_id === "" ? true : false}
    //             >
    //                 View Agreements
    //             </Button>
    //         )
    //     ),
    // },
    // {
    //   key: "action",
    //   width: 38,
    //   render: (text, record) =>
    //     record.document !== null && (
    //       <Button
    //         type="primary"
    //         onClick={() => handleGenerateSign(record.loan_id)}
    //         disabled={record.agreement_id !== null}
    //       >
    //         Generate Sign Agreements
    //       </Button>
    //     ),
    // },
  ];

  return (
    <div>
      <Table
        rowKey="loan_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{
          x: 1000,
          
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200  ?'0px 20px' : "0px",
          width: screenWidth > 1200 ? 'auto' : '100%',
        }}
      />
      {/* {data && data.length > 0 && data
      .map((item) => (
        <Modal                           
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={modalStates[item.loan_id]}
        onCancel={() => setModalStates(prevStates => ({
          ...prevStates,
          [item.loan_id]: false
        }))}
      
        footer={[

         
        ]}
      >
      

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, borderRadius: "10px" }}>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_amount"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Lender</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.lender || "-"}</p>                       
             </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_interest_rate"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Borrower</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.borrower || "-"}</p>                       

          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, borderRadius: "10px" }}>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_amount"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Status</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_interest_rate + "%" || "-"}</p>                        
             </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_interest_rate"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Period</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_period + " Month" || "-"}</p> 
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_period"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Loan Status</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.status || "-"}</p>                         
            </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="type"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Loan Type</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.type || "-"}</p> 
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_period"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Loan Amount</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_amount || "-"}</p>                  
                   </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="type"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Admin Fee</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.admin_fee + "%" || "-"}</p> 
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_period"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Created At</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.created_at || "-"}</p>                        
             </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="type"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Updated At</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.updated_at || "-"}</p> 
          </div>
        </div>


        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
          <div style={{ flex: 1 }}>
            <label htmlFor="offer_period"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Offer Principal</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_principal || "-"}</p>                      
               </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="type"  style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6" }}>Offer Revenue</label>
            <br />
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_revenue || "-"}</p> 
          </div>
        </div>

        <hr />

      
      </Modal>
      ))
    } */}
    </div>
  );
};

export default TableGenerateAgreement;
