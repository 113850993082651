import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
} from "@mui/material";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
// import { makeStyles } from '@mui/system';
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import briefcase from "../../assets/img/briefcase.png";
import house from "../../assets/img/house.png";
import currency from "../../assets/img/currency.png";
import { format } from "date-fns";
import { Progress, Button, Modal, message, Slider, InputNumber } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useHistory } from "react-router-dom";
import adspot_ico from "../../assets/img/giant-building-with-sun.png";
import applyLoan from "../../assets/img/apply-loan.png";
import { BorderBottom } from "@mui/icons-material";
import defaultIcon from "../../assets/img/Profile.png";

// const useStyles = makeStyles({
//     formControl: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         border: `1px solid ##141414`
//     },
// });

const ContainerActivity = ({ icon, title, subtitle, date }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "10px",
      borderRadius: "10px",
      backgroundColor: "#FFFFFFB3",
      height: "70px",
      padding: "0px 15px 0px 0px",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "left",
        color: "grey",
        alignItems: "center",
        height: "70px",
      }}
    >
      <img
        src={icon}
        alt={title}
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "10px",
          objectFit: "cover",
          margin: "10px 10px 10px 10px",
        }}
      />
      <div>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            textAlign: "left",
            margin: "0px 0px 0px 0px",
          }}
        >
          You have submitted a loan request of {title} on {date} at expected
          interest Rate of {subtitle} %
        </h3>
        {/* <p style={{ fontFamily: "Poppins, sans-serif", fontSize: "10px", fontWeight: "400", marginLeft: "10px" }}>{subtitle}</p> */}
      </div>
    </div>
  </div>
);

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const ContainerAgreement = ({
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
}) => (
  <div
    style={{
      width: "45%",
      margin: "30px 1px 1px 30px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        flex: 1,
        marginBottom: "20px",
      }}
    >
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "400",
          fontSize: "14px",
          color: "#606264",

          flex: "0 0 auto",
        }}
      >
        {title}
      </h3>

      <h3
        style={{
          fontFamily: "Manrope, sans-serif",
          fontWeight: "600",
          fontSize: "30px",
          color: "#3E5367",
          // flex: 1,
          marginBottom: "15px",
          marginLeft: "10px",
          flex: "0 0 auto",
        }}
      >
        {title1}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",
          // flex: 1,
          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title2}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
          marginTop: "3px",
        }}
      >
        {title3}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#B13434",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title4}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title5}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title6}
      </h3>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "18px",
          color: "#435669",

          marginBottom: "10px",
          flex: "0 0 auto",
        }}
      >
        {title7}
      </h3>
    </div>
    <div
      style={{
        marginTop: "-40px",
      }}
    ></div>
  </div>
);

const Loan = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataSummary, setSummary] = useState([]);

  const [loanPeriod, setLoanPeriod] = useState(0);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [offer_amount, setLoanAmount] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const [type, setLoanType] = useState("simple_loan");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [interestProfit, setInterestProfit] = useState([]);
  const [totalInterestProfit, setTotalInterestProfit] = useState(0);
  const [averageInterestProfit, setAverageInterestProfit] = useState(0);
  const [makePayment, setMakePayment] = useState();
  const [sliderValueAmount, setSliderValueAmount] = useState(1000); // Initialize slider value state
  const [sliderValuePeriod, setSliderValuePeriod] = useState(1); // Initialize slider value state
  const [sliderValueInterest, setSliderValueInterest] = useState(1); // Initialize slider value state
  const [monthlyInstallment, setMonthlyInstallment] = useState(0.0);
  const [totalInterestPaid, setTotalInterestPaid] = useState(0.0);
  const [yearlyInterestPaid, setYearlyInterestPaid] = useState(0.0);
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [sliderMin, setSliderMin] = useState(1);
  const [sliderMax, setSliderMax] = useState(36);
  const [label, setLabel] = useState("Month");
  const [refreshData, setRefreshData] = useState(false);
  const [sliderValuePeriodInMonths, setSliderValuePeriodInMonths] =
    useState(sliderValuePeriod);

  const handleClickInterestProfit = () => {
    history.push("/analytics");
  };

  const handleApplyLoan = (event) => {
    event.preventDefault();
    history.push("/b-apply-loan");
  };

  const [modalStates2, setModalStates2] = useState({});
  const [modalStates3, setModalStates3] = useState({});
  const [modalStates4, setModalStates4] = useState({});

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    // If the label is 'Years', multiply sliderValuePeriod by 12. Otherwise, use the original value.
    const actualPeriod =
      label === "Year" ? sliderValuePeriod * 12 : sliderValuePeriod;

    const monthlyInstallmentValue =
      sliderValueAmount / actualPeriod +
      sliderValueAmount * (sliderValueInterest / 100);

    const total_admin_fee =
      ((sliderValueAmount *
        (sliderValueInterest / 1200) *
        (1 + sliderValueInterest / 1200) ** actualPeriod) /
        ((1 + sliderValueInterest / 1200) ** actualPeriod - 1)) *
      1.05 *
      0.05 *
      actualPeriod;

    const totalInterestPaidValue =
      sliderValueAmount * (sliderValueInterest / 100) * actualPeriod;

    const yearlyInterestPaidValue =
      (totalInterestPaidValue / actualPeriod) * 12;

    setMonthlyInstallment(monthlyInstallmentValue);
    setTotalInterestPaid(totalInterestPaidValue);
    setYearlyInterestPaid(yearlyInterestPaidValue);
  }, [sliderValueAmount, sliderValuePeriod, sliderValueInterest, label]); // added label to the dependency array

  // const handleSliderChangeAmount = (value) => {
  //   // Function to update slider value
  //   setSliderValueAmount(value);
  // };

  const handleSliderChangeAmount = (value) => {
    // Function to update slider value
    setSliderValueAmount(value);
  };

  const handleSliderChangePeriod = (value) => {
    // Function to update slider value
    setSliderValuePeriod(value);
  };

  const handleSliderChangeInterest = (value) => {
    // Function to update slider value
    setSliderValueInterest(value);
  };

  const handleMonthlyInstallment = (value) => {
    setMonthlyInstallment(value);
  };

  const handleTotalInterestPaid = (value) => {
    setTotalInterestPaid(value);
  };

  const handleYearlyInterestPaid = (value) => {
    setYearlyInterestPaid(value);
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerOngoingLoan = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    index,
    credit_score,
    loan_id,
    open_modal,
    cancel_modal,
    onClickModal,
  }) => {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "left",
          marginRight: "0px",
          border: "none",
          borderRadius: "10px",
          backgroundColor: "#FFFFFFB3",
          display: "flex",
          justifyContent: "flex-start",
          paddingBottom: "5px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "95%",
            textAlign: "center",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            padding: "10px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              marginLeft: "10px",
            }}
          >
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "25px",
                textAlign: "left",
                color: "#142C44",
                margin: "0px 0px 0px 0px",
                lineHeight: "45px",
              }}
            >
              {label}
            </p>
            <Progress
              percent={credit_score}
              showInfo={false}
              strokeColor={
                subtitle === "Your subtitle here"
                  ? { "0%": "#b1c3e0", "100%": "#1557a9" }
                  : { "0%": "#b1c3e0", "100%": "#1557a9" } // Update these colors based on your conditions
              }
            />
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "-10px 0px 10px 0px",
              }}
            >
              {description}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px 0px 0px 0px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontWeight: "700",
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#142C44CC",
                margin: "0px 0px 0px 0px",
              }}
            >
              {description2}
            </p>
            {/* <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px 0px 0px",
              }}
            >
              {label1}asd
            </p> */}
            {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", marginTop: "-17px" }}>{description1}</p> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0px 0px 0px",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {title ? getInitials(title) : "SL"}
            </div>
            <div
              style={{
                marginLeft: "8px",
              }}
            >
              <h3
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                  margin: "0px 0px 0px 0px",
                  textAlign: "left",
                }}
              >
                {title}
              </h3>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px 0px 0px 0px",
                  textAlign: "left",
                }}
              >
                {subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ContainerRow1 = ({
    onClick,
    icon,
    title,
    subtitle,
    label,
    description,
  }) => (
    <div
      onClick={onClick} // The onClick event handler
      style={{
        width: "48%",
        textAlign: "center",
        margin: "10px 1px 1px 10px",
        border: "1px solid none",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "15px",
          marginBottom: "10px",
          marginTop: "7px",
          marginRight: "1px",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "17px",
            color: "#142C44CC",
            textAlign: "left",
            margin: "12px 0px 0px 0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "200",
            fontSize: "8px",
            color: "#142C44CC",
            textAlign: "right",
            margin: "17px 10px 0px 0px",
          }}
        >
          {subtitle}
        </p>
      </div>

      <div
        style={{ display: "flex", alignItems: "center", marginTop: "-10px" }}
      >
        <img
          src={icon}
          alt={title}
          style={{
            background: "#f0f0f0",
            width: "50px",
            height: "45px",
            borderRadius: "30px",
            marginLeft: "12px",
          }}
        />
        <div
          style={{
            marginLeft: "15px",
            marginBottom: "10px",
            marginTop: "7px",
            marginRight: "1px",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "800",
              fontSize: "20px",
              color: "#142C44CC",
              textAlign: "left",
              marginBottom: "4px",
              marginTop: "10px",
            }}
          >
            {label}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "500",
              fontSize: "12px",
              color: "#142C44CC",
              textAlign: "left",
              lineHeight: "1",
            }}
          >
            {description}
          </p>
        </div>
      </div>
      {/* <p>{description}</p> */}
    </div>
  );

  const ContainerRow3 = ({ noData }) => (
    <div
      style={{
        width: screenWidth > 1200 ? "48%" : "100%",
        textAlign: "center",
        margin: screenWidth > 1200 ? "30px 1px 1px 10px" : "10px 1px 1px 0px",
        border: "1px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <p
        style={{
          fontWeight: screenWidth < 800 ? "400" : "600",
          fontSize: screenWidth < 800 ? "12px" : "18px",
          color: "#142C44CC",
          textAlign: "center",
          margin: "50px auto",
        }}
      >
        {noData}
      </p>
    </div>
  );

  const reformatDateString = (originalDateString) => {
    // Create a Date object from the original date string
    const originalDate = new Date(originalDateString);

    // Define options for formatting the date
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format the date into "Mon dd, yyyy" format
    const formattedDate = originalDate.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const Container2 = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label4,
    description3,
    index,
    label3,
    repay_id,
  }) => {
    const shouldAddMarginTop = index > 3;
    return (
      <div style={{ width: screenWidth < 800 ? "300px" : "32%" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            border: "1px solid none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "10px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{}}>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: screenWidth < 800 ? "30px" : "40px",
                  textAlign: "left",
                  color: "#142C44",
                  margin: "0px 0px 10px 0px",
                  lineHeight: "45px",
                }}
              >
                {description1}
              </p>

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  margin: "28px 0px 0px 0px",
                }}
              >
                {subtitle}
              </p>
              <div style={{ display: "flex", marginTop: "-20px" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    width: "60px",
                    textAlign: "left",
                  }}
                >
                  {label1}
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "#142C44CC",
                  }}
                >
                  {description1}
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "-20px" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    width: "60px",
                    textAlign: "left",
                  }}
                >
                  {label2}
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "#142C44CC",
                  }}
                >
                  {description2}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    margin: "0px",
                  }}
                >
                  {label4}
                </p>
                {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", margin: "0" }}>{description2}</p> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div style={{ textAlign: "left", marginTop: "-22px" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    margin: "40px 0px 0px 0px",
                  }}
                >
                  {label3}
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "#142C44CC",
                    margin: "0px 0px 0px 0px",
                  }}
                >
                  {reformatDateString(description3)}
                </p>
              </div>
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  className="all_button"
                  type="primary"
                  size="middle"
                  onClick={(event) => showModal(repay_id, event)}
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "4px",
                    borderRadius: "20px",
                    backgroundColor:
                      new Date(description3) < new Date()
                        ? "#b01023"
                        : "#00DA5E",
                    border:
                      new Date(description3) < new Date()
                        ? "1px solid #b01023"
                        : "1px solid #00DA5E",
                  }}
                >
                  Pay Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleMakePayment = async (repay_id) => {
    console.log("value of repay_id", repay_id);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/makePayment?repay_id=${repay_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setMakePayment(data);

      // Display the message from the Ant Design library
      message.success(data.message);

      // If the payment was successful
      if (
        data.message === "Payment made successfully!" ||
        "Payment has already been made for this repayment."
      ) {
        setRefreshData((prevState) => !prevState); // Toggle the state
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Container2Summary = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: "23.5%",
            textAlign: "center",
            marginRight: "0px",
            marginLeft: "15px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container3 = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "96%" : "100%",
            textAlign: "center",
            height: "240px",
            marginRight: "-15px",
            marginLeft: screenWidth > 1200 ? "25px" : "0px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container4 = () => {
    return (
      <div
        style={{
          width: screenWidth < 800 ? "300px" : "32.5%",
          textAlign: "center",
          border: "1px solid none",
          borderRadius: "10px",
          backgroundColor: "#FFFFFFB3",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100px",
          height: "100%",
          padding: "40px 0px",
        }}
      >
        <img
          src={applyLoan}
          alt="Icon"
          style={{ width: "30%", height: "auto", margin: "20px 0px" }}
        />
        <button
          className="all_button"
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: "Poppins, sans-serif",
            padding: "5px 12px",
            background: hovered3
              ? "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)"
              : "transparent",
            outline: hovered3 ? "2px solid transparent" : "2px solid grey",
            border: "none",
            color: hovered3 ? "white" : " grey",
            transition: "0.3s all ease-in-out",
          }}
          onClick={(event) => handleApplyLoan(event)}
          onMouseEnter={() => setHovered3(true)} // set the state hovered to true  when mouse enter
          onMouseLeave={() => setHovered3(false)} // set the state hovered to false  when mouse leave
        >
          Apply Loan
        </button>
      </div>
    );
  };

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: screenWidth < 800 ? "400" : "600",
          fontSize: screenWidth < 800 ? "12px" : "18px",
          color: "#142C44CC",
          textAlign: "center",
          margin: "0px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/pendingLoan/viewPendingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setInterestProfit(data["Ongoing Loan"]);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/pendingLoan/viewPendingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setActivityLog(data["Pending Loans"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/ongoingLoan/viewOngoingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const calculatedData = data["Ongoing Loan"].flat().map((loanItem) => ({
          ...loanItem,
          loan: loanItem.loan.map((loan) => ({
            ...loan,
            credit_score:
              ((loan.amount - loanItem.last_loan_balance) / loan.amount) * 100,
          })),
        }));
        console.log("credit_score", calculatedData);

        setSummary(calculatedData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple_loan") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.6 || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective_loan") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            0.6 || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setLoanDetails(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [modalStates, setModalStates] = useState({});

  useEffect(() => {
    const initialStates = {};
    dataSummary.forEach((item) => {
      initialStates[item.repay_id] = false;
    });
    setModalStates(initialStates);
  }, [dataSummary]);

  const [open, setOpen] = useState(false);

  const showModal = (repay_id, event) => {
    event.preventDefault();
    console.log("repay_id modal", repay_id);
    setModalStates((prevStates) => ({
      ...prevStates,
      [repay_id]: true,
    }));
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleGridPath = () => {
    // history.push('../loan-request-grid')
  };

  const handleListPath = () => {
    history.push("../loan-request");
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleLoanPeriodChange = (e) => {
    setLoanPeriod(e.target.value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const handleMakeOffer = async (loan_id) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        offer_amount: parseFloat(offer_amount),
        offer_interest_rate: parseFloat(offer_interest_rate),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(offer_principal),
        offer_monthly_installment: parseFloat(offer_monthly_installment),
        offer_revenue: parseFloat(offer_revenue),
        total_admin_fee: parseFloat(total_admin_fee),
        admin_fee: parseFloat(admin_fee),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      path = "/pending-acceptance";

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };
  const buttonStyleExpress = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background: "#FED065",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isWithinSevenDays = (repayDueDate) => {
    const today = new Date();
    const dueDate = new Date(repayDueDate);

    // Calculate the difference in milliseconds
    const differenceInMs = dueDate - today;

    // Calculate the difference in days
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    // Check if the due date is within 7 days from today
    console.log(differenceInDays <= 7 && differenceInDays >= 0);
    return differenceInDays <= 7 && differenceInDays >= 0;
  };
  const [ppicture, setppicture] = useState(null);

  const getProfilePicture = async (fileName) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/profile/viewPicture?file_name=${fileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      setppicture(imageUrl);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {screenWidth > 1200 ? (
        <div style={{ padding: "0px 20px", width: "100%" }}>
          {/* HEADER */}
          {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      </Box> */}

          {/* GRID & CHARTS */}
          <div style={{ display: "flex", width: "100%" }}>
            {/* ROW 2 */}
            <div
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                height: "90vh",
                borderRadius: "10px",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
                paddingRight: "20px",
                paddingLeft: "20px",
                paddingBottom: "20px",
                paddingTop: "20px",
                width: "74.5%",
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "11vh",
                }}
              >
                <img
                  src={adspot_ico}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "1",
                  }}
                />
                <button
                  className="apply_now_button"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "86%",
                    transform: "translate(-50%, -50%)",
                    zIndex: "2",
                    borderRadius: "20px",
                    color: "#142C44",

                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    padding: "5px 25px",
                    width: "140px",
                    border: "none",
                  }}
                  onClick={(event) => handleApplyLoan(event)}
                >
                  Apply Now
                </button>
              </div>

              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    color={colors.grey[920]}
                    variant="h5"
                    fontWeight="800"
                    fontFamily="Manrope, sans-serif"
                    fontSize="18px"
                  >
                    Upcoming Payment
                  </Typography>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      color: "grey",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    {dataSummary && dataSummary.length > 0 ? (
                      dataSummary.map((loanItem, index) =>
                        loanItem.loan.map((item) =>
                          isWithinSevenDays(formatDate(item.repay_due_date)) ? (
                            <>
                              <Container2
                                key={index}
                                icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                                title={
                                  item.amount
                                    ? item.amount.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })
                                    : "-"
                                }
                                repay_id={item.repay_id}
                                label1="Principal:"
                                description1={
                                  item.repay_principal
                                    ? item.repay_principal.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "SGD",
                                        }
                                      )
                                    : "-"
                                }
                                label2="Interest:"
                                description2={
                                  item.repay_interest
                                    ? item.repay_interest.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "SGD",
                                        }
                                      )
                                    : "-"
                                }
                                label4={`Your payment will be deducted from your main account (${
                                  item.bank_name || "-"
                                }: ${item.bank_account_no || "-"}) 
                                on or after the stated date.`}
                                label="Last Payment Date"
                                description={
                                  item.repay ? formatDate(item.repay) : "-"
                                }
                                label3="Due Date"
                                description3={
                                  item.repay_due_date
                                    ? formatDate(item.repay_due_date)
                                    : "-"
                                }
                                index={index}
                              />
                              <Modal
                                style={{
                                  backgroundColor: "rgba(0, 0, 0, 0)",
                                  margin: "10% auto",
                                }}
                                open={modalStates[item.repay_id]}
                                onCancel={() =>
                                  setModalStates((prevStates) => ({
                                    ...prevStates,
                                    [item.repay_id]: false,
                                  }))
                                }
                                footer={[
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: 16,
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        handleMakePayment(item.repay_id)
                                      }
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      type="default"
                                      onClick={() =>
                                        setModalStates((prevStates) => ({
                                          ...prevStates,
                                          [item.repay_id]: false,
                                        }))
                                      }
                                    >
                                      No
                                    </Button>
                                  </div>,
                                ]}
                              >
                                Are you sure you want to confirm this payment?
                              </Modal>
                            </>
                          ) : null
                        )
                      )
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "0px 15px",
                            background: "rgba(255,255,255,0.7)",
                            borderRadius: "10px",
                            height: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Manrope, sans-serif",
                            fontSize: "0.8em",
                            fontWeight: "600",
                            color: "#00000066",
                          }}
                        >
                          No upcoming payment records
                        </div>
                      </div>
                    )}

                    {dataSummary && dataSummary.length > 0 && <Container4 />}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%", gap: "15px" }}>
                <div style={{ width: "60%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "30px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Ongoing Loan
                    </Typography>
                  </div>

                  <div
                    style={{ display: "flex", width: "100%", color: "grey" }}
                  >
                    {dataSummary &&
                    dataSummary.length > 0 &&
                    dataSummary[0].loan.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <ContainerOngoingLoan
                          key={0}
                          separator={true}
                          title={dataSummary[0].loan[0].lender_name || "-"}
                          subtitle={
                            dataSummary[0].loan[0].express === 1
                              ? "Express Applicant"
                              : "Approved Borrower"
                          }
                          label={dataSummary[0].loan[0].amount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                          credit_score={dataSummary[0].loan[0].credit_score}
                          description={`You've paid ${
                            dataSummary[0].loan[0]?.credit_score.toFixed(2) ||
                            "-"
                          }% of the loan`}
                          label1="Lender"
                          description1={dataSummary[0].loan[0].amount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                          label2="Period"
                          description2={
                            dataSummary[0].loan[0].period + " Month" || "-"
                          }
                          index={0}
                          loan_id={dataSummary[0].loan[0].loan_id}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "0px 15px",
                            background: "rgba(255,255,255,0.7)",
                            borderRadius: "10px",
                            height: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Manrope, sans-serif",
                            fontSize: "0.8em",
                            fontWeight: "600",
                            color: "#00000066",
                          }}
                        >
                          No on-going loan records
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "40%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "30px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Requested Loans
                    </Typography>
                  </div>
                  {dataActivityLog && dataActivityLog.length > 0 ? (
                    dataActivityLog.slice(0, 3).map((item, index) => (
                      <ContainerActivity
                        key={index}
                        icon="https://www.gizmochina.com/wp-content/uploads/2018/01/Logo-Sony.jpg"
                        title={
                          item.request_amount
                            ? item.request_amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        subtitle={item.request_interest_rate || "-"}
                        date={formatDate(item.created_at)}
                        index={index}
                      />
                    ))
                  ) : (
                    <Box
                      gridColumn="span 1"
                      display="flex"
                      alignItems="left"
                      justifyContent="left"
                    >
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "0px 15px",
                            background: "rgba(255,255,255,0.7)",
                            borderRadius: "10px",
                            height: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Manrope, sans-serif",
                            fontSize: "0.8em",
                            fontWeight: "600",
                            color: "#00000066",
                          }}
                        >
                          No requested loan data
                        </div>
                      </div>
                    </Box>
                  )}
                </div>
              </div>
            </div>
            <div style={{ width: "calc(25.5% - 20px)", marginLeft: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Loan Categories
                </Typography>
              </div>
              <Box
                gridColumn="span 2"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="130px"
                flexDirection="column"
                backgroundColor="#FFFFFFB3"
                borderRadius="10px"
                marginBottom="2vh"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    margin="0 40px"
                  >
                    <IconButton
                      style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "1000px",
                        border: "2px solid #f0f0f0",
                        background: "#9EB5CE",
                      }}
                    >
                      <img
                        src={briefcase}
                        alt="Home"
                        style={{
                          color: "#0E5AA5",
                          width: "80%",
                          height: "80%",
                        }}
                      />
                    </IconButton>
                    <label
                      style={{
                        color: "#142C44",
                        fontFamily: "Poppins,sans-serif",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Business
                    </label>
                  </Box>
                  {/* <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="0 40px"
              >
                <IconButton
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "1000px",
                    border: "2px solid #f0f0f0",
                    background: "#9EB5CE",
                  }}
                >
                  <img
                    src={house}
                    alt="Home"
                    style={{ color: "#0E5AA5", width: "80%", height: "80%" }}
                  />
                </IconButton>
                <label
                  style={{
                    color: "#142C44",
                    fontFamily: "Poppins,sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Property
                </label>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="0 40px"
              >
                <IconButton
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "1000px",
                    border: "2px solid #f0f0f0",
                    background: "#9EB5CE",
                    opacity: 1,
                  }}
                >
                  <img
                    src={currency}
                    alt="Home"
                    style={{ color: "#0E5AA5", width: "80%", height: "80%" }}
                  />
                </IconButton>
                <label
                  style={{
                    color: "#142C44",
                    fontFamily: "Poppins,sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Other
                </label>
              </Box> */}
                </Box>
              </Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Loan Calculator
                </Typography>
              </div>
              <div
                style={{
                  overflowY: "hidden",
                  overflowX: "hidden",
                  backgroundColor: "#FFFFFFB3",
                  height: "57vh",
                  padding: "15px",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <h5
                    style={{
                      fontWeight: "700",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "#142C44CC",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Loan Amount Requested
                  </h5>
                </div>
                <div>
                  {/* <span style={{fontSize: "24px", fontFamily: "Manrope, sans-serif", color: "#142C44", marginLeft: '5px'}}>$</span> */}

                  <InputNumber
                    style={{
                      fontSize: "24px",
                      fontWeight: "800",
                      fontFamily: "Manrope, sans-serif",
                      color: "#142C44",
                      background: "transparent",
                      border: "none",
                      borderBottom: "none",
                      marginTop: "7px",
                      width: "50%",
                    }}
                    min={1000}
                    max={1000000}
                    value={sliderValueAmount}
                    onChange={handleSliderChangeAmount}
                  />
                  <Slider
                    min={1000}
                    max={1000000}
                    value={
                      typeof sliderValueAmount === "number"
                        ? sliderValueAmount
                        : 1000
                    }
                    onChange={handleSliderChangeAmount}
                    trackStyle={{
                      background:
                        "linear-gradient(to right, #b1c3e0 0%, #1557a9 100%)",
                    }}
                    railStyle={{
                      backgroundColor: "grey",
                      opacity: 0.4, // Adjust this color to your need.
                    }}
                    style={{ marginTop: "-5px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "-10px 0px 15px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Min $1000
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Max $1000,000
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h5
                    style={{
                      fontWeight: "700",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "#142C44CC",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Tenure Period
                  </h5>
                  <div>
                    <button
                      style={{
                        borderRadius: "20px",
                        marginRight: "5px",
                        background: "transparent",
                        color: hovered ? "#0E5AA5" : "grey",
                        border: hovered
                          ? "1px solid #0E5AA5"
                          : "1px solid grey",
                        fontSize: "14",
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      onClick={() => {
                        setSliderMin(1);
                        setSliderMax(36);
                        setLabel("Month");
                      }}
                      onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
                      onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
                    >
                      Month
                    </button>
                    <button
                      style={{
                        borderRadius: "20px",
                        background: "transparent",
                        color: hovered2 ? "#0E5AA5" : "grey",
                        border: hovered2
                          ? "1px solid #0E5AA5"
                          : "1px solid grey",
                        fontSize: "14",
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      onClick={() => {
                        setSliderMin(1);
                        setSliderMax(3);
                        setLabel("Year");

                        setSliderValuePeriodInMonths(sliderValuePeriod * 12); // Update the sliderValuePeriodInMonths state variable
                      }}
                      onMouseEnter={() => setHovered2(true)} // set the state hovered to true  when mouse enter
                      onMouseLeave={() => setHovered2(false)} // set the state hovered to false
                    >
                      Year
                    </button>
                  </div>
                </div>

                <div>
                  <InputNumber
                    style={{
                      fontSize: "24px",
                      fontWeight: "800",
                      fontFamily: "Manrope, sans-serif",
                      width: "20%",
                      color: "#142C44",
                      background: "transparent",
                      border: "none",
                      borderBottom: "none",
                      marginTop: "7px",
                    }}
                    min={1}
                    max={36}
                    value={sliderValuePeriod}
                    onChange={handleSliderChangePeriod}
                  />
                  <span
                    style={{
                      fontWeight: "700",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "#142C44CC",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    {label}
                  </span>

                  <Slider
                    min={sliderMin}
                    max={sliderMax}
                    value={
                      typeof sliderValuePeriod === "number"
                        ? sliderValuePeriod
                        : sliderMin
                    }
                    onChange={handleSliderChangePeriod}
                    trackStyle={{
                      background:
                        "linear-gradient(to right, #b1c3e0 0%, #1557a9 100%)",
                    }}
                    railStyle={{
                      backgroundColor: "grey",
                      opacity: 0.4, // Adjust this color to your need.
                    }}
                    style={{ marginTop: "-5px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "-10px 0px 15px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    {`Min ${sliderMin} ${label}`}
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    {`Max ${sliderMax} ${label}`}
                  </p>
                </div>
                <div>
                  <h5
                    style={{
                      fontWeight: "700",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "#142C44CC",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Monthly Interest Rate
                  </h5>
                </div>
                <div>
                  <InputNumber
                    style={{
                      fontSize: "24px",
                      fontWeight: "800",
                      fontFamily: "Manrope, sans-serif",
                      width: "20%",
                      color: "#142C44",
                      background: "transparent",
                      border: "none",
                      borderBottom: "none",
                      marginTop: "7px",
                    }}
                    min={1}
                    max={5}
                    value={sliderValueInterest}
                    onChange={handleSliderChangeInterest}
                  />
                  <span
                    style={{
                      fontWeight: "700",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "#142C44CC",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    %
                  </span>

                  <Slider
                    min={1}
                    max={5}
                    value={
                      typeof sliderValueInterest === "number"
                        ? sliderValueInterest
                        : 1
                    }
                    onChange={handleSliderChangeInterest}
                    trackStyle={{
                      background:
                        "linear-gradient(to right, #b1c3e0 0%, #1557a9 100%)",
                    }}
                    railStyle={{
                      backgroundColor: "grey",
                      opacity: 0.4, // Adjust this color to your need.
                    }}
                    style={{ marginTop: "-5px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "-10px 0px 15px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Min 1%
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Max 5%
                  </p>
                </div>
                <div>
                  <hr style={{ color: "#142C44CC", height: "2px" }} />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontFamily: "Manrope,sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    Monthly Installment
                  </h5>
                  <h5
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontFamily: "Manrope,sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    {monthlyInstallment
                      ? monthlyInstallment.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })
                      : "$0.00"}
                  </h5>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontFamily: "Manrope,sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    Total Interest Paid
                  </h5>
                  <h5
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontFamily: "Manrope,sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    {totalInterestPaid
                      ? totalInterestPaid.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })
                      : "$0.00"}
                  </h5>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontFamily: "Manrope,sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    Yearly Interest Paid
                  </h5>
                  <h5
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontFamily: "Manrope,sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    {yearlyInterestPaid
                      ? yearlyInterestPaid.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })
                      : "$0.00"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 0px",
              height: `80vh`,
              borderRadius: "10px",
              width: "95%",
              marginLeft: "2.5%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                position: "relative",
                height: "70px",
                width: "calc(100% - 20px)",
                marginLeft: "10px",
                paddingTop: "10px",
              }}
            >
              <img
                src={adspot_ico}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  position: "relative",
                  top: "0",
                  left: "0",
                  zIndex: "1",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  zIndex: 5,
                  top: 10,
                  right: 10,
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    borderRadius: "20px",
                    fontSize: "12px",
                    fontWeight: "600",
                    padding: "8px 15px",
                    fontFamily: "Manrope, sans-serif",
                    color: "#115CA6",
                    border: "none",
                  }}
                  onClick={(event) => handleApplyLoan(event)}
                >
                  Apply Now
                </button>
              </div>
            </div>
            <div
              style={{
                width: "calc(100% - 20px)",
                marginLeft: "10px",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
                marginTop="20px"
                marginBottom="15px"
              >
                Upcoming Payment
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                color: "grey",
                width: "calc(100% - 20px)",
                margin: "10px 0px 0px 10px",
                overflowX: "auto",
                gap: "10px",
              }}
            >
              {dataSummary && dataSummary.length > 0 ? (
                dataSummary.map((loanItem, index) =>
                  loanItem.loan.map((item) => (
                    <div>
                      <Container2
                        key={index}
                        icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        title={
                          item.amount
                            ? item.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        repay_id={item.repay_id}
                        label1="Principal:"
                        description1={
                          item.repay_principal
                            ? item.repay_principal.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label2="Interest:"
                        description2={
                          item.repay_interest
                            ? item.repay_interest.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label4={`Your payment will be deducted from your main account (${
                          item.bank_name || "-"
                        }: ${item.bank_account_no || "-"}) 
                                on or after the stated date.`}
                        label="Last Payment Date"
                        description={item.repay ? formatDate(item.repay) : "-"}
                        label3="Due Date"
                        description3={
                          item.repay_due_date
                            ? formatDate(item.repay_due_date)
                            : "-"
                        }
                        index={index}
                      />
                      <Modal
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          margin: "10% auto",
                        }}
                        open={modalStates[item.repay_id]}
                        onCancel={() =>
                          setModalStates((prevStates) => ({
                            ...prevStates,
                            [item.repay_id]: false,
                          }))
                        }
                        closeIcon={<div></div>}
                        footer={[
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 16,
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={() => handleMakePayment(item.repay_id)}
                            >
                              Yes
                            </Button>
                            <Button
                              type="default"
                              onClick={() =>
                                setModalStates((prevStates) => ({
                                  ...prevStates,
                                  [item.repay_id]: false,
                                }))
                              }
                            >
                              No
                            </Button>
                          </div>,
                        ]}
                      >
                        Are you sure you want to confirm this payment?
                      </Modal>
                    </div>
                  ))
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No upcoming payment records
                  </div>
                </div>
              )}
              <div>
                {dataSummary && dataSummary.length > 0 && <Container4 />}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "calc(100% - 20px)", marginLeft: "10px" }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[100]}
                  style={{ margin: "20px 0px 10px 0px" }}
                >
                  <Typography
                    color={colors.grey[920]}
                    variant="h5"
                    fontWeight="800"
                    fontFamily="Manrope, sans-serif"
                    fontSize="20px"
                  >
                    Ongoing Loan
                  </Typography>
                </Box>

                <div style={{ display: "flex", width: "100%", color: "grey" }}>
                  {dataSummary &&
                  dataSummary.length > 0 &&
                  dataSummary[0].loan.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ContainerOngoingLoan
                        key={0}
                        separator={true}
                        icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        title={dataSummary[0].loan[0].lender_name || "-"}
                        subtitle={
                          dataSummary[0].loan[0].express === 1
                            ? "Express Applicant"
                            : "Approved Borrower"
                        }
                        label={dataSummary[0].loan[0].amount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                        credit_score={dataSummary[0].loan[0].credit_score}
                        description={`You've paid ${
                          dataSummary[0].loan[0]?.credit_score.toFixed(2) || "-"
                        }% of the loan`}
                        label1="Lender"
                        description1={dataSummary[0].loan[0].amount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                        label2="Period"
                        description2={
                          dataSummary[0].loan[0].period + " Month" || "-"
                        }
                        index={0}
                        loan_id={dataSummary[0].loan[0].loan_id}
                      />
                    </div>
                  ) : (
                    <ContainerRow3 noData="No Ongoing Loan Data" />
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                width: `calc(${window.innerHeight} - 120px)`,
                marginLeft: "10px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[100]}
                style={{ margin: "20px 0px 10px 0px" }}
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                >
                  Requested Loans
                </Typography>
              </Box>
              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 3).map((item, index) => (
                  <Box
                    gridColumn="span 1"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <ContainerActivity
                      key={index}
                      icon="https://www.gizmochina.com/wp-content/uploads/2018/01/Logo-Sony.jpg"
                      title={
                        item.request_amount
                          ? item.request_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                      subtitle={item.request_interest_rate || "-"}
                      date={formatDate(item.created_at)}
                      index={index}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  gridColumn="span 1"
                  display="flex"
                  alignItems="left"
                  justifyContent="left"
                >
                  <ContainerActivity2 noData="No Requested Loans Data" />
                </Box>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loan;
