import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import adspot_ico from "../assets/img/adSpot.jpg";
import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Avatar,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
  message,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { Collapse } from "antd";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import _ from "lodash";
import TableAdvertisement from "../components/TableAdvertisement";

const { Option } = Select;

const options = [
  "bDashboard",
  "lDashboard",
  "bApplyLoan",
  "bTransaction",
  "bProfile",
  // { value: "lProfile", label: "Lender Profile" },
];

const onChange = (key) => {
  console.log(key);
};

const Advertisement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [publishPeriod, setPublishPeriod] = useState("");
  const [description, setDescription] = useState("");
  const [screen, setScreen] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState(null);
  const history = useHistory();
  const { TextArea } = Input;
  // const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [adsData, setAdsData] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");
  //   const [title, setTitle] = useState("");
  // const [publishPeriod, setPublishPeriod] = useState("");
  // const [description, setDescription] = useState("");
  // const [screen, setScreen] = useState("");
  const [imageUrl, setImageUrl] = useState(""); // For the Dragger
  const [currentAdsId, setCurrentAdsId] = useState(null);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setScreen(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const fetchAdsData = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/ads/getAllAds",
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );
      const json = await response.json();
      setAdsData(json);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdsData();
  }, []);

  const handleBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Can only upload file in format .jpeg , .jpg and .png");
    } else {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      console.log("Photo ready to upload", file);
      setSelectedFile(file);

      return false; // Prevent file from being uploaded automatically
    }
    return isJpgOrPng;
  };

  const [fileList, setFileList] = useState([]);

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: false,
    listType: "picture",
    accept: ".jpeg, .jpg, .png",
    action: "https://smart-lend.com/a-advertisement",
    beforeUpload: handleBeforeUpload,
    onChange(info) {
      setFileList([info.file]); // set fileList to contain only the latest file

      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setImageUrl(info.file.originFileObj); // set the File object to the state
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  let ads_id;
  const [existingId, setExistingId] = useState(null);

  const handleNewAd = () => {
    setExistingId(null);
    setTitle("");
    setPublishPeriod("");
    setDescription("");
    setLink(null);
    setScreen("");
    setImageUrl(""); // Set the blob URL to the imageUrl state
    setSelectedFile(null);
  };

  const handleRowClick = async (record) => {
    const ads_id = record.ads_id;
    try {
      console.log(record);
      // Update state variables with the data from the API
      setExistingId(record.id);
      setTitle(record.title);
      setPublishPeriod(record.publish_period);
      setDescription(record.description);
      setLink(record.link);
      setScreen([record.screen]);
      setImageUrl(record.image); // Set the blob URL to the imageUrl state
    } catch (error) {
      console.error("Error retrieving Ad:", error);
    }
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: screenWidth > 1200 ? 9 : 4,
      span: 24,
    },
  };

  const handleSubmitAd = async () => {
    try {
      if (selectedFile) {
        const formData = new FormData();
        console.log(selectedFile);
        formData.append("file", selectedFile);
        const response1 = await fetch(
          "https://www.smart-lend.com/api/file/uploadUniversalFile",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response1.ok) {
          const data = await response1.json();
          const imageUrl = data.url;

          let item = {
            screen: screen,
            publish_period: publishPeriod,
            title: title,
            description: description,
            link: link,
            image: imageUrl,
          };
          if (existingId) {
            item.existingId = existingId;
          }

          try {
            const response = await fetch(
              "https://www.smart-lend.com/api/ads/createAds",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Cookies.get("token")}`,
                },
                body: JSON.stringify(item),
              }
            );
            if (response.status === 200) {
              const json = await response.json();
              console.log(json);
            }
          } catch (error) {
            console.error(error);
          }

          console.log(item);
        } else {
          // Handle non-OK response status
          console.error("Failed to upload files");
        }
      } else {
        let item = {
          screen: screen,
          publish_period: publishPeriod,
          title: title,
          description: description,
          link: link,
          image: imageUrl,
        };
        if (existingId) {
          item.existingId = existingId;
        }

        try {
          const response = await fetch(
            "https://www.smart-lend.com/api/ads/createAds",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify(item),
            }
          );
          if (response.status === 200) {
            const json = await response.json();
            console.log(json);
          }
        } catch (error) {
          console.error(error);
        }
      }

      // const response = await fetch(
      //   "https://www.smart-lend.com/api/ads/createAds",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + Cookies.get("token"),
      //     },
      //     body: JSON.stringify(item),
      //   }
      // );

      // const data = await response.json();
      // message.success("Ads created successfully");

      // console.log("Offer created:", data);

      fetchAdsData(); // fetch the latest ads data
    } catch (error) {
      console.error("Error creating offer:", error);
      message.error("Error creating offer");
    }
  };

  const handleUpdateAd = async () => {
    try {
      // Check if a new image has been selected
      if (selectedFile) {
        // Upload the new image first
        let uploadFormData = new FormData();
        uploadFormData.append("image", selectedFile);

        const uploadResponse = await fetch(
          `https://www.smart-lend.com/api/ads/uploadAds/${currentAdsId}`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: uploadFormData,
          }
        );

        const uploadData = await uploadResponse.json();
        console.log("Ads image uploaded:", uploadData);
        message.success("Image uploaded successfully");

        // Update the imageUrl to the new uploaded image URL (if the API returns it)
        // imageUrl = uploadData.newImageUrl; // Assuming the API returns the new image URL
      }

      // Now, update the ad details
      let item = {
        screen: screen,
        publish_period: publishPeriod,
        title: title,
        description: description,
        // link: link,
        image: imageUrl, // This will be the existing image URL or the new one if uploaded
        status: "active",
      };

      const response = await fetch(
        `https://www.smart-lend.com/api/ads/updateAdById/${currentAdsId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );

      const data = await response.json();
      console.log("Ad updated:", data);
      message.success("Ads updated successfully");

      // Update your UI or close the modal
      fetchAdsData(); // fetch the latest ads data
    } catch (error) {
      console.error("Error updating ad:", error);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 12"
              gridRow="span 2"
              // overflow="auto"
              backgroundColor={colors.grey[910]}
              background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
              borderRadius="10px"
              border="1px solid "
              opacity="0.95"
              style={{
                overflowY: "scroll",
                minHeight: "89.7vh",
              }}
            >
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                border-radius="20px"
                opacity="0.95"
              >
                <Card
                  style={{
                    overflowY: "scroll",
                    minHeight: "89.7vh",
                  }}
                >
                  <Form
                    style={{ minHeight: "73vh" }}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      borderRadius="10px"
                      margin="20px 20px 20px 0px"
                    >
                      <Typography
                        color={colors.grey[920]}
                        variant="h5"
                        fontWeight="800"
                        fontFamily="Manrope, sans-serif"
                        fontSize="20px"
                      >
                        Advertisement List
                      </Typography>
                    </Box>
                    <hr
                      style={{
                        color: "#142C44CC",
                        height: "2px",
                        width: "96.5%",
                      }}
                    />
                    <Row>
                      <Col span={23}>
                        <TableAdvertisement
                          data={adsData}
                          fetchData={fetchAdsData}
                          onRowClick={handleRowClick}
                          onSelectionChange={setAdsData}
                        />
                      </Col>
                    </Row>
                    <hr
                      style={{
                        color: "#142C44CC",
                        height: "2px",
                        width: "96.5%",
                      }}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      borderRadius="10px"
                      margin="20px 20px 20px 0px"
                    >
                      <Typography
                        color={colors.grey[920]}
                        variant="h5"
                        fontWeight="800"
                        fontFamily="Manrope, sans-serif"
                        fontSize="20px"
                      >
                        Advertisement Application
                      </Typography>
                    </Box>
                    <Row>
                      <Col span={11}>
                        <Form.Item label="Photo" name="photo">
                          <Dragger style={{ width: "90%" }} {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag image to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Support for a file with format jpeg, jpg and png
                              only. Strictly prohibited from uploading company
                              data or other banned files.
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "10px" }}>
                      <Col span={11}>
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="Uploaded"
                            style={{ width: "100%", maxHeight: "200px" }}
                          />
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item label="Name">
                          <Input
                            style={{ width: "90%" }}
                            type="text"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Publish Period (Month)">
                          <Input
                            style={{ width: "90%" }}
                            type="text"
                            value={publishPeriod}
                            onChange={(event) =>
                              setPublishPeriod(event.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            margin: 0,
                          }}
                        >
                          <div style={{ marginBottom: 12 }}>
                            Advertising link
                          </div>
                          <input
                            type="checkbox"
                            checked={link !== null ? true : false}
                            onChange={(e) => {
                              if (e.target.checked === false) {
                                setLink(null);
                              } else {
                                setLink("");
                              }
                            }}
                          />
                        </div>
                        {link !== null ? (
                          <Form.Item label="">
                            <Input
                              rows={3}
                              style={{ width: "90%" }}
                              value={link}
                              onChange={(event) => setLink(event.target.value)}
                            />
                          </Form.Item>
                        ) : null}
                        <Form.Item label="Description">
                          <TextArea
                            rows={3}
                            style={{ width: "90%" }}
                            value={description}
                            onChange={(event) =>
                              setDescription(event.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <div>Screen</div>
                        <div
                          style={{
                            width: "90%",
                          }}
                        >
                          {options.map((opt, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                checked={screen.includes(opt) ? true : false}
                                onChange={(e) => {
                                  const updatingScreens = [...screen];
                                  if (
                                    e.target.checked === true &&
                                    !updatingScreens.includes(opt)
                                  ) {
                                    updatingScreens.push(opt);
                                    console.log(updatingScreens);
                                    setScreen(updatingScreens);
                                  } else if (
                                    e.target.checked === false &&
                                    updatingScreens.includes(opt)
                                  ) {
                                    const filteredScreens =
                                      updatingScreens.filter(
                                        (screenOpt) => screenOpt !== opt
                                      );
                                    console.log(filteredScreens);
                                    setScreen(filteredScreens);
                                  }
                                }}
                              />
                              {opt}
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                  <Col span={12}>
                    <Form.Item label="Button URL" name="link">
                      <Input
                        style={{ width: "90%" }}
                        type="text"
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                    <hr
                      style={{
                        color: "#142C44CC",
                        height: "2px",
                        width: "96.5%",
                      }}
                    />

                    {/* <Form.Item {...tailLayout}> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => handleNewAd()}
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 600,
                          borderRadius: "10px",
                          width: "25%",
                          background: "rgb(14, 90, 165)",
                          height: "40px",
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => handleSubmitAd()}
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 600,
                          marginLeft: "10px",
                          borderRadius: "10px",
                          width: "25%",
                          background: "rgb(14, 90, 165)",
                          height: "40px",
                        }}
                      >
                        Submit Ad
                      </Button>
                    </div>

                    {/* <Button
                        type="default"
                        htmlType="submit"
                        onClick={() => handleUpdateAd(ads_id)} // Pass ads_id here
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 600,
                          marginLeft: "10px",
                          borderRadius: "10px",
                          width: "25%",
                          border: "1px solid grey",
                          height: "40px",
                        }}
                      >
                        Update Ad
                      </Button> */}
                    {/* </Form.Item> */}
                  </Form>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                // background:"transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
                borderRadius: "20px",
                opacity: "0.95",
                // width:"100%"
              }}
            >
              <Card
                style={{
                  minHeight: "89.7vh",
                  overflowX: "hidden",
                }}
              >
                <Form
                  style={{ minHeight: "73vh" }}
                  {...layout}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    borderRadius="10px"
                    margin="20px 20px 20px 0px"
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="20px"
                    >
                      Advertisement List
                    </Typography>
                  </Box>
                  <hr
                    style={{ color: "#142C44CC", height: "2px", width: "100%" }}
                  />
                  <Row>
                    <div>
                      <TableAdvertisement
                        data={adsData}
                        fetchData={fetchAdsData}
                        onRowClick={handleRowClick}
                        onSelectionChange={setAdsData}
                      />
                    </div>
                  </Row>
                  <hr
                    style={{ color: "#142C44CC", height: "2px", width: "100%" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      borderRadius: "10px",
                      margin: "20px 0px 20px 0px",
                    }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="20px"
                    >
                      Advertisement Application
                    </Typography>
                  </div>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Photo" name="photo">
                        <Dragger style={{ width: "100%" }} {...props}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag image to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a file with format jpeg, jpg and png
                            only. Strictly prohibited from uploading company
                            data or other banned files.
                          </p>
                        </Dragger>
                        {/* {imageUrl ? <img src={imageUrl} alt="Uploaded" /> : null} */}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Form.Item label="Name">
                        <Input
                          style={{ width: "100%" }}
                          type="text"
                          value={title}
                          onChange={(event) => setTitle(event.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Publish Period (Month)">
                        <Input
                          style={{ width: "100%" }}
                          type="text"
                          value={publishPeriod}
                          onChange={(event) =>
                            setPublishPeriod(event.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Description">
                        <TextArea
                          rows={3}
                          style={{ width: "100%" }}
                          value={description}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Screen">
                        <Select
                          style={{ width: "100%" }}
                          value={screen}
                          onChange={(value) => setScreen(value)}
                        >
                          {options.every((option) =>
                            adsData.some((ad) => ad.screen === option.value)
                          ) ? (
                            <Select.Option value="" disabled>
                              All screens selected, please delete one of your
                              ads
                            </Select.Option>
                          ) : (
                            options
                              .filter(
                                (option) =>
                                  !adsData.some(
                                    (ad) => ad.screen === option.value
                                  )
                              )
                              .map((option) => (
                                <Select.Option
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col span={24}>
                    <Form.Item label="Button URL" name="link">
                      <Input
                        style={{ width: "100%" }}
                        type="text"
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                  <hr
                    style={{ color: "#142C44CC", height: "2px", width: "100%" }}
                  />

                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => handleSubmitAd()}
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,

                        borderRadius: "10px",
                        width: "35%",
                        background:
                          "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
                        height: "40px",
                      }}
                    >
                      Submit Ad
                    </Button>

                    {/* <Button
                      type="default"
                      htmlType="submit"
                      onClick={() => handleUpdateAd(ads_id)} // Pass ads_id here
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        marginLeft: "10px",
                        borderRadius: "10px",
                        width: "35%",
                        border: "1px solid grey",
                        height: "40px",
                      }}
                    >
                      Update Ad
                    </Button> */}
                  </Form.Item>
                </Form>
              </Card>
              <div>
                <p style={{ color: "transparent" }}>-</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Advertisement;
