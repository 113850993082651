import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import cookies from "js-cookie";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const TinyLineChartBorrower = ({ title, subtitle }) => {
  const [data, setData] = useState([]);
  const [averageInterestRate, setAverageInterestRate] = useState(null);
  const [sumInterestRate, setSumInterestRate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/dashboard/monthlyInterestRate",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const allInterestRate = json["All Interest Rate (Current Month)"];
      const currentMonthAverage = json["Current Month Average Interest Rate"];
      const previousMonthAverage = json["Previous Month Average Interest Rate"];
      const averageInterestRate = currentMonthAverage - previousMonthAverage;
      setData(allInterestRate);
      console.log(averageInterestRate);
      setAverageInterestRate((averageInterestRate * 100).toFixed(1));
      setSumInterestRate((calculateSumInterestRate(allInterestRate) * 100).toFixed(1));
    };

    fetchData();
  }, []);

  const calculateSumInterestRate = (interestRates) => {
    let sum = 0;
    interestRates.forEach((rate) => {
      sum += rate.interest_rate;
    });
    return sum;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div
          className="custom-tooltip"
          style={{
            width: "50%",
            background: "rgba(256,256,256,0.95)",
            border: "none",
            outline: "none",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            padding: "10px 10px 1px 10px",
            // marginTop: "100px",
          }}
        >
          <p className="label">{`Interest Rate: ${payload[0].value}%`}</p>
        </div>
      );
    }

    return null;
  };

  const [screenWidthx, setScreenWidthx] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidthx(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: "grey",
        border: "1px solid none",
        backgroundColor: "#FFFFFFB3",
        borderRadius: "10px",
        height: "170px",
        justifyContent: "left",
        alignItems: "left",
        position: "relative",
      }}
    >
      <div
        style={{ marginTop: "0px", marginBottom: "0px", marginLeft: "20px" }}
      >
        <ResponsiveContainer
          width="90%"
          height={100}
          margin={{ top: 0, bottom: 10 }}
        >
          <LineChart data={data} margin={{ top: 10, bottom: 10 }}>
            <Line
              // type="linear"
              dataKey="interest_rate"
              stroke="#115CA6"
              strokeWidth={5}
              dot={{ r: 2 }}
              type="monotone" // Specify the interpolation type as "monotone"
            />

            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{ outline: "none" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          textAlign: "left",
          position: "absolute",
          left: 10,
          bottom: 10,
          width: "100%",
        }}
      >
        <h5
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            color: "#142C44",
            letterSpacing: "-1px",
            fontSize: screenWidthx < 800 ? "18px" : "25px",
          }}
        >
          {sumInterestRate ? `${sumInterestRate}%` : "-"}
        </h5>
        <p
          style={{
            paddingRight: "0px",
            marginTop: "-7px",
            marginBottom: "0px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "800",
            color: "#142C44",
          }}
        >
          Monthly Interest Rate
        </p>
        <div
          style={{
            divaddingRight: "0px",
            marginTop: "-2px",
            marginBottom: "0px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "600",
            color: "#00000066",
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <p style={{ margin: "0px" }}>Average up by</p>{" "}
          <div
            style={{
              margin: "0px",
              color:
                averageInterestRate !== null && averageInterestRate < 0
                  ? "red"
                  : "green",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              {averageInterestRate !== null && averageInterestRate < 0 ? (
                <ArrowCircleDownIcon
                  style={{ fontSize: "14px", marginRight: "3px", color: "red" }}
                />
              ) : (
                <ArrowCircleUpIcon
                  style={{
                    fontSize: "14px",
                    marginRight: "3px",
                    color: "green",
                  }}
                />
              )}
            </div>
            <div>
              {averageInterestRate
                ? `${averageInterestRate.toLocaleString()}%`
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TinyLineChartBorrower;
