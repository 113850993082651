import React, { useState } from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const RedFlagged = () => {
  const [data, setData] = useState([]);
  const [numberOfLoanVisibility, setNumberOfLoanVisibility] = useState(null);
  const [dataActivityLog, setActivityLog] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const processedData = await Promise.all(
          data.all_activity_log.map(async (item) => {
            const profilePictureResponse = await fetch(
              `https://www.smart-lend.com/api/loan/loanRequest/viewListProfilePicture?profile_picture=${item.profile_picture}`,
              options
            );

            if (!profilePictureResponse.ok) {
              throw new Error("Failed to fetch profile picture data");
            }

            const blob = await profilePictureResponse.blob();
            const imageUrl = URL.createObjectURL(blob);

            return {
              ...item,
              icon: imageUrl, // use the blob URL as the icon
            };
          })
        );
        const reversedData = processedData.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewLoanRequest",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const flatData = json["Requested Loan"].flat();
        console.log(flatData);
        setData(flatData);
        console.log(flatData);

        let noneVisibleLoan = 0;
        for (const loan of flatData) {
          if (!loan.visibility) {
            noneVisibleLoan += 1;
          }
        }
        // console.log(noneVisibleLoan);
        setNumberOfLoanVisibility(noneVisibleLoan);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let noneVisibleLoan = 0;
    for (const loan of data) {
      if (!loan.visibility) {
        noneVisibleLoan += 1;
      }
    }
    setNumberOfLoanVisibility(noneVisibleLoan);
  }, [data]);

  const pushToMarket = async (loanId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/visibleLoanRequest?loan_id=${loanId}&visibility=1`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      console.log(res);
      const changingData = [...data];
      const loanIndex = data.findIndex((loan) => loan.loan_id === loanId);
      console.log(data, loanIndex, loanId);
      changingData[loanIndex].visibility = 1;
      setData(changingData);
    } catch (error) {
      console.error(error);
    }
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        padding: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginRight: "10px",
            marginLeft: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
            position: "relative",
          }}
        >
          {getInitials(title)}
        </div>
        <div style={{ width: "calc(100% - 75px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        padding: "0px 0px",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "0px 15px",
          background: "rgba(255,255,255,0.7)",
          borderRadius: "10px",
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Manrope, sans-serif",
          fontSize: "0.8em",
          fontWeight: "600",
          color: "#00000066",
        }}
      >
        No activity log records
      </div>
    </div>
  );

  return (
    <div
      style={{
        width: "100%",
        height: "91vh",
        display: "flex",
        justifyContent: "space-betweeen",
        gap: "20px",
      }}
    >
      <div
        style={{
          width: "75%",
          height: "100%",
          padding: "4px 0px 20px 20px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            Red Flagged Loans
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            {numberOfLoanVisibility === 0 || numberOfLoanVisibility === null ? (
              <div
                style={{
                  width: "100%",
                  padding: "0px 0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "0px 15px",
                    background: "rgba(255,255,255,0.7)",
                    borderRadius: "10px",
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "0.8em",
                    fontWeight: "600",
                    color: "#00000066",
                  }}
                >
                  No loans pending your approval
                </div>
              </div>
            ) : (
              data
                .filter((dat) => dat.corporate_structure === false)
                .map((loan, index) =>
                  !loan.visibility ? (
                    <div
                      style={{
                        width: "23.5%",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFFB3",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        minHeight: "30vh",
                        padding: "15px",
                        position: "relative",
                      }}
                      key={index}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0px",
                          }}
                        >
                          <img
                            src={"icon"}
                            alt={""}
                            style={{
                              width: "55px",
                              height: "55px",
                              borderRadius: "1000px",
                              border: "2px solid #f0f0f0",
                            }}
                          />
                          <div style={{ marginLeft: "5px" }}>
                            <h3
                              style={{
                                fontWeight: "700",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "16px",
                                color: "#142C44CC",
                                textAlign: "left",
                                margin: "0px",
                              }}
                            >
                              {loan.principal_name ? loan.principal_name : null}
                            </h3>
                            <p
                              style={{
                                fontFamily: "Manrope, sans-serif",
                                fontWeight: "600",
                                fontSize: "11px",
                                color: "rgba(0,0,0,0.4)",
                                textAlign: "left",
                                margin: "0px",
                              }}
                            >
                              {loan.express
                                ? loan.express === 1
                                  ? "Express Applicant"
                                  : "Approved Borrower"
                                : null}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "600",
                              fontSize: "11px",
                              color: "rgba(0,0,0,0.4)",
                              textAlign: "left",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            Loan Amount
                          </p>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "16px",
                              color: "rgba(0,0,0,0.5)",
                              textAlign: "left",
                              margin: "0px 0px 10px 0px",
                            }}
                          >
                            {loan.request_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                              minimumFractionDigits: 2,
                            })}
                          </p>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "600",
                              fontSize: "11px",
                              color: "rgba(0,0,0,0.4)",
                              textAlign: "left",
                              margin: "0px",
                            }}
                          >
                            Period
                          </p>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "16px",
                              color: "rgba(0,0,0,0.5)",
                              textAlign: "left",
                              margin: "0px 0px 10px 0px",
                            }}
                          >
                            {loan.request_period} Months
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            pushToMarket(loan.loan_id);
                          }}
                          className="all_button"
                          style={{
                            background: "#1557a9",
                            color: "white",
                            width: "100%",
                            border: "none",
                            borderRadius: "50px",
                            padding: "6px 0px",
                            fontSize: "12px",
                            margin: "10px 0px 0px 0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Approve to market
                        </button>
                      </div>
                    </div>
                  ) : null
                )
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "25%",
          height: "100%",
          padding: "4px 20px 20px 0px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Activity Log
          </div>
          <div style={{ height: "78vh", overflowY: "auto", marginTop: "15px" }}>
            {dataActivityLog && dataActivityLog.length > 0 ? (
              dataActivityLog.slice(0, 8).map((item, index) => (
                <ContainerActivity
                  key={index}
                  icon={item.icon} // use the icon URL from the processed data
                  title={item.principal_name || "-"}
                  subtitle={item.description || "-"}
                  index={index}
                />
              ))
            ) : (
              <ContainerActivity2 />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedFlagged;
