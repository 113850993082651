import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CountdownWithHover from "../../components/CountDownWithHover";
import { Progress } from "antd";
import Cookies from "js-cookie";
import { Button, Modal, message } from "antd";
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import grid_normal from "../../assets/img/Grid_normal.png";
import grid_selected from "../../assets/img/grid_selected.png";
import list_normal from "../../assets/img/List_normal.png";
import list_selected from "../../assets/img/List_selected.png";
import Filter from "../../assets/img/Filter.png";
import StarIcon from "@mui/icons-material/Star";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { format } from "date-fns";
import defaultIcon from "../../assets/img/Profile.png";
import { toFloat } from "validator";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import boldFont from "../../assets/font/Poppins-SemiBold.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});

const LoanRequestGrid = ({ item }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [loanPeriod, setLoanPeriod] = useState(0);
  const [type, setLoanType] = useState("simple");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [offer_processing_fee, setProcessingFee] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const history = useHistory();
  const [minLoanAmount, setMinLoanAmount] = useState("");
  const [maxLoanAmount, setMaxLoanAmount] = useState("");
  const [creditScoreMin, setCreditScoreMin] = useState("");
  const [creditScoreMax, setCreditScoreMax] = useState("");
  const [LoanPeriodMin, setLoanPeriodMin] = useState("");
  const [LoanPeriodMax, setLoanPeriodMax] = useState("");
  const [MinYearOfIncorp, setMinYearOfIncorp] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedCreditRating, setSelectedCreditRating] = useState("");
  const [LoanInterestMin, setLoanInterestMin] = useState("");
  const [LoanInterestMax, setLoanInterestMax] = useState("");
  const [loanPeriodFilter, setLoanPeriodFilter] = useState("");
  const [loanCategoryFilters, setLoanCategoryFilters] = useState([]);
  const [filterClicked, setFilterClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [offer_amount, setLoanAmount] = useState(0);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filteredDataStatus, setFilteredDataStatus] = useState(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedCheckbox1, setSelectedCheckbox1] = useState(null);
  const [selectedCheckbox2, setSelectedCheckbox2] = useState(null);
  const [empty, setempty] = useState("0");
  const [currentID, setcurrentID] = useState(null);
  const [data, setData] = useState([]);
  const [countLoanOffer, setCountLoanOffer] = useState([]);
  const [targetDate, setTargetDate] = useState(null);
  const [notInterestedIds, setNotInterestedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [express, setExpress] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [principal_name, setPrincipalName] = useState(null);
  const [add_document_description, setAddDocumentDescription] = useState(null);
  const [documentDescriptions, setDocumentDescriptions] = useState([""]);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);
  const [notInterestedLoanId, setNotInterestedLoanId] = useState(null);

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const possiblecreditrating = [
    { value: "AA" },
    { value: "BB" },
    { value: "CC" },
    { value: "DD" },
    { value: "EE" },
    { value: "FF" },
    { value: "GG" },
    { value: "HH" },
    { value: "CX" },
    { value: "Others" },
  ];
  const SsicCodes = [
    {
      name: "AGRICULTURE AND FISHING",
      value: [
        "01",
        "011",
        "0111",
        "01111",
        "01112",
        "01113",
        "01119",
        "0112",
        "01120",
        "0113",
        "01130",
        "0114",
        "01141",
        "01142",
        "01149",
        "0119",
        "01190",
        "014",
        "0141",
        "01411",
        "01412",
        "01413",
        "0142",
        "01421",
        "01422",
        "01423",
        "01424",
        "0149",
        "01491",
        "01492",
        "01493",
        "01494",
        "01499",
        "015",
        "0150",
        "01500",
        "016",
        "0160",
        "01600",
        "02",
        "020",
        "0200",
        "02000",
        "03",
        "031",
        "0310",
        "03101",
        "03102",
        "03103",
        "03109",
        "032",
        "0320",
        "03201",
        "03202",
        "03209",
      ],
    },
    {
      name: "MINING AND QUARRYING",
      value: [
        "08",
        "081",
        "0810",
        "08101",
        "08102",
        "089",
        "0890",
        "08900",
        "09",
        "090",
        "0900",
        "09001",
        "09002",
      ],
    },
    {
      name: "MANUFACTURING",
      value: [
        "10",
        "101",
        "1010",
        "10101",
        "10102",
        "10103",
        "10104",
        "10109",
        "102",
        "1020",
        "10200",
        "103",
        "1030",
        "10301",
        "10302",
        "10303",
        "104",
        "1040",
        "10401",
        "10402",
        "10409",
        "105",
        "1050",
        "10501",
        "10502",
        "10503",
        "10509",
        "106",
        "1061",
        "10611",
        "10612",
        "10613",
        "10619",
        "1062",
        "10620",
        "107",
        "1071",
        "10711",
        "10712",
        "10719",
        "1072",
        "10720",
        "1073",
        "10731",
        "10732",
        "10733",
        "1074",
        "10740",
        "1075",
        "10750",
        "1076",
        "10761",
        "10762",
        "10763",
        "10764",
        "10765",
        "1079",
        "10791",
        "10792",
        "10793",
        "10794",
        "10799",
        "108",
        "1080",
        "10800",
        "11",
        "110",
        "1101",
        "11010",
        "1102",
        "11020",
        "1103",
        "11030",
        "1104",
        "11041",
        "11042",
        "11043",
        "12",
        "120",
        "1200",
        "12001",
        "12009",
        "13",
        "131",
        "1310",
        "13100",
        "139",
        "1391",
        "13910",
        "1392",
        "13921",
        "13922",
        "13929",
        "1393",
        "13930",
        "1394",
        "13940",
        "1399",
        "13990",
        "14",
        "141",
        "1410",
        "14101",
        "14102",
        "14103",
        "14109",
        "142",
        "1420",
        "14200",
        "143",
        "1430",
        "14301",
        "14302",
        "14309",
        "15",
        "151",
        "1511",
        "15110",
        "1512",
        "15121",
        "15122",
        "15129",
        "152",
        "1520",
        "15200",
        "16",
        "161",
        "1610",
        "16101",
        "16102",
        "16109",
        "162",
        "1621",
        "16211",
        "16219",
        "1629",
        "16291",
        "16292",
        "16293",
        "16294",
        "16295",
        "16299",
        "17",
        "170",
        "1701",
        "17010",
        "1702",
        "17021",
        "17022",
        "1709",
        "17091",
        "17092",
        "17093",
        "17094",
        "17099",
        "18",
        "181",
        "1811",
        "18111",
        "18112",
        "18113",
        "18114",
        "18115",
        "18119",
        "1812",
        "18121",
        "18122",
        "18129",
        "182",
        "1820",
        "18200",
        "19",
        "191",
        "1910",
        "19100",
        "192",
        "1920",
        "19201",
        "19202",
        "19209",
        "20",
        "201",
        "2011",
        "20111",
        "20112",
        "20113",
        "20114",
        "20119",
        "2012",
        "20120",
        "2013",
        "20131",
        "20132",
        "20133",
        "202",
        "2021",
        "20210",
        "2022",
        "20221",
        "20222",
        "20229",
        "2023",
        "20231",
        "20232",
        "20233",
        "20234",
        "2024",
        "20241",
        "20242",
        "20249",
        "2029",
        "20291",
        "20292",
        "20293",
        "20294",
        "20295",
        "20296",
        "20297",
        "20298",
        "20299",
        "203",
        "2030",
        "20300",
        "21",
        "210",
        "2101",
        "21011",
        "21012",
        "21013",
        "2102",
        "21021",
        "21022",
        "21023",
        "2103",
        "21030",
        "22",
        "221",
        "2211",
        "22111",
        "22112",
        "22119",
        "2212",
        "22121",
        "22122",
        "2219",
        "22191",
        "22192",
        "22193",
        "22194",
        "22199",
        "222",
        "2221",
        "22211",
        "22212",
        "22213",
        "22214",
        "22215",
        "22216",
        "22217",
        "22218",
        "22219",
        "2222",
        "22220",
        "23",
        "231",
        "2310",
        "23100",
        "239",
        "2391",
        "23911",
        "23912",
        "23919",
        "2393",
        "23931",
        "23939",
        "2394",
        "23940",
        "2395",
        "23951",
        "23959",
        "2396",
        "23960",
        "2399",
        "23991",
        "23992",
        "23993",
        "23994",
        "23995",
        "23999",
        "24",
        "241",
        "2410",
        "24101",
        "24102",
        "24109",
        "242",
        "2420",
        "24201",
        "24202",
        "24209",
        "243",
        "2431",
        "24310",
        "2432",
        "24320",
        "25",
        "251",
        "2511",
        "25111",
        "25112",
        "25113",
        "25114",
        "25119",
        "2512",
        "25120",
        "2513",
        "25131",
        "25139",
        "252",
        "2520",
        "25200",
        "259",
        "2591",
        "25911",
        "25912",
        "25913",
        "25914",
        "25919",
        "2592",
        "25921",
        "25922",
        "25923",
        "25924",
        "25929",
        "2593",
        "25931",
        "25932",
        "25939",
        "2594",
        "25941",
        "25949",
        "2595",
        "25951",
        "25952",
        "25959",
        "2599",
        "25991",
        "25992",
        "25993",
        "25994",
        "25995",
        "25996",
        "25997",
        "25998",
        "25999",
        "26",
        "261",
        "2611",
        "26111",
        "26112",
        "26113",
        "26114",
        "26115",
        "26119",
        "2612",
        "26121",
        "26122",
        "26123",
        "26124",
        "26125",
        "26126",
        "26129",
        "262",
        "2620",
        "26201",
        "26202",
        "26203",
        "26204",
        "26209",
        "263",
        "2630",
        "26301",
        "26302",
        "26303",
        "26304",
        "26309",
        "264",
        "2640",
        "26400",
        "265",
        "2651",
        "26511",
        "26512",
        "26513",
        "2652",
        "26521",
        "26522",
        "26523",
        "26524",
        "266",
        "2660",
        "26601",
        "26602",
        "267",
        "2670",
        "26701",
        "26709",
        "268",
        "2680",
        "26801",
        "26802",
        "27",
        "271",
        "2710",
        "27101",
        "27102",
        "27103",
        "27104",
        "27109",
        "272",
        "2720",
        "27201",
        "27202",
        "273",
        "2732",
        "27321",
        "27322",
        "27323",
        "27324",
        "27329",
        "2733",
        "27330",
        "274",
        "2740",
        "27400",
        "275",
        "2750",
        "27501",
        "27509",
        "279",
        "2790",
        "27901",
        "27902",
        "28",
        "281",
        "2811",
        "28111",
        "28112",
        "2812",
        "28121",
        "28122",
        "28129",
        "2814",
        "28141",
        "28142",
        "2815",
        "28150",
        "2816",
        "28161",
        "28162",
        "28169",
        "2817",
        "28171",
        "28172",
        "2818",
        "28181",
        "28182",
        "28189",
        "2819",
        "28191",
        "28192",
        "28193",
        "28194",
        "28195",
        "28199",
        "282",
        "2821",
        "28210",
        "2822",
        "28221",
        "28222",
        "28223",
        "28224",
        "28225",
        "28229",
        "2824",
        "28241",
        "28242",
        "28243",
        "28249",
        "2825",
        "28251",
        "28252",
        "28253",
        "2826",
        "28260",
        "2827",
        "28271",
        "28272",
        "28273",
        "28274",
        "2829",
        "28291",
        "28292",
        "28293",
        "28294",
        "28299",
        "283",
        "2830",
        "28300",
        "29",
        "291",
        "2910",
        "29100",
        "292",
        "2920",
        "29200",
        "293",
        "2930",
        "29300",
        "30",
        "301",
        "3011",
        "30110",
        "3012",
        "30120",
        "302",
        "3020",
        "30200",
        "303",
        "3030",
        "30301",
        "30302",
        "304",
        "3040",
        "30400",
        "309",
        "3091",
        "30910",
        "3092",
        "30920",
        "3099",
        "30990",
        "31",
        "310",
        "3100",
        "31001",
        "31002",
        "31003",
        "31004",
        "31005",
        "31006",
        "31009",
        "32",
        "321",
        "3211",
        "32111",
        "32112",
        "32119",
        "3212",
        "32120",
        "322",
        "3220",
        "32200",
        "323",
        "3230",
        "32300",
        "324",
        "3240",
        "32400",
        "325",
        "3250",
        "32501",
        "32502",
        "32503",
        "32504",
        "32509",
        "329",
        "3290",
        "32901",
        "32909",
      ],
    },
    {
      name: "ELECTRICITY, GAS, STEAM AND AIR-CONDITIONING SUPPLY",
      value: [
        "35",
        "351",
        "3510",
        "35101",
        "35102",
        "35103",
        "35104",
        "352",
        "3520",
        "35201",
        "35202",
        "35203",
        "353",
        "3530",
        "35300",
      ],
    },
    {
      name: "WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES",
      value: [
        "36",
        "360",
        "3600",
        "36000",
        "37",
        "370",
        "3700",
        "37000",
        "38",
        "381",
        "3810",
        "38100",
        "382",
        "3820",
        "38200",
        "383",
        "3830",
        "38301",
        "38309",
      ],
    },
    {
      name: "CONSTRUCTION",
      value: [
        "41",
        "410",
        "4100",
        "41001",
        "41002",
        "41009",
        "42",
        "421",
        "4210",
        "42101",
        "42102",
        "42103",
        "422",
        "4220",
        "42201",
        "42202",
        "429",
        "4290",
        "42901",
        "42902",
        "42903",
        "42909",
        "43",
        "431",
        "4311",
        "43110",
        "4312",
        "43121",
        "43122",
        "43129",
        "432",
        "4321",
        "43210",
        "4322",
        "43220",
        "4329",
        "43291",
        "43292",
        "43293",
        "43294",
        "43295",
        "43296",
        "43299",
        "433",
        "4330",
        "43301",
        "43302",
        "43303",
        "43304",
        "43305",
        "43306",
        "43307",
        "43309",
        "439",
        "4390",
        "43901",
        "43902",
        "43903",
        "43904",
        "43905",
        "43906",
        "43907",
        "43909",
      ],
    },
    {
      name: "WHOLESALE AND RETAIL TRADE",
      value: [
        "46",
        "461",
        "4610",
        "46100",
        "462",
        "4621",
        "46211",
        "46212",
        "46213",
        "46219",
        "4622",
        "46221",
        "46222",
        "46223",
        "46224",
        "46225",
        "46229",
        "463",
        "4630",
        "46301",
        "46302",
        "46303",
        "46304",
        "46305",
        "46306",
        "46307",
        "46308",
        "46309",
        "464",
        "4641",
        "46411",
        "46412",
        "46413",
        "46414",
        "46415",
        "46416",
        "4642",
        "46421",
        "46422",
        "46423",
        "46424",
        "46429",
        "4643",
        "46431",
        "46432",
        "46433",
        "46434",
        "46435",
        "46436",
        "46437",
        "46439",
        "4644",
        "46441",
        "46442",
        "46443",
        "46444",
        "46449",
        "4645",
        "46451",
        "46452",
        "46453",
        "46459",
        "4646",
        "46461",
        "46462",
        "4647",
        "46471",
        "46472",
        "46473",
        "46474",
        "46479",
        "4649",
        "46491",
        "46492",
        "46499",
        "465",
        "4651",
        "46511",
        "46512",
        "46513",
        "46514",
        "4652",
        "46521",
        "46522",
        "46523",
        "4653",
        "46530",
        "4654",
        "46541",
        "46542",
        "46543",
        "46544",
        "46549",
        "4655",
        "46551",
        "46552",
        "46559",
        "4656",
        "46561",
        "46562",
        "46563",
        "4659",
        "46591",
        "46592",
        "46593",
        "46594",
        "46595",
        "46599",
        "466",
        "4661",
        "46610",
        "4662",
        "46620",
        "4663",
        "46631",
        "46632",
        "46633",
        "46634",
        "46635",
        "46639",
        "4664",
        "46641",
        "46642",
        "46643",
        "46649",
        "4665",
        "46651",
        "46659",
        "4666",
        "46661",
        "46662",
        "469",
        "4690",
        "46900",
        "47",
        "471",
        "4710",
        "47101",
        "47102",
        "47103",
        "47109",
        "472",
        "4721",
        "47211",
        "47212",
        "47213",
        "47214",
        "47219",
        "4722",
        "47220",
        "4723",
        "47230",
        "473",
        "4731",
        "47311",
        "47312",
        "47313",
        "47314",
        "4732",
        "47320",
        "474",
        "4741",
        "47411",
        "47412",
        "47413",
        "47414",
        "47415",
        "4742",
        "47420",
        "475",
        "4751",
        "47510",
        "4752",
        "47521",
        "47522",
        "47529",
        "4753",
        "47531",
        "47532",
        "47533",
        "47534",
        "47535",
        "47536",
        "47537",
        "47539",
        "476",
        "4761",
        "47610",
        "4762",
        "47620",
        "4763",
        "47630",
        "4764",
        "47640",
        "477",
        "4771",
        "47711",
        "47712",
        "47713",
        "47714",
        "47715",
        "47719",
        "4772",
        "47721",
        "47722",
        "47723",
        "47729",
        "4773",
        "47731",
        "47732",
        "47733",
        "47739",
        "4774",
        "47741",
        "47742",
        "47743",
        "47744",
        "47745",
        "47746",
        "47749",
        "4775",
        "47751",
        "47752",
        "47759",
        "4776",
        "47761",
        "47762",
        "47769",
        "4777",
        "47770",
        "478",
        "4780",
        "47801",
        "47802",
      ],
    },
    {
      name: "TRANSPORTATION AND STORAGE",
      value: [
        "49",
        "491",
        "4910",
        "49101",
        "49102",
        "492",
        "4921",
        "49211",
        "49212",
        "49213",
        "49214",
        "49215",
        "4922",
        "49220",
        "4923",
        "49231",
        "49232",
        "49233",
        "493",
        "4930",
        "49300",
        "50",
        "500",
        "5001",
        "50011",
        "50012",
        "50013",
        "50014",
        "5002",
        "50021",
        "50022",
        "50023",
        "51",
        "510",
        "5100",
        "51001",
        "51002",
        "51003",
        "51004",
        "51005",
        "52",
        "521",
        "5210",
        "52101",
        "52102",
        "52103",
        "52109",
        "522",
        "5221",
        "52211",
        "52212",
        "52219",
        "5222",
        "52221",
        "52222",
        "52223",
        "52224",
        "52225",
        "52226",
        "52227",
        "52229",
        "5223",
        "52231",
        "52232",
        "52233",
        "52234",
        "52239",
        "5224",
        "52241",
        "52242",
        "52243",
        "5225",
        "52251",
        "52252",
        "5229",
        "52291",
        "52292",
        "53",
        "531",
        "5310",
        "53100",
        "532",
        "5320",
        "53200",
      ],
    },
    {
      name: "ACCOMMODATION AND FOOD SERVICE ACTIVITIES",
      value: [
        "55",
        "551",
        "5510",
        "55101",
        "55102",
        "55103",
        "559",
        "5590",
        "55900",
        "56",
        "561",
        "5611",
        "56111",
        "56112",
        "5612",
        "56121",
        "56122",
        "56123",
        "5613",
        "56130",
        "5614",
        "56140",
        "562",
        "5620",
        "56200",
      ],
    },
    {
      name: "INFORMATION AND COMMUNICATIONS",
      value: [
        "58",
        "581",
        "5811",
        "58110",
        "5812",
        "58120",
        "5813",
        "58131",
        "58132",
        "5819",
        "58190",
        "582",
        "5820",
        "58201",
        "58202",
        "59",
        "591",
        "5911",
        "59111",
        "59112",
        "59113",
        "59114",
        "59119",
        "5912",
        "59120",
        "5913",
        "59131",
        "59132",
        "59139",
        "5914",
        "59140",
        "592",
        "5920",
        "59201",
        "59202",
        "60",
        "601",
        "6010",
        "60100",
        "602",
        "6020",
        "60200",
        "61",
        "610",
        "6101",
        "61011",
        "61012",
        "61013",
        "6109",
        "61091",
        "61092",
        "61099",
        "62",
        "620",
        "6201",
        "62011",
        "62012",
        "62013",
        "6202",
        "62021",
        "62022",
        "62023",
        "6209",
        "62090",
        "63",
        "631",
        "6311",
        "63111",
        "63112",
        "63119",
        "6312",
        "63120",
        "632",
        "6320",
        "63201",
        "63202",
        "63203",
        "63204",
        "63205",
        "63209",
        "639",
        "6390",
        "63901",
        "63909",
      ],
    },
    {
      name: "FINANCIAL AND INSURANCE ACTIVITIES",
      value: [
        "64",
        "641",
        "6411",
        "64110",
        "6412",
        "64120",
        "6413",
        "64130",
        "6414",
        "64140",
        "6415",
        "64150",
        "6419",
        "64190",
        "642",
        "6420",
        "64201",
        "64202",
        "643",
        "6430",
        "64300",
        "649",
        "6491",
        "64910",
        "6492",
        "64921",
        "64922",
        "64923",
        "64924",
        "64925",
        "64929",
        "6499",
        "64991",
        "64992",
        "64993",
        "64994",
        "64999",
        "65",
        "651",
        "6511",
        "65110",
        "6512",
        "65121",
        "65122",
        "65123",
        "65124",
        "652",
        "6520",
        "65201",
        "65202",
        "653",
        "6530",
        "65301",
        "65302",
        "66",
        "661",
        "6611",
        "66110",
        "6612",
        "66121",
        "66122",
        "66123",
        "66124",
        "66125",
        "66126",
        "66129",
        "6619",
        "66191",
        "66192",
        "66193",
        "66194",
        "66195",
        "66199",
        "662",
        "6621",
        "66210",
        "6622",
        "66221",
        "66222",
        "6629",
        "66291",
        "66292",
        "66299",
        "663",
        "6630",
        "66301",
        "66302",
        "66303",
        "66304",
        "66305",
        "66306",
        "66307",
        "66309",
      ],
    },
    {
      name: "REAL ESTATE ACTIVITIES",
      value: [
        "68",
        "681",
        "6810",
        "68101",
        "68102",
        "68103",
        "68104",
        "68105",
        "68106",
        "682",
        "6820",
        "68201",
        "68202",
        "68203",
        "68209",
      ],
    },
    {
      name: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES",
      value: [
        "69",
        "691",
        "6910",
        "69101",
        "69102",
        "692",
        "6920",
        "69201",
        "69202",
        "70",
        "701",
        "7010",
        "70101",
        "70102",
        "702",
        "7020",
        "70201",
        "70202",
        "70203",
        "70204",
        "70205",
        "70206",
        "70209",
        "71",
        "711",
        "7111",
        "71111",
        "71112",
        "71113",
        "71114",
        "71119",
        "7112",
        "71121",
        "71122",
        "71123",
        "71124",
        "71125",
        "71126",
        "71129",
        "712",
        "7120",
        "71200",
        "72",
        "721",
        "7210",
        "72101",
        "72102",
        "72103",
        "72104",
        "72105",
        "72106",
        "72109",
        "722",
        "7220",
        "72200",
        "73",
        "731",
        "7310",
        "73100",
        "732",
        "7320",
        "73200",
        "74",
        "741",
        "7411",
        "74111",
        "74112",
        "74113",
        "74119",
        "7419",
        "74191",
        "74192",
        "74193",
        "74194",
        "74199",
        "742",
        "7420",
        "74200",
        "749",
        "7490",
        "74901",
        "74902",
        "74903",
        "74904",
        "74905",
        "74909",
        "75",
        "750",
        "7500",
        "75000",
      ],
    },
    {
      name: "ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES",
      value: [
        "77",
        "771",
        "7710",
        "77101",
        "77102",
        "77109",
        "772",
        "7721",
        "77210",
        "7722",
        "77220",
        "7729",
        "77291",
        "77292",
        "77299",
        "773",
        "7731",
        "77310",
        "7732",
        "77320",
        "7733",
        "77330",
        "7734",
        "77341",
        "77342",
        "7739",
        "77391",
        "77392",
        "77399",
        "774",
        "7740",
        "77400",
        "78",
        "781",
        "7810",
        "78101",
        "78102",
        "78103",
        "78104",
        "783",
        "7830",
        "78300",
        "79",
        "791",
        "7910",
        "79101",
        "79102",
        "792",
        "7920",
        "79200",
        "80",
        "801",
        "8010",
        "80100",
        "802",
        "8020",
        "80200",
        "81",
        "812",
        "8121",
        "81211",
        "81212",
        "8129",
        "81291",
        "81292",
        "81299",
        "813",
        "8130",
        "81300",
        "82",
        "821",
        "8211",
        "82110",
        "8219",
        "82191",
        "82192",
        "82199",
        "822",
        "8220",
        "82200",
        "823",
        "8230",
        "82301",
        "82302",
        "82303",
        "82304",
        "829",
        "8291",
        "82911",
        "82912",
        "8292",
        "82920",
        "8299",
        "82991",
        "82999",
      ],
    },
    {
      name: "PUBLIC ADMINISTRATION AND DEFENCE",
      value: [
        "84",
        "841",
        "8411",
        "84110",
        "8412",
        "84120",
        "842",
        "8421",
        "84210",
        "8422",
        "84221",
        "84229",
        "8423",
        "84231",
        "84232",
        "84233",
        "84234",
        "84235",
        "84239",
      ],
    },
    {
      name: "EDUCATION",
      value: [
        "85",
        "851",
        "8510",
        "85101",
        "85102",
        "852",
        "8521",
        "85211",
        "85212",
        "85213",
        "8522",
        "85220",
        "8523",
        "85230",
        "8524",
        "85240",
        "8525",
        "85250",
        "853",
        "8530",
        "85301",
        "85302",
        "85303",
        "85304",
        "854",
        "8540",
        "85401",
        "85407",
        "85408",
        "85402",
        "85409",
        "85406",
        "85403",
        "85404",
        "85405",
        "855",
        "8550",
        "85501",
        "85502",
        "85509",
      ],
    },
    {
      name: "HEALTH AND SOCIAL SERVICES",
      value: [
        "86",
        "861",
        "8610",
        "86101",
        "86102",
        "86109",
        "862",
        "8620",
        "86201",
        "86202",
        "86203",
        "86204",
        "869",
        "8690",
        "86901",
        "86902",
        "86903",
        "86904",
        "86905",
        "86909",
        "87",
        "870",
        "8701",
        "87010",
        "8702",
        "87021",
        "87022",
        "87029",
        "88",
        "881",
        "8810",
        "88101",
        "88102",
        "889",
        "8891",
        "88911",
        "88912",
        "88913",
        "88919",
        "8892",
        "88920",
        "8899",
        "88991",
        "88992",
        "88999",
      ],
    },
    {
      name: "ARTS, ENTERTAINMENT AND RECREATION",
      value: [
        "90",
        "900",
        "9000",
        "90001",
        "90002",
        "90003",
        "90009",
        "91",
        "910",
        "9101",
        "91010",
        "9102",
        "91021",
        "91029",
        "9103",
        "91030",
        "92",
        "920",
        "9200",
        "92001",
        "92002",
        "93",
        "931",
        "9311",
        "93111",
        "93119",
        "9312",
        "93120",
        "932",
        "9320",
        "93201",
        "93202",
        "93203",
        "93204",
        "93209",
      ],
    },
    {
      name: "OTHER SERVICE ACTIVITIES",
      value: [
        "94",
        "941",
        "9411",
        "94110",
        "9412",
        "94120",
        "942",
        "9420",
        "94200",
        "949",
        "9491",
        "94911",
        "94912",
        "94913",
        "94914",
        "94919",
        "9492",
        "94920",
        "9499",
        "94991",
        "94992",
        "94993",
        "94994",
        "94995",
        "94999",
        "95",
        "951",
        "9511",
        "95110",
        "9512",
        "95120",
        "952",
        "9521",
        "95210",
        "9522",
        "95220",
        "9523",
        "95230",
        "9524",
        "95240",
        "9529",
        "95291",
        "95292",
        "95293",
        "95299",
        "953",
        "9530",
        "95301",
        "95302",
        "95303",
        "96",
        "960",
        "9601",
        "96011",
        "96012",
        "9602",
        "96021",
        "96022",
        "96023",
        "96024",
        "96029",
        "9603",
        "96030",
        "9604",
        "96040",
        "9609",
        "96091",
        "96092",
        "96093",
        "96094",
        "96095",
        "96099",
      ],
    },
    {
      name: "ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS OF DOMESTIC PERSONNEL",
      value: ["97", "970", "9700", "97001", "97002"],
    },
    {
      name: "ACTIVITIES OF EXTRA-TERRITORIAL ORGANISATIONS AND BODIES",
      value: ["99", "990", "9901", "99010", "9902", "99020", "9909", "99090"],
    },
  ];

  const [allCBSData, setAllCBSData] = useState([]);
  const findGroupByCode = (code) => {
    const codeStr = String(code); // Convert the input code to a string
    for (const group of SsicCodes) {
      for (const value of group.value) {
        if (String(value) === codeStr) {
          // Compare as strings
          return group.name;
        }
      }
    }
    return null;
  };

  const handleChange = (event, index) => {
    const newDescriptions = [...documentDescriptions];
    newDescriptions[index] = event.target.value;
    setDocumentDescriptions(newDescriptions);
  };

  const addMore = () => {
    setDocumentDescriptions([...documentDescriptions, ""]);
  };

  const remove = (indexToRemove) => {
    setDocumentDescriptions(
      documentDescriptions.filter((_, index) => index !== indexToRemove)
    );
  };

  const [extraRequestInformation, setExtraRequestInformation] = useState({});

  const showModalRequestDocument = (
    loan_id,
    express,
    user_id,
    principal_name,
    created_at
  ) => {
    setIsModalOpen(true);
    setLoanId(loan_id);
    setExpress(express);
    setUserId(user_id);
    setPrincipalName(principal_name);
    setExtraRequestInformation({ created_at: created_at });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRequestMoreDocument = async (
    loan_id,
    express,
    user_id,
    principal_name
  ) => {
    console.log("loanId value in handleRequestMoreDocument", loan_id); // Debug statement
    console.log("user_id value in handleRequestMoreDocument", user_id); // Debug statement
    console.log(
      "principal_name value in handleRequestMoreDocument",
      principal_name
    ); // Debug statement

    // rest of the code
    try {
      let path = "";

      let add_documents = documentDescriptions.map((description, index) => ({
        document_name: description,
        document: "",
      }));

      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: 0,
        processing_fee: parseFloat(offer_processing_fee / 100),
        add_document: JSON.stringify(add_documents),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message
        try {
          let item = {
            user_id: user_id,
            channel: "system",
            subject: "Lender offer",
            message: "Lender had make an  offer",
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                user_id: user_id,
                user_name: principal_name,
                user_account_type: "borrower",
                description: `You've received a request for more document on your loan request ${loan_id} that you've made on ${formatDate(
                  extraRequestInformation.created_at
                    ? extraRequestInformation.created_at
                    : null
                )}`,
              }),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLogForSelf`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                description: `You've requested for more document on loan ${loan_id} from ${principal_name}.`,
              }),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const handleNotInterestedModal = (event) => {
    console.log("Event: ", event);
    const value = event.target.value;
    console.log("Value: ", value);
    setSelectedValue(value);
    setShowOtherInput(value === "others");
  };

  const [showOtherInput, setShowOtherInput] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleNotInterested = async (loan_id, selectedValue) => {
    console.log("Not interested");
    const updatedNotInterestedIds = [...notInterestedIds, loan_id];

    // Filter out the loan with the provided loan_id
    const selectedLoan = data.filter((loan) => loan.loan_id === loan_id);

    // Filter out the other loans
    const remainingLoans = data.filter((loan) => loan.loan_id !== loan_id);

    // Concatenate the arrays to move the selected loan to the end
    const updatedLoans = [...remainingLoans, ...selectedLoan];

    // Update the data array and not interested ids
    setData(updatedLoans);
    setNotInterestedIds(updatedNotInterestedIds);

    // Store the updated data in the cookies
    Cookies.set("notInterestedIds", JSON.stringify(updatedNotInterestedIds));
    Cookies.set("loans", JSON.stringify(updatedLoans));

    console.log("loans dari cookie:", JSON.parse(Cookies.get("loans")));

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/notInterestedLoan?loan_id=${loan_id}&reject_reason=${selectedValue}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const data = await response.json();

      if (response.status === 200) {
        message.success(data.message);
      } else if (response.status === 400) {
        message.error(data.message);
      }
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }

    try {
      let item = {
        user_id: "A00001",
        channel: "system",
        subject: `Not interested Loan`,
        message: `Lender had select an option for not interested with loan ${loan_id}`,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/notification/sendNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      console.log("Offer created:", data);
    } catch (error) {
      console.error("Error creating offer:", error);
    }

    // Set the not interested loan id
    setNotInterestedLoanId(loan_id);

    // Fetch data again
    fetchData();
  };

  const handleLoanPeriodFilterChange = (value) => {
    setLoanPeriodFilter(value);
  };

  const handleCreditScoreMinChange = (event) => {
    setCreditScoreMin(event.target.value);
  };

  const handleCreditScoreMaxChange = (event) => {
    setCreditScoreMax(event.target.value);
  };

  const handleLoanPeriodMinChange = (event) => {
    setLoanPeriodMin(event.target.value);
  };

  const handleLoanPeriodMaxChange = (event) => {
    setLoanPeriodMax(event.target.value);
  };

  const handleCheckboxChange = (isChecked, category) => {
    if (isChecked) {
      setLoanCategoryFilters([...loanCategoryFilters, category]);
    } else {
      setLoanCategoryFilters(loanCategoryFilters.filter((c) => c !== category));
    }
  };

  //Integration for the onChange Search Filter

  // Use the useEffect hook to filter the data based on the query parameters

  const updateFilter = async (data) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/filter/createFilter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify({ filter_config: data }),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFilter = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/filter/getFilter",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const jsonData = JSON.parse(json.data);
        console.log(jsonData);
        if (jsonData.minLoanAmount !== null) {
          setMinLoanAmount(jsonData.minLoanAmount);
        }
        if (jsonData.maxLoanAmount !== null) {
          setMaxLoanAmount(jsonData.maxLoanAmount);
        }
        if (jsonData.LoanPeriodMin !== null) {
          setLoanPeriodMin(jsonData.LoanPeriodMin);
        }
        if (jsonData.LoanPeriodMax !== null) {
          setLoanPeriodMax(jsonData.LoanPeriodMax);
        }
        if (jsonData.LoanInterestMin !== null) {
          setLoanInterestMin(jsonData.LoanInterestMin);
        }
        if (jsonData.LoanInterestMax !== null) {
          setLoanInterestMax(jsonData.LoanInterestMax);
        }
        if (jsonData.selectedCheckbox !== null) {
          setSelectedCheckbox(jsonData.selectedCheckbox);
        }
        if (jsonData.selectedCheckbox1 !== null) {
          setSelectedCheckbox1(jsonData.selectedCheckbox1);
        }
        if (jsonData.selectedCheckbox2 !== null) {
          setSelectedCheckbox2(jsonData.selectedCheckbox2);
        }
        if (jsonData.MinYearOfIncorp !== null) {
          setMinYearOfIncorp(jsonData.MinYearOfIncorp);
        }
        if (jsonData.selectedIndustry !== null) {
          setSelectedIndustry(jsonData.selectedIndustry);
        }
        if (jsonData.selectedCreditRating !== null) {
          setSelectedCreditRating(jsonData.selectedCreditRating);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateYearsFromNow = (dateString) => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    const yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();

    return yearsDifference;
  };

  const findGroupByName = (name) => {
    return SsicCodes.find((group) => group.name === name);
  };

  const findRatingIndex = (rating) => {
    return possiblecreditrating.findIndex((r) => r.value === rating);
  };

  useEffect(() => {
    let filtered = data;

    if (
      (data && minLoanAmount !== "") ||
      maxLoanAmount !== "" ||
      LoanInterestMin !== "" ||
      LoanInterestMax !== "" ||
      LoanPeriodMin !== "" ||
      LoanPeriodMax !== "" ||
      MinYearOfIncorp !== "" ||
      selectedIndustry !== ""
    ) {
      // console.log("filter data ranned");
      const filterConfig = {
        minLoanAmount: minLoanAmount,
        maxLoanAmount: maxLoanAmount,
        LoanPeriodMin: LoanPeriodMin,
        LoanPeriodMax: LoanPeriodMax,
        LoanInterestMin: LoanInterestMin,
        LoanInterestMax: LoanInterestMax,
        selectedCheckbox: selectedCheckbox,
        selectedCheckbox1: selectedCheckbox1,
        selectedCheckbox2: selectedCheckbox2,
        MinYearOfIncorp: MinYearOfIncorp,
        selectedIndustry: selectedIndustry,
        selectedCreditRating: selectedCreditRating,
      };
      updateFilter(filterConfig);
    }
    console.log(filtered);
    // Filter the data based on the query parameters
    if (minLoanAmount) {
      filtered = filtered.filter(
        (item) => item.request_amount >= minLoanAmount
      );
    }
    if (maxLoanAmount) {
      filtered = filtered.filter(
        (item) => item.request_amount <= maxLoanAmount
      );
    }
    if (LoanPeriodMin) {
      filtered = filtered.filter(
        (item) => item.request_period >= LoanPeriodMin
      );
    }
    if (LoanPeriodMax) {
      filtered = filtered.filter(
        (item) => item.request_period <= LoanPeriodMax
      );
    }
    if (LoanInterestMin) {
      filtered = filtered.filter(
        (item) => item.request_interest_rate >= LoanInterestMin / 100
      );
    }
    if (LoanInterestMax) {
      filtered = filtered.filter(
        (item) => item.request_interest_rate <= LoanInterestMax / 100
      );
    }
    if (MinYearOfIncorp) {
      console.log(MinYearOfIncorp);
      filtered = filtered.filter(
        (item) =>
          calculateYearsFromNow(
            JSON.parse(item?.singpass_data)?.entity?.["basic-profile"]?.[
              "registration-date"
            ]?.value
          ) >= MinYearOfIncorp
      );
    }

    if (selectedIndustry) {
      const selectedGroup = findGroupByName(selectedIndustry);
      console.log(selectedGroup);
      if (selectedGroup) {
        filtered = filtered.filter((item) => {
          const ssicCode = JSON.parse(item.singpass_data).entity[
            "basic-profile"
          ]["primary-activity"].code;
          console.log(selectedGroup.value.includes(ssicCode));
          return selectedGroup.value.includes(ssicCode);
        });
      }
    }

    if (selectedCreditRating) {
      const selectedIndex = findRatingIndex(selectedCreditRating);
      console.log(selectedIndex);
      filtered = filtered.filter((item) => {
        const creditReportData = item.credit_report_data || {};
        const creditRating = creditReportData.RiskGrade
          ? creditReportData.RiskGrade.replace(/\s+/g, "")
          : null;
        if (creditRating === null) {
          return false; // Skip items with null or undefined RiskGrade
        }
        const itemRatingIndex = findRatingIndex(creditRating);
        console.log(itemRatingIndex);
        if (itemRatingIndex > -1 && selectedIndex > -1) {
          return itemRatingIndex <= selectedIndex;
        }
        return false; // Skip items where itemRatingIndex or selectedIndex is invalid
      });
    }

    if (loanCategoryFilters.length > 0) {
      filtered = filtered.filter((item) =>
        loanCategoryFilters.includes(item.category)
      );
    }
    // Set filteredData only if it's not empty
    if (filtered.length > 0) {
      setFilteredData(filtered);
    } else if (filtered !== data) {
      setFilteredData([]);
      // console.log("no");
      setempty("1");
    }
  }, [
    selectedCreditRating,
    selectedIndustry,
    MinYearOfIncorp,
    minLoanAmount,
    maxLoanAmount,
    LoanInterestMin,
    LoanInterestMax,
    LoanPeriodMin,
    LoanPeriodMax,
    loanCategoryFilters,
  ]);

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    const storedDate = Cookies.get("countdownTargetDate");
    if (storedDate) {
      setTargetDate(Number(storedDate));
    } else {
      const newTargetDate = new Date().getTime() + 1000 * 60 * 60 * 24; // 1 day from now
      Cookies.set("countdownTargetDate", newTargetDate);
      setTargetDate(newTargetDate);
    }
  }, []);

  const timerStyle = {
    color: "grey",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: "12px",
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let timeLeft = "";

    if (completed) {
      return <span style={timerStyle}>Time's up!</span>;
    } else {
      if (days > 0) {
        timeLeft = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timeLeft = `${hours}h ${minutes}m ${seconds}s`;
      } else {
        timeLeft = `${minutes}m ${seconds}s`;
      }

      return <span style={timerStyle}>{timeLeft}</span>;
    }
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleGridPath = () => {
    // history.push('../loan-request-grid')
  };

  const handleListPath = () => {
    history.push("../loan-request");
  };

  const handleInterestRateChange = (e) => {
    let value = e.target.value;
    setInterestRate(value);
  };

  const handleLoanPeriodChange = (e) => {
    setSelectedLoanPeriod(e.target.value);
  };

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.0 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.0 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
          0.0;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 *
            0.05 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = offer_monthly_installment * 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setLoanDetails(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [modalStates, setModalStates] = useState({});
  const [modalStates2, setModalStates2] = useState({});
  const [modalStates3, setModalStates3] = useState({});
  const [modalStates4, setModalStates4] = useState({});

  useEffect(() => {
    const initialStates = {};
    const initialStates2 = {};
    const initialStates3 = {};
    const initialStates4 = {};

    data.forEach((item) => {
      initialStates[item.loan_id] = false;
      initialStates2[item.loan_id] = false;
      initialStates3[item.loan_id] = false;
      initialStates4[item.loan_id] = false;
    });
    setModalStates(initialStates);
    setModalStates2(initialStates2);
    setModalStates3(initialStates3);
    setModalStates4(initialStates4);
  }, [data]);

  const [floatingLoanAmount, setFloatingLoanAmount] = useState({
    loanId: "",
    totalLoanedAmount: "",
  });
  const [warningLenderModal, setWarningLenderModal] = useState(false);

  const openMakeAnOfferModal = () => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [floatingLoanAmount.loanId]: true,
    }));
  };

  const showModal = (loanId, totalLoanedAmount) => {
    if (totalLoanedAmount !== 0) {
      console.log();
      setFloatingLoanAmount({
        loanId: loanId,
        totalLoanedAmount: totalLoanedAmount,
      });
      setWarningLenderModal(true);
    } else {
      setModalStates((prevStates) => ({
        ...prevStates,
        [loanId]: true,
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showModal2 = (loanId) => {
    setModalStates2((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const showModal3 = (loanId) => {
    setModalStates3((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const showModal4 = (loanId) => {
    setModalStates4((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  //Condition to allow only one checkbox selected based on checkbox ID
  const handleLoanAmountCheckbox = (id, min, max) => {
    if (selectedCheckbox === id) {
      setSelectedCheckbox(null);
      setMinLoanAmount("");
      setMaxLoanAmount("");
    } else {
      setSelectedCheckbox(id);
      setMinLoanAmount(min);
      setMaxLoanAmount(max);
    }
  };
  const handleLoanPeriodCheckbox = (id, min, max) => {
    if (selectedCheckbox1 === id) {
      setSelectedCheckbox1(null);
      setLoanPeriodMin("");
      setLoanPeriodMax("");
    } else {
      setSelectedCheckbox1(id);
      setLoanPeriodMin(min);
      setLoanPeriodMax(max);
    }
  };
  const handleLoanInterestCheckbox = (id, min, max) => {
    if (selectedCheckbox2 === id) {
      setSelectedCheckbox2(null);
      setLoanInterestMin("");
      setLoanInterestMax("");
    } else {
      setSelectedCheckbox2(id);
      setLoanInterestMin(min);
      setLoanInterestMax(max);
    }
  };

  const handleDownloadBankStatement = async (loan_id, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewBankStatement?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.data) {
        window.open(data.data, "_blank"); // open the url in a new tab
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCreditReport = async (userId, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/viewCreditReport?user_id=${userId}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // the filename you want
      a.download = "CreditReport.pdf"; // You can dynamically set the filename based on content-disposition header or any other logic

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMakeOffer = async (
    loan_id,
    express,
    user_id,
    principal_name,
    created_at
  ) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    console.log("principal_name value in handleMakeOffer", principal_name); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: 0,
        processing_fee: parseFloat(offer_processing_fee / 100),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message

        try {
          let item = {
            user_id: user_id,
            channel: "system",
            subject: "Lender offer",
            message: "Lender had make an  offer",
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                user_id: user_id,
                user_name: principal_name,
                user_account_type: "borrower",
                description: `You've received a loan offer on your loan request ${loan_id} that you've made on ${formatDate(
                  created_at ? created_at : null
                )}`,
              }),
            }
          );
          const json = await response.json();
          console.log(json);
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLogForSelf`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                description: `You've made a loan offer on loan ${loan_id} from ${principal_name}.`,
              }),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };
  const buttonStyleExpress = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background: "#FED065",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "7px",
  };

  const buttonStyleNotInterested = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "grey",
    textAlign: "center",
    background: "transparent",
    border: "1px solid grey",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const calculateCountdown = (expiredDate) => {
    const now = new Date().getTime();
    const targetDate = new Date(expiredDate).getTime();
    const timeDiff = targetDate - now;

    if (timeDiff <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d: ${hours}h: ${minutes}m`;
  };

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    index,
    credit_score,
    loan_id,
    userId,
    loanOfferCount,
    countDown,
    createdAt,
    reject_reason,
    onClickModal,
    item,
  }) => {
    const shouldAddMarginTop = index >= 4;
    const overlayStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      borderRadius: "10px",
      zIndex: 1,
    };
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "calc(50% - 10px)",
            textAlign: "center",
            border:
              subtitle === "Express Applicant"
                ? "1px solid rgb(242, 193, 80)"
                : "none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: screenWidth > 1200 ? "40vh" : "auto",
            padding: "15px",
            position: "relative",
          }}
        >
          {loan_id === notInterestedLoanId && <div style={overlayStyle}></div>}

          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              display: subtitle === "Express Applicant" ? "block" : "none",
            }}
          >
            <StarIcon
              style={{ color: "rgba(242, 193, 80)", fontSize: "25px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: 20,
                fontWeight: 600,
                position: "relative",
              }}
              onClick={() => {
                console.log(
                  JSON.parse(item.singpass_data)?.person?.name?.value
                );
                setBadgeVerified(item.badge_verified);
                const dataToPush = [
                  {
                    name: JSON.parse(item.singpass_data)?.person?.name?.value,
                    cbs_data: item.credit_report_data,
                  },
                ];
                for (const dat of item.loan_authorizations) {
                  dataToPush.push({
                    name: dat.director_name,
                    cbs_data: dat.credit_report_data,
                  });
                }
                console.log(dataToPush);
                setAllCBSData(dataToPush);
                singpassDetails(
                  item.user_id,
                  item.authorization_required,
                  item.loan_id,
                  item.keyman,
                  item.singpass_data,
                  item.loan_authorizations
                );
              }}
            >
              {title ? getInitials(title) : "SL"}
              {item.badge_verified ? (
                <div
                  className="tooltip-container"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "18px",
                    height: "18px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(14, 90, 165)",
                  }}
                >
                  <LocalPoliceIcon
                    className="tooltip-target"
                    style={{ color: "white", fontSize: "14px" }}
                  />
                  <span className="tooltip-text">
                    Verified borrowers have undergone SmartLend's authentication
                    process, demonstrating legitimate business operations. This
                    includes providing evidence such as current tenancy
                    agreements, business premises signage, or recent invoices
                    matching their bank statements
                  </span>
                </div>
              ) : null}
            </div>
            <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
              <h3
                style={{
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "#142C44CC",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {title.length > 20 ? title.slice(0, 20) + "..." : title}
              </h3>
              <tooltip
                placement="topRight"
                title="This application qualifies for our 24-hour service 
guarantee based on our credit scoring. Be the first to fund them within 24 hours!"
              >
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {item.badge_verified ? subtitle : null}
                </p>
              </tooltip>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
            }}
          >
            {/* <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "10px 0px -5px 0px",
              }}
            >
              {label}
            </p>
            <Progress
              percent={credit_score}
              showInfo={false}
              strokeColor={
                subtitle === "Express Applicant"
                  ? "rgba(242, 193, 80)"
                  : "#1557a9"
              }
            />
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "-5px 0px 10px 0px",
              }}
            >
              {description}
            </p> */}
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
                marginTop: "20px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Expecting Interest Rate Per Month
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {item.request_interest_rate === 2
                ? "1.1% - 2%"
                : item.request_interest_rate === 3
                ? "2.1% - 3%"
                : "3.1% - 5%"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Expecting Processing Fee
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {item.admin_fee === 3
                ? "1% - 3%"
                : item.admin_fee === 5
                ? "3.1% - 5%"
                : "5.1% - 7%"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Validity
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {calculateCountdown(countDown)}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Offers
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {loanOfferCount}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Applied on
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 20px 0px",
              }}
            >
              {formatDate(createdAt)}
            </p>
          </div>
          {/* <div style={{ marginBottom: "10px" }}>
            <CountdownWithHover targetDate={targetDate} renderer={renderer} />
          </div> */}
          <>
            <div key={loan_id} style={{}}>
              <button
                className="all_button"
                onClick={(event) => {
                  event.preventDefault();
                  window.open(item.bank_statement, "_blank");
                }}
                style={{
                  background: "transparent",
                  color: "grey",
                  width: "100%",
                  border: "2px solid lightgrey",
                  borderRadius: "15px",
                  padding: "4px 20px",
                  fontSize: "12px",
                  margin: "0px",
                }}
              >
                Download Company Financial Information
              </button>
              {/* <button
                className="all_button"
                onClick={(event) => handleDownloadCreditReport(userId, event)}
                style={{
                  background: "transparent",
                  color: "grey",
                  width: "100%",
                  border: "2px solid lightgrey",
                  borderRadius: "15px",
                  padding: "4px 20px",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                Download Financial Report
              </button> */}
              <button
                className="all_button"
                onClick={(event) => {
                  event.preventDefault();
                  const url = `/l-messages?principelname=${item.principal_name}&userid=${item.user_id}&requestamount=${item.request_amount}`;
                  history.push(url);
                }}
                style={{
                  background: "transparent",
                  color: "grey",
                  width: "100%",
                  border: "2px solid lightgrey",
                  borderRadius: "15px",
                  padding: "4px 20px",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                Message borrower
              </button>
              <button
                className="all_button"
                onClick={(event) => {
                  event.preventDefault();
                  const onGoingLoan = item.loan_offers.filter(
                    (offer) => offer.status === "accepted"
                  );
                  const totalLoanedAmount = item.loan_offers
                    .filter((offer) => offer.status === "accepted")
                    .reduce((sum, offer) => sum + offer.offer_amount, 0);
                  showModal(loan_id, totalLoanedAmount);
                }}
                // onClick={(event) => showModal(loan_id, event)}
                style={{
                  background:
                    subtitle === "Express Applicant"
                      ? "rgba(242, 193, 80)"
                      : "#1557a9",
                  color: "white",
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "6px 0px",
                  fontSize: "12px",
                  margin: "10px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Make an offer{" "}
                <HourglassTopIcon
                  className="rotating_hourglass"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    marginLeft: "5px",
                  }}
                />
              </button>
              {(reject_reason === null || reject_reason === "") && (
                <button
                  className="all_button"
                  style={{
                    background: "transparent",
                    color: "grey",
                    width: "100%",
                    border: "2px solid lightgrey",
                    borderRadius: "50px",
                    padding: "4px 0px",
                    fontSize: "12px",
                  }}
                  onClick={(event) => showModal2(loan_id, event)}
                >
                  Not Interested?
                </button>
              )}
            </div>
          </>
        </div>
      </>
    );
  };

  // Move the fetching functionality into a separate function
  const fetchData = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/viewLoanRequest",
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const fetchedData = await response.json();
      console.log(fetchedData);
      const parsedData = fetchedData["Requested Loan"].map((item) => {
        // Parse credit_report_data in the main object
        const updatedItem = {
          ...item,
          credit_report_data: JSON.parse(item.credit_report_data),
        };

        // Filter and parse credit_report_data in loan_authorizations array
        const updatedLoanAuthorizations = item.loan_authorizations
          .filter((item) => {
            const createdAtDate = new Date(item.created_at);
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

            // Return only if the created_at date is within the last 30 days
            return createdAtDate >= thirtyDaysAgo;
          })
          .map((authorization) => ({
            ...authorization,
            credit_report_data: JSON.parse(authorization.credit_report_data),
          }));

        // Update the item with the updated loan_authorizations array
        updatedItem.loan_authorizations = updatedLoanAuthorizations;

        return updatedItem;
      });

      console.log(parsedData);

      if (parsedData && parsedData.length > 0) {
        const loanDataFromCookies = Cookies.get("loans")
          ? JSON.parse(Cookies.get("loans"))
          : parsedData;
        console.log("loanDataFromCookies:", loanDataFromCookies);
        setData(loanDataFromCookies);

        const targetDate = new Date(
          loanDataFromCookies[0].expired_date
        ).getTime();
        setTargetDate(targetDate);
      }

      getFilter();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dppicture, setdppicture] = useState([]);

  const fillAllLenderPpicture = async (index, lenderId) => {
    // try {
    //   const response = await fetch(
    //     `https://www.smart-lend.com/api/user/getLenderPicture?lender_id=${lenderId}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Cookies.get("token")}`,
    //       },
    //     }
    //   );
    //   const blob = await response.blob();
    //   const imageUrl = URL.createObjectURL(blob);
    //   setdppicture((prevDppicture) => {
    //     const updatedDppicture = [...prevDppicture]; // Create a copy of the array
    //     updatedDppicture[index] = { profilepicture: imageUrl }; // Update the specific index
    //     console.log(updatedDppicture);
    //     return updatedDppicture;
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    if (filteredData) {
      filteredData
        .sort((a, b) => {
          if (
            notInterestedIds.includes(a.loan_id) &&
            !notInterestedIds.includes(b.loan_id)
          ) {
            return 1;
          }
          if (
            !notInterestedIds.includes(a.loan_id) &&
            notInterestedIds.includes(b.loan_id)
          ) {
            return -1;
          }
          // If express 1 must be top compare to express 0
          if (a.express !== b.express) {
            return b.express - a.express;
          }
          // Sorting based on the selection at the sort by option
          if (sortBy === "default") {
            // if user select the option recommend, then sort the data based on the request_amount from high to low
            return b.request_amount - a.request_amount;
          } else if (sortBy === "latest-request") {
            // if user select option latest request, sort the data by latest created_at to the not latest created_at
            return new Date(b.created_at) - new Date(a.created_at);
          }
          return 0;
        })
        .map((item, index) => {
          if (item.visibility) {
            if (calculateCountdown(item.expired_date) !== "Expired") {
              fillAllLenderPpicture(index, item.user_id);
            }
          }
        });
    } else {
      if (data && data.length > 0 && empty == "0") {
        data.map((item, index) => {
          if (item.visibility) {
            if (calculateCountdown(item.expired_date) !== "Expired") {
              fillAllLenderPpicture(index, item.user_id);
            }
          }
        });
      }
    }
  }, [filteredData, data, sortBy]);
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [viewingUser, setViewingUser] = useState(0);
  const [viewingTab, setViewingTab] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [singpassData, setSingpassData] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [keymanDetails, setKeymanDetails] = useState({ contact: "", email: "" });

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];
  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      console.log(json);
      setKeymanDetails({ contact: json.company_contact, email: json.company_email })
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return [];
    }
  };

  return (
    <Box m="4px 20px 20px 20px">
      <Modal
        open={warningLenderModal}
        onCancel={() => {
          setWarningLenderModal(false);
        }}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setWarningLenderModal(false);
                openMakeAnOfferModal();
              }}
            >
              Proceed
            </Button>
            <Button type="default" onClick={() => setWarningLenderModal(false)}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: 800,
            color: "#142C44CC",
            fontFamily: "Manrope, sans-serif",
          }}
        >
          Loan Offer Notice
        </div>
        <div style={{ marginTop: "5px" }}>
          The borrower currently have accepted loans totaling{" "}
          {floatingLoanAmount.totalLoanedAmount.toLocaleString("en-us", {
            style: "currency",
            currency: "SGD",
          })}{" "}
          on this loan request, would you like to proceed making a loan offer to
          this borrower?
        </div>
      </Modal>
      <Modal
        width={isMobile ? "100%" : "60%"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={dataSingpassModal}
        onCancel={() => {
          setDataSingpassModal(false);
          setSingpassData(null);
          setOpenDataIndex(null);
          setKeyman("");
        }}
        footer={null}
      >
        {singpassData ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2> Borrower information</h2>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  background: "rgba(14, 90, 165, 0.1)",
                  color: "rgba(20, 44, 68, 0.8)",
                  fontWeight: 800,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: 16,
                  padding: "3px 15px",
                  borderRadius: 7,
                }}
              >
                Loan keyman: {keyman ? keyman : ""}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 25,
                gap: "20px",
                padding: "10px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              <div
                className="all_button"
                style={{
                  cursor: "pointer",
                  color:
                    viewingUser === 0
                      ? "rgba(20, 44, 68, 0.8)"
                      : "rgba(0,0,0,0.4)",
                  fontSize: viewingUser === 0 ? "14px" : "12px",
                  fontWeight: viewingUser === 0 ? "800" : "400",
                  transition: "0.3s all ease-in-out",
                }}
                onClick={() => {
                  setViewingUser(0);
                  console.log(singpassData);
                  setLiveUserData(singpassData);
                }}
              >
                {singpassData?.person?.name?.value !== undefined
                  ? singpassData?.person?.name?.value
                  : null}
              </div>

              {otherAuthoriserDetails
                ? otherAuthoriserDetails.map((aut, index) => (
                    <div
                      className="all_button"
                      style={{
                        cursor: "pointer",
                        color:
                          viewingUser === index + 1
                            ? "rgba(20, 44, 68, 0.8)"
                            : "rgba(0,0,0,0.4)",
                        fontSize: viewingUser === index + 1 ? "14px" : "12px",
                        fontWeight: viewingUser === index + 1 ? "800" : "400",
                        transition: "0.3s all ease-in-out",
                      }}
                      onClick={() => {
                        setViewingUser(index + 1);
                        console.log(JSON.parse(aut.singpass_data));
                        setLiveUserData(JSON.parse(aut.singpass_data));
                      }}
                      key={index}
                    >
                      {aut.director_name}
                    </div>
                  ))
                : null}
            </div>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                padding: "10px 0px",
                width: "100%",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {availableTab.map((view, index) => (
                <div
                  onClick={() => {
                    setViewingTab(index);
                  }}
                  className="all_button"
                  style={{
                    cursor: "pointer",
                    color:
                      viewingTab === index
                        ? "rgba(20, 44, 68, 0.8)"
                        : "rgba(0,0,0,0.4)",
                    fontSize: viewingTab === index ? "13px" : "12px",
                    fontWeight: viewingTab === index ? "700" : "400",
                    transition: "0.3s all ease-in-out",
                  }}
                  key={index}
                >
                  {view.name}
                </div>
              ))}
            </div>
            <div
              style={{
                padding: "15px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {liveUserData ? (
                viewingTab === 0 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        NRIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          min={0}
                          // className={nricError ? "error-border" : ""}
                          value={liveUserData?.person?.uinfin?.value}
                        />
                        {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Full Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          value={liveUserData?.person?.name?.value}
                          // required
                        />
                        {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Sex
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.sex?.desc}
                          // required
                        />
                        {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Race
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.race?.desc}
                        />
                        {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Nationality
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.nationality?.desc}
                        />
                        {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Date of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.dob?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Country of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.birthcountry?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Residential Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.residentialstatus?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passtype?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passstatus?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Expiry Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.passexpirydate?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Ownership of Private Property Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.ownerprivate?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          type="email"
                          disabled
                          // required
                          value={liveUserData?.person?.email?.value}
                        />
                        {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Mobile Number
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="number"
                          // required
                          value={liveUserData?.person?.mobileno?.nbr?.value}
                        />
                        {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Marital Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.marital?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Occupation
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.occupation?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employer's name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employment Sector
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Outstanding HDB Loan Balance
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbownership[0]?.outstandingloanbalance?.value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Type of HDB Dwelling
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.hdbownership[0]?.hdbtype?.desc
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Ownership - Monthly Loan Installment
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbownership[0]?.monthlyloaninstalment?.value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Model
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.vehicles[0]?.model?.value
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Make
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.vehicles[0]?.make?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                  </div>
                ) : viewingTab === 1 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        UEN
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]?.uen?.value
                          }
                        />
                        {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-name"]
                              ?.value
                          }
                        />
                        {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-type"]
                              ?.value
                          }
                        />
                        {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "entity-status"
                            ]?.value
                          }
                        />
                        {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                            resize: "none",
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.entity?.addresses["addresses-list"][0]
                              ? (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.unit?.value !== undefined
                                  ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.floor?.value !== undefined
                                  ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.block?.value !== undefined
                                  ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.street?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.postal?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.country?.desc !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registration Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "registration-date"
                            ]?.value
                          }
                        />
                        {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Primary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "primary-activity"
                            ]?.desc
                          }
                        />
                        {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Secondary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "secondary-activity"
                            ]?.desc
                          }
                        />
                        {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                      </div>
                    </div>
                    {liveUserData?.entity?.appointments[
                      "appointments-list"
                    ]?.map((appointment, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Position
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment.position.desc}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment["person-reference"].idno.value}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]["person-name"]
                                  .value
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Appointment Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              value={appointment["appointment-date"].value}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : viewingTab === 2 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Employment
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Interest
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Rent
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Tax Clearance
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Trade
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Years of Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.person?.noahistory?.noas?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.amount?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.employment?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.interest?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.rent?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.taxclearance?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.trade?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.yearofassessment?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 3 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Capital Type
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Issued Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Alloted Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.capitals["capitals-list"]?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["capital-type"]?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "issued-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "paid-up-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "share-allotted-amount"
                              ]?.value?.toLocaleString("en-US")}
                            </td>
                            {/* Add other data cells */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 4 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period End Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period Start Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Capital Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Is Audited
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.financials?.highlights?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-end-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-start-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["group-capital-paid-up-capital-amount"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["is-audited"]?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 5 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Allocation
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          NRIC
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.shareholders[
                        "shareholders-list"
                      ] ? (
                        liveUserData?.entity?.shareholders[
                          "shareholders-list"
                        ]?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.allocation?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["person-reference"]?.["person-name"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["person-reference"]?.idno?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["share-type"]?.desc}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              textAlign: "center",
                              padding: "5px 10px",
                            }}
                            colSpan="11"
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                    <PDFViewer style={{ width: "100%", height: "80vh" }}>
                      <Document>
                        <Page size="A4">
                          {/**Letterhead section */}
                          <View
                            style={{
                              paddingVertical: 15,
                            }}
                          >
                            <View
                              style={{
                                paddingHorizontal: 40,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 10,
                                // borderBottom: 1,
                                // borderBottomColor: "grey",
                                paddingBottom: 10,
                              }}
                            >
                              <View
                                style={{
                                  padding: 8,
                                  borderWidth: 3,
                                  borderColor: "#2881c4",
                                  borderRadius: "50%",
                                  marginTop: 15,
                                }}
                              >
                                <Image
                                  source={logo}
                                  style={{ width: 50, objectFit: "contain" }}
                                />
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  SmartLend Financial Pte. Ltd.
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 4,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: -3,
                                  }}
                                >
                                  <Text style={{ fontSize: 7 }}>
                                    UEN: 202327568G
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Tel: +65 88288147
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Email: support@smart-lend.com
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                backgroundColor: "lightgrey",
                                height: 2,
                                width: "100%",
                                marginTop: 20,
                              }}
                            />
                            <View
                              style={{
                                backgroundColor: "#2881c4",
                                height: 4,
                                width: "76%",
                                marginTop: -3,
                                marginLeft: "12%",
                                marginBottom: 15,
                              }}
                            />
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Company Profile
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                }}
                              >
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Name:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "entity-name"
                                        ]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      UEN:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"]
                                          ?.uen?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Registration Date:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["registration-date"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Primary Activity:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "primary-activity"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Ownership:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["ownership"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Status:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["entity-status"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Constitution:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["company-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{ flexDirection: "row", gap: 10 }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Verification By Smartlend:
                                    </Text>
                                    <Text style={{ fontSize: 7 }}>
                                      {badgeVerified
                                        ? "Verified"
                                        : "Un-verified"}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      ACRA Address:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.addresses[
                                        "addresses-list"
                                      ][0]
                                        ? (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.unit?.value !== undefined
                                            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.floor?.value !== undefined
                                            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.block?.value !== undefined
                                            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.street?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.postal?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.country?.desc !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                            : "")
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Capital Type:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {
                                        singpassData?.entity?.["capitals"]?.[
                                          "capitals-list"
                                        ]?.[0]?.["capital-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Paid Up Capital:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "paid-up-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Share Allocated Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "share-allotted-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Issued Capital Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "issued-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Email:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {keymanDetails.email
                                        ? keymanDetails.email
                                        : null}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Mobile:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {keymanDetails.contact
                                        ? keymanDetails.contact
                                        : null}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Financial Highlight
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Revenue</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit pre-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit post-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Start date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>End date</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.financials?.highlights?.map(
                                  (data, index) => (
                                    <View
                                      key={index}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor:
                                          index % 2
                                            ? "rgba(199, 220, 236, 0.2)"
                                            : "transparent",
                                        padding: 4,
                                      }}
                                    >
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-revenue"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-before-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-after-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-start-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-end-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                  )
                                )}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Grants
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Category</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Functional Area</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Status</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Approved Amount</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.grants?.[
                                  "grants-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["development-category"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["functional-area"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>{data?.["grant-type"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["grant-status"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.[
                                          "approved-amount"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Officer(S) / Owners
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Officer Name</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Position</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Appointment Date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.appointments?.[
                                  "appointments-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "25%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.["idno"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>{data?.["position"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["appointment-date"]?.value}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Shareholders
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Shareholder Name / Address</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "18%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Share Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>No of Share</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Date of birth</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Corppass Contact</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.shareholders?.[
                                  "shareholders-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "25%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                          ? data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value +
                                            " (" +
                                            getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.sex?.code +
                                            ")"
                                          : data?.["entity-reference"]?.[
                                              "entity-name"
                                            ]?.value + " (Corporate)"}
                                      </Text>
                                      <Text>
                                        {getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.regadd
                                          ? (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.unit
                                              ?.value !== null
                                              ? `Unit ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.unit
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.floor
                                              ?.value !== null
                                              ? `Floor ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.floor
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.block
                                              ?.value !== null
                                              ? `Block ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.block
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.street
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.street
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.postal
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.postal
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.country
                                              ?.desc !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd
                                                    ?.country?.desc
                                                }.`
                                              : "")
                                          : ""}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.["idno"]
                                          ?.value
                                          ? data?.["person-reference"]?.["idno"]
                                              ?.value
                                          : data?.["entity-reference"]?.["uen"]
                                              ?.value + " (UEN)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "18%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["share-type"]?.desc}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["allocation"]?.value}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "15%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.dob?.value
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.email?.value
                                        }
                                      </Text>
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.mobileno?.nbr?.value
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          </View>
                          <View></View>
                        </Page>
                        {combinedSingpassData
                          ? combinedSingpassData.map((data, index) => (
                              <Page key={index} size="A4">
                                {/**Letterhead section */}
                                <View
                                  style={{
                                    paddingVertical: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Personal Information"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                      }}
                                    >
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Ownership of Private Residential
                                            Property:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {data?.person?.hdbownership
                                              ? data?.person?.hdbownership
                                                  ?.length > 0
                                                ? "Yes"
                                                : "No"
                                              : "No"}
                                          </Text>
                                        </View>
                                        {data?.person?.hdbownership
                                          ? data?.person?.hdbownership?.length >
                                            0
                                            ? data?.person?.hdbownership?.map(
                                                (hdb, index3) => (
                                                  <View key={index3}>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB {index3 + 1}
                                                      </Text>
                                                    </View>

                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB Ownership:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.address
                                                          ? (hdb?.address?.unit
                                                              ?.value !== null
                                                              ? `Unit ${hdb?.address?.unit?.value}, `
                                                              : "") +
                                                            (hdb?.address?.floor
                                                              ?.value !== null
                                                              ? `Floor ${hdb?.address?.floor?.value}, `
                                                              : "") +
                                                            (hdb?.address?.block
                                                              ?.value !== null
                                                              ? `Block ${hdb?.address?.block?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.street
                                                              ?.value !== null
                                                              ? `${hdb?.address?.street?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.postal
                                                              ?.value !== null
                                                              ? `${hdb?.address?.postal?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.country
                                                              ?.desc !== null
                                                              ? `${hdb?.address?.country?.desc}.`
                                                              : "")
                                                          : ""}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Outstanding HDB Loan
                                                        Balance:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Type of HDB:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.hdbtype?.desc}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Loan
                                                        Installment:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "No"
                                          : "No"}
                                      </View>
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Contact:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.mobileno?.nbr?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Email:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.email?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Marital Status:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.marital?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Vehicles:
                                          </Text>
                                          <View>
                                            {data?.person?.vehicles?.map(
                                              (veh, index4) => (
                                                <View key={index4}>
                                                  <Text
                                                    style={{
                                                      fontSize: 7,
                                                      width: "65%",
                                                    }}
                                                  >
                                                    {veh?.make?.value +
                                                      ": " +
                                                      veh?.model?.value}
                                                  </Text>
                                                </View>
                                              )
                                            )}
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Notice of Assessments"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor: "#c7dcec",
                                          opacity: 0.7,
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Year of Assesment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Amount</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Employment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Interest</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Rent</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Trade</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Tax Clearance</Text>
                                        </View>
                                      </View>
                                      {data?.person?.noahistory?.noas?.map(
                                        (data2, index2) => (
                                          <View
                                            key={index2}
                                            style={{
                                              flexDirection: "row",
                                              width: "100%",
                                              backgroundColor:
                                                index2 % 2
                                                  ? "rgba(199, 220, 236, 0.2)"
                                                  : "transparent",
                                              padding: 4,
                                              gap: 10,
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {
                                                  data2?.["yearofassessment"]
                                                    ?.value
                                                }
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "amount"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "employment"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "interest"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "rent"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "trade"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.["taxclearance"]?.value}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                  {allCBSData?.filter(
                                    (dat2) =>
                                      dat2?.name === data?.person?.name?.value
                                  )?.[0] ? (
                                    <View
                                      style={{
                                        marginTop: 15,
                                        paddingHorizontal: 40,
                                      }}
                                    >
                                      <View
                                        style={{
                                          backgroundColor: "#c7dcec",
                                          alignItems: "center",
                                          padding: 6,
                                          borderTopLeftRadius: 7,
                                          borderTopRightRadius: 7,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 10,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {data?.person?.name?.value +
                                            " | Credit Bureau | Personal Commitement"}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Credit Score:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.CreditScore
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Probability of Default:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.ProbabilityofDefault
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]?.cbs_data
                                                ?.UnsecuredCreditUtilizationinpercentage +
                                                "%"}
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Risk Grade:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.RiskGrade
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]?.cbs_data
                                                ?.SecuredCreditUtilizationinpercentage +
                                                "%"}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Monthly Commitment/Installment:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.MonthlyCommitment?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor: "#c7dcec",
                                            opacity: 0.7,
                                            padding: 4,
                                            gap: 10,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Loan / Product Type</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Grantor / Bank</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Outstanding Balance</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Monthly Installment</Text>
                                          </View>
                                        </View>

                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                            (imp, index10) => (
                                              <View
                                                key={index10}
                                                style={{
                                                  flexDirection: "row",
                                                  width: "100%",
                                                  backgroundColor:
                                                    index10 % 2
                                                      ? "rgba(199, 220, 236, 0.4)"
                                                      : "rgba(199, 220, 236, 0.15)",
                                                  padding: 4,
                                                  gap: 10,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp.includes(
                                                      "private residential purchase",
                                                      ""
                                                    )
                                                      ? "Private Residential Purchase"
                                                      : imp.includes(
                                                          "motor vehicle loan",
                                                          ""
                                                        )
                                                      ? "Motor Vehicle Loan"
                                                      : imp.includes(
                                                          "hdb loan",
                                                          ""
                                                        )
                                                      ? "HDB Loan"
                                                      : ""}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp
                                                      .replace(
                                                        "private residential purchase",
                                                        ""
                                                      )
                                                      .replace(
                                                        "motor vehicle loan",
                                                        ""
                                                      )
                                                      .replace("hdb loan", "")
                                                      .trim()
                                                      .split(" ")[0]
                                                      .toUpperCase()}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {parseFloat(
                                                      imp
                                                        .split("   ")
                                                        [
                                                          imp.split("   ")
                                                            .length - 4
                                                        ].replace(/,/g, "")
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "SGD",
                                                    })}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.CBSImpLoanSupplementaryInstallment?.[
                                                      index10
                                                    ]
                                                      ? (
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 4
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          ) +
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 3
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          )
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )
                                                      : null}
                                                  </Text>
                                                </View>
                                              </View>
                                            )
                                          )}
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                          borderBottomWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Last 6 Months (Unsecured) Credit
                                            Utilization Graph:
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "100%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <View
                                              style={{
                                                width: "5%",
                                                position: "relative",
                                              }}
                                            >
                                              <View style={{ height: "15px" }}>
                                                <Text
                                                  style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{ height: "220px" }}
                                              ></View>
                                            </View>
                                            <View
                                              style={{
                                                width: "95%",
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "100%",
                                                  flexDirection: "row",
                                                  marginTop: "25px",
                                                  borderBottom:
                                                    "1px solid lightgrey",
                                                  height: "200px",
                                                  alignItems: "flex-end",
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    height: "200px",
                                                    borderLeft:
                                                      "1px solid lightgrey",
                                                  }}
                                                />
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat30, index30) => (
                                                      <View
                                                        key={index30}
                                                        style={{
                                                          marginLeft: "4%",
                                                          marginRight: "4%",
                                                          width: "8%",
                                                          borderTopLeftRadius:
                                                            "4px",
                                                          borderTopRightRadius:
                                                            "4px",
                                                          height: `${
                                                            (parseFloat(
                                                              dat30.value.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                            ) /
                                                              allCBSData?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]?.cbs_data
                                                                ?.Last6MonthsGraphChartMaxData) *
                                                            100
                                                          }%`,
                                                          backgroundColor:
                                                            "rgba(217, 238, 255, 1)",
                                                        }}
                                                      ></View>
                                                    )
                                                  )}
                                              </View>
                                              <View
                                                style={{
                                                  marginTop: "8px",
                                                  flexDirection: "row",
                                                  fontSize: 7,
                                                }}
                                              >
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat, index) => (
                                                      <View
                                                        key={index}
                                                        style={{
                                                          width: "16%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {dat.date
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            dat.date.slice(1)}
                                                        </Text>
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {parseFloat(
                                                            dat.value.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ).toLocaleString(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "SGD",
                                                            }
                                                          )}
                                                        </Text>
                                                      </View>
                                                    )
                                                  )}
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ) : null}
                                </View>
                              </Page>
                            ))
                          : null}
                      </Document>
                    </PDFViewer>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        borderRadius="10px"
      >
        <Box
          gridColumn={screenWidth > 1200 ? "span 9" : "span 12"}
          gridRow="span 2"
          borderRadius="10px"
          opacity="0.95"
          paddingBottom="100px"
          style={{
            overflowY: "scroll",
            height: isMobile ? "80vh" : "90vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
          // margin="20px 20px 20px 20px"
        >
          <Modal
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() =>
                    handleRequestMoreDocument(
                      loanId,
                      express,
                      user_id,
                      principal_name
                    )
                  }
                >
                  Submit
                </Button>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>,
            ]}
            open={isModalOpen}
            // onOk={handleOk}
            onCancel={handleCancel}
          >
            {" "}
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                marginTop: "10px",
              }}
            >
              Please Insert The Document Name
            </div>
            <div>
              {documentDescriptions.map((description, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "90%",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontWeight: "700",
                        marginTop: "10px",
                      }}
                    >
                      Document Name
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <div>
                      <input
                        style={{
                          width: "90%",
                          padding: "8px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          outline: "none",
                          border: "1px solid rgba(0,0,0,0.2)",
                        }}
                        value={description}
                        placeholder="Document Name"
                        onChange={(event) => handleChange(event, index)}
                      />
                    </div>
                  </div>
                  {documentDescriptions.length > 1 && (
                    <div style={{ width: "10%", textAlign: "right" }}>
                      <Button
                        type="default"
                        onClick={() => remove(index)}
                        style={{
                          backgroundColor: "none",
                          border: "none",
                          color: "#FF0000",
                          boxShadow: "none",
                          marginTop: "10px",
                        }}
                        icon={<MinusOutlined />}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </div>
              ))}
              <div>
                <Button
                  type="default"
                  onClick={addMore}
                  style={{
                    backgroundColor: "none",
                    border: "none",
                    color: "#0E5AA5",
                    marginLeft: "25%",
                    boxShadow: "none",
                    marginTop: "5px",
                  }}
                  icon={<PlusOutlined style={{ marginTop: "-10px" }} />}
                >
                  Add more
                </Button>
              </div>
            </div>
          </Modal>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            borderRadius="10px"
            style={{ marginBottom: "10px" }}
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Requested Loans
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "63%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="default">Recommend</option>
                    <option value="latest-request">Latest Request</option>
                  </select>
                </div>
              </div>
            </div>
          </Box>
          <Box gridColumn="span 4">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                color: "grey",
                gap: "15px",
              }}
            >
              {filteredData && filteredData.length > 0 ? (
                filteredData
                  .sort((a, b) => {
                    if (
                      notInterestedIds.includes(a.loan_id) &&
                      !notInterestedIds.includes(b.loan_id)
                    ) {
                      return 1;
                    }
                    if (
                      !notInterestedIds.includes(a.loan_id) &&
                      notInterestedIds.includes(b.loan_id)
                    ) {
                      return -1;
                    }
                    // If express 1 must be top compare to express 0
                    if (a.express !== b.express) {
                      return b.express - a.express;
                    }
                    // Sorting based on the selection at the sort by option
                    if (sortBy === "default") {
                      // if user select the option recommend, then sort the data based on the request_amount from high to low
                      return b.request_amount - a.request_amount;
                    } else if (sortBy === "latest-request") {
                      // if user select option latest request, sort the data by latest created_at to the not latest created_at
                      return new Date(b.created_at) - new Date(a.created_at);
                    }
                    return 0;
                  })
                  .map((item, index) =>
                    item.visibility ? (
                      calculateCountdown(item.expired_date) !== "Expired" ? (
                        <Container
                          key={index}
                          icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                          title={item.principal_name || "-"}
                          subtitle={
                            item.express === 1
                              ? "Express Business Loan"
                              : "Simple Business Loan"
                          }
                          loanOfferCount={item.loan_offer_count + " Offering"} // use the countLoanOffer object to access the count for each loan_id
                          label="Credit Score"
                          credit_score={item.credit_score}
                          description={item.credit_score || "-"}
                          createdAt={item.created_at}
                          label1="Loan Amount"
                          description1={item.request_amount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                          label2="Period"
                          description2={item.request_period + " Months" || "-"}
                          reject_reason={item.reject_reason}
                          countDown={item.expired_date} // pass the value of expired_date
                          index={index} // pass the index prop to each Container instance
                          loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                          userId={item.user_id} // pass the user_id prop to each Container instance
                          open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                          onClickModal={(event) =>
                            showModal(item.loan_id, event)
                          } // pass the showModal function to each Container instance
                          cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                          item={item}
                        />
                      ) : null
                    ) : null
                  )
              ) : data && data.length > 0 && empty == "0" ? (
                data.map((item, index) =>
                  item.visibility ? (
                    calculateCountdown(item.expired_date) !== "Expired" ? (
                      <Container
                        key={index}
                        icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        title={item.principal_name || "-"}
                        subtitle={
                          item.express === 1
                            ? "Express Applicant"
                            : "Approved Borrower"
                        }
                        loanOfferCount={item.loan_offer_count + " Offering"} // use the countLoanOffer object to access the count for each loan_id
                        label="Credit Score"
                        credit_score={item.credit_score}
                        description={item.credit_score || "-"}
                        createdAt={item.created_at}
                        label1="Loan Amount"
                        description1={item.request_amount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                        label2="Period"
                        description2={item.request_period + " Months" || "-"}
                        reject_reason={item.reject_reason}
                        countDown={item.expired_date} // pass the value of expired_date
                        index={index} // pass the index prop to each Container instance
                        loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                        userId={item.user_id} // pass the user_id prop to each Container instance
                        open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                        onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                        cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                        item={item}
                      />
                    ) : null
                  ) : null
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No results found
                  </div>
                </div>
              )}
              {filteredData && filteredData.length > 0
                ? filteredData.map((item, index) => (
                    <Modal
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates[item.loan_id]}
                      onCancel={() =>
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: 25,
                          }}
                        >
                          {offer_amount.length > 0 &&
                            offer_interest_rate.length > 0 &&
                            offer_period.length > 0 &&
                            type.length > 0 && (
                              <div>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    handleMakeOffer(
                                      item.loan_id,
                                      item.express,
                                      item.user_id,
                                      item.principal_name,
                                      item.created_at
                                    )
                                  }
                                >
                                  Make Offer
                                </Button>
                                <Button
                                  type="default"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() =>
                                    showModalRequestDocument(
                                      item.loan_id,
                                      item.express,
                                      item.user_id,
                                      item.principal_name,
                                      item.created_at
                                    )
                                  }
                                >
                                  Request More Document
                                </Button>
                              </div>
                            )}
                        </div>,
                      ]}
                    >
                      <div>
                        <div
                          style={{
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 700,
                            fontSize: 20,
                          }}
                        >
                          Loan offer to {item.principal_name || "-"}
                        </div>
                        <div style={{ display: "flex", marginTop: 10 }}>
                          <div
                            style={{
                              background: "rgba(153, 178, 205, 0.67)",
                              padding: "5px 15px",
                              borderRadius: "6px",
                              color: "white",
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                          >
                            Loan ID: {item.loan_id}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_amount">Loan Amount</label>
                          <br />
                          <input
                            type="number"
                            id="offer_amount"
                            name="offer_amount"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanAmountChange}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_interest_rate">
                            Interest Rate (%)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_interest_rate"
                            name="offer_interest_rate"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            min={0}
                            max={5}
                            onChange={handleInterestRateChange}
                          />
                          {" %"}
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_period">
                            Loan Period (Month)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_period"
                            name="offer_period"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanPeriodChange}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="offer_processing_fee">
                            Processing Fee (%)
                          </label>
                          <br />
                          <input
                            type="number"
                            id="offer_processing_fee"
                            name="offer_processing_fee"
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "10px",
                              border: "2px solid lightgrey",
                            }}
                            value={parseFloat(offer_processing_fee)}
                            onChange={(e) => setProcessingFee(e.target.value)}
                            min={0}
                            max={7}
                          />{" "}
                          %
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="type">Loan Type</label>
                          <br />
                          <select
                            id="type"
                            name="type"
                            style={{
                              borderRadius: "10px",
                              width: "73%",
                              border: "2px solid lightgrey",
                            }}
                            onChange={handleLoanTypeChange}
                          >
                            <option value="simple">
                              Unsecured Business Term Loan
                            </option>
                            {/* <option value="effective">
                              Effective Loan
                            </option> */}
                          </select>
                        </div>
                      </div>

                      <hr />
                      <p>
                        Monthly Instalment payable:{" "}
                        {Number(offer_monthly_installment).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                      </p>

                      <p>
                        Processing Fees:{" "}
                        {Number(
                          offer_amount * (offer_processing_fee / 100)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                      <p>
                        Forecast Revenue:{" "}
                        {Number(offer_revenue).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                      <p>
                        Platform Fee:{" "}
                        {Number(offer_amount * 0.015).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </p>
                    </Modal>
                  ))
                : data &&
                  data.length > 0 &&
                  empty == "0" &&
                  data
                    .sort((a, b) => {
                      if (
                        notInterestedIds.includes(a.loan_id) &&
                        !notInterestedIds.includes(b.loan_id)
                      ) {
                        return 1;
                      }
                      if (
                        !notInterestedIds.includes(a.loan_id) &&
                        notInterestedIds.includes(b.loan_id)
                      ) {
                        return -1;
                      }
                      // If express 1 must be top compare to express 0
                      if (a.express !== b.express) {
                        return b.express - a.express;
                      }
                      // Sorting based on the selection at the sort by option
                      if (sortBy === "default") {
                        // if user select the option recommend, then sort the data based on the request_amount from high to low
                        return b.request_amount - a.request_amount;
                      } else if (sortBy === "latest-request") {
                        // if user select option latest request, sort the data by latest created_at to the not latest created_at
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <Modal
                        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                        open={modalStates[item.loan_id]}
                        onCancel={() =>
                          setModalStates((prevStates) => ({
                            ...prevStates,
                            [item.loan_id]: false,
                          }))
                        }
                        footer={[
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 16,
                            }}
                          >
                            {offer_amount.length > 0 &&
                              offer_interest_rate.length > 0 &&
                              offer_period.length > 0 &&
                              type.length > 0 && (
                                <div>
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      handleMakeOffer(
                                        item.loan_id,
                                        item.express,
                                        item.user_id,
                                        item.principal_name,
                                        item.created_at
                                      )
                                    }
                                  >
                                    Make Offer
                                  </Button>
                                  <Button
                                    type="default"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() =>
                                      showModalRequestDocument(
                                        item.loan_id,
                                        item.express,
                                        item.user_id,
                                        item.principal_name,
                                        item.created_at
                                      )
                                    }
                                  >
                                    Request More Document
                                  </Button>
                                </div>
                              )}
                          </div>,
                        ]}
                      >
                        <div>
                          <div
                            style={{
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 700,
                              fontSize: 20,
                            }}
                          >
                            Loan offer to {item.principal_name || "-"}
                          </div>
                          <div style={{ display: "flex", marginTop: 10 }}>
                            <div
                              style={{
                                background: "rgba(153, 178, 205, 0.67)",
                                padding: "5px 15px",
                                borderRadius: "6px",
                                color: "white",
                                fontSize: 12,
                                fontWeight: 400,
                              }}
                            >
                              Loan ID: {item.loan_id}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <label htmlFor="offer_amount">Loan Amount</label>
                            <br />
                            <input
                              type="number"
                              id="offer_amount"
                              name="offer_amount"
                              style={{
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                border: "2px solid lightgrey",
                              }}
                              onChange={handleLoanAmountChange}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label htmlFor="offer_interest_rate">
                              Interest Rate (Month)
                            </label>
                            <br />
                            <input
                              type="number"
                              id="offer_interest_rate"
                              name="offer_interest_rate"
                              style={{
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                border: "2px solid lightgrey",
                              }}
                              min={0}
                              max={100}
                              onChange={handleInterestRateChange}
                            />
                            {" %"}
                            {errorMessage && (
                              <p style={{ color: "red" }}>{errorMessage}</p>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <label htmlFor="offer_period">
                              Loan Period (Month)
                            </label>
                            <br />
                            <input
                              type="number"
                              id="offer_period"
                              name="offer_period"
                              style={{
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                border: "2px solid lightgrey",
                              }}
                              onChange={handleLoanPeriodChange}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label htmlFor="offer_processing_fee">
                              Processing Fee
                            </label>
                            <br />
                            <input
                              type="number"
                              id="offer_processing_fee"
                              name="offer_processing_fee"
                              style={{
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                border: "2px solid lightgrey",
                              }}
                              value={parseFloat(offer_processing_fee)}
                              onChange={(e) => setProcessingFee(e.target.value)}
                              min={0}
                              max={100}
                            />{" "}
                            %
                            {errorMessage && (
                              <p style={{ color: "red" }}>{errorMessage}</p>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <label htmlFor="type">Loan Type</label>
                            <br />
                            <select
                              id="type"
                              name="type"
                              style={{
                                borderRadius: "10px",
                                width: "73%",
                                border: "2px solid lightgrey",
                              }}
                              onChange={handleLoanTypeChange}
                            >
                              <option value="simple">
                                Unsecured Business Term Loan
                              </option>
                              {/* <option value="effective">
                                Effective Loan
                              </option> */}
                            </select>
                          </div>
                        </div>

                        <hr />

                        <p>
                          Processing Fees:{" "}
                          {Number(
                            offer_amount * (offer_processing_fee / 100)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </p>
                        <p>
                          Monthly Instalment payable:{" "}
                          {Number(offer_monthly_installment).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        </p>
                        <p>
                          Guaranteed Revenue:{" "}
                          {Number(
                            offer_revenue +
                              offer_amount * (offer_processing_fee / 100)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </p>
                        <p>
                          Platform Fee:{" "}
                          {Number(offer_amount * 0.015).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        </p>
                      </Modal>
                    ))}
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <Modal
                    style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                    open={modalStates2[item.loan_id]}
                    onCancel={() =>
                      setModalStates2((prevStates) => ({
                        ...prevStates,
                        [item.loan_id]: false,
                      }))
                    }
                    footer={[
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 16,
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            handleNotInterested(item.loan_id, selectedValue)
                          }
                        >
                          Submit
                        </Button>
                      </div>,
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 16,
                        borderRadius: "10px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label
                          htmlFor="offer_amount"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "600",
                            fontSize: "18px",
                            opacity: ".6",
                          }}
                        >
                          Are you not interested with this loan?{" "}
                        </label>
                        <br />
                        {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.principal_name || "-"}</p>                        */}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 16,
                        borderRadius: "10px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label
                          htmlFor="offer_amount"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "600",
                            fontSize: "14px",
                            opacity: ".6",
                          }}
                        >
                          Please Select one of the option :{" "}
                        </label>

                        <br />
                        <select
                          id="type"
                          name="type"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            marginLeft: "0px",
                          }}
                          onChange={handleNotInterestedModal}
                        >
                          <option value="interest_rate_is_too_low">
                            The interest rate is too low
                          </option>
                          <option value="loan_period_is_too_long">
                            The loan period is too long
                          </option>
                          <option value="credit_score_borrower_not_meet_expectation">
                            The credit score of the borrower does not meet my
                            expectation
                          </option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </div>
                    {showOtherInput && (
                      <div style={{ marginTop: "16px" }}>
                        <label
                          htmlFor="other_reason"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "600",
                            fontSize: "14px",
                            opacity: ".6",
                          }}
                        >
                          Please specify:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="other_reason"
                          name="other_reason"
                          style={{
                            borderRadius: "10px",
                            width: "90%",
                            paddingLeft: "10px",
                          }}
                          onChange={(event) =>
                            setSelectedValue(event.target.value)
                          }
                          value={selectedValue}
                        />
                      </div>
                    )}

                    <hr />
                  </Modal>
                ))}
            </div>
          </Box>
        </Box>
        {screenWidth > 1200 ? (
          <Box
            gridColumn="span 3"
            opacity="1.00"
            display="flex"
            style={{
              height: "90vh",
              padding: "15px",
              background:
                "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <div
              style={{
                border: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FilterAltIcon
                  style={{ color: "#142C44CC", marginRight: "5px" }}
                />
                <h4
                  style={{
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "18px",
                    margin: "0px",
                  }}
                >
                  Search Filter
                </h4>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Loan Amount
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <input
                  type="number"
                  placeholder="MIN"
                  value={minLoanAmount}
                  onChange={(e) => setMinLoanAmount(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "40%",
                  }}
                />
                -
                <input
                  type="number"
                  placeholder="MAX"
                  value={maxLoanAmount}
                  onChange={(e) => setMaxLoanAmount(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 0px 0px 10px",
                    width: "40%",
                  }}
                />
              </div>
              <div style={{ margin: "10px 0px 20px 0px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox1"
                    checked={selectedCheckbox === "checkbox1"}
                    onChange={() =>
                      handleLoanAmountCheckbox("checkbox1", 0, 10000)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox1"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    $0 - $10,000
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox2"
                    checked={selectedCheckbox === "checkbox2"}
                    onChange={() =>
                      handleLoanAmountCheckbox("checkbox2", 10001, 50000)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    htmlFor="checkbox2"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    $10,001 - $50,000
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox3"
                    checked={selectedCheckbox === "checkbox3"}
                    onChange={() =>
                      handleLoanAmountCheckbox("checkbox3", 50001, 10000000)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    htmlFor="checkbox3"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    {">$50,000"}
                  </label>
                </div>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Expected Interest Rate
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <input
                  type="number"
                  placeholder="MIN"
                  value={LoanInterestMin}
                  onChange={(e) => setLoanInterestMin(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "40%",
                  }}
                />
                -
                <input
                  type="number"
                  placeholder="MAX"
                  value={LoanInterestMax}
                  onChange={(e) => setLoanInterestMax(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 0px 0px 10px",
                    width: "40%",
                  }}
                />
              </div>
              <div style={{ margin: "10px 0px 20px 0px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox10"
                    checked={selectedCheckbox2 === "checkbox10"}
                    onChange={() =>
                      handleLoanInterestCheckbox("checkbox10", 1, 2)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox10"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    1% - 2%
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox11"
                    checked={selectedCheckbox2 === "checkbox11"}
                    onChange={() =>
                      handleLoanInterestCheckbox("checkbox11", 2.1, 5)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox11"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    2.1% - 5%
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox12"
                    checked={selectedCheckbox2 === "checkbox12"}
                    onChange={() =>
                      handleLoanInterestCheckbox("checkbox12", 5.01, 100)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox12"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    {">5%"}
                  </label>
                </div>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Loan Period
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <input
                  type="number"
                  placeholder="MIN"
                  value={LoanPeriodMin}
                  onChange={(e) => setLoanPeriodMin(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "40%",
                  }}
                />
                -
                <input
                  type="number"
                  placeholder="MAX"
                  value={LoanPeriodMax}
                  onChange={(e) => setLoanPeriodMax(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 0px 0px 10px",
                    width: "40%",
                  }}
                />
              </div>
              <div style={{ margin: "10px 0px 20px 0px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox5"
                    checked={selectedCheckbox1 === "checkbox5"}
                    onChange={() => handleLoanPeriodCheckbox("checkbox5", 0, 6)}
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox5"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    0 - 6 Months
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox6"
                    checked={selectedCheckbox1 === "checkbox6"}
                    onChange={() =>
                      handleLoanPeriodCheckbox("checkbox6", 7, 12)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox6"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    7 - 12 Months
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox7"
                    checked={selectedCheckbox1 === "checkbox7"}
                    onChange={() =>
                      handleLoanPeriodCheckbox("checkbox7", 13, 60)
                    }
                    style={{ margin: "0px 10px 0px 0px" }}
                  />
                  <label
                    for="checkbox7"
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                    }}
                  >
                    {">12 Months"}
                  </label>
                </div>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Years of Incorporation
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <input
                  type="number"
                  placeholder="MIN"
                  value={MinYearOfIncorp}
                  onChange={(e) => setMinYearOfIncorp(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "40%",
                  }}
                />
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Industries
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <select
                  value={selectedIndustry}
                  onChange={(e) => {
                    setSelectedIndustry(e.target.value);
                  }}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "90%",
                  }}
                >
                  <option value="">None selected</option>
                  {SsicCodes.map((cod, index) => (
                    <option value={cod.name} key={index}>
                      {cod.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Minimum Credit Rating
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <select
                  value={selectedCreditRating}
                  onChange={(e) => {
                    setSelectedCreditRating(e.target.value);
                  }}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "90%",
                  }}
                >
                  <option value="">None selected</option>
                  {possiblecreditrating.map((cr, index) => (
                    <option value={cr.value} key={index}>
                      {cr.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Loan Category
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                  margin: "10px 0px 0px 0px",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox9"
                  onChange={(e) =>
                    handleCheckboxChange(e.target.checked, "business")
                  }
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox9"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  Business Loan
                </label>
                {/* <br />
         <input type="checkbox" id="checkbox10" onChange={(e) => handleCheckboxChange(e.target.checked, 'other')} />
         <label htmlFor="checkbox10" style={{ fontWeight: '500', fontFamily: 'Poppins, sans-serif', fontSize: '15px' }}>Other Loan</label> */}
              </div>
              <div style={{ marginTop: "15px" }}>
                <button
                  style={{
                    background: "rgb(14, 90, 165)",
                    borderRadius: "30px",
                    padding: "7px 35px",
                    border: "none",
                    color: "white",
                    fontWeight: 600,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setMinLoanAmount("");
                    setMaxLoanAmount("");
                    setLoanInterestMin("");
                    setLoanInterestMax("");
                    setLoanPeriodMin("");
                    setLoanPeriodMax("");
                    setMinYearOfIncorp("");
                    setSelectedIndustry("");
                    setSelectedCreditRating("");
                    setSelectedCheckbox(null);
                    setSelectedCheckbox1(null);
                    setSelectedCheckbox2(null);
                  }}
                >
                  Reset filter
                </button>
              </div>
            </div>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default LoanRequestGrid;
