import React, { useEffect, useState } from "react";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import cookies from "js-cookie";

const Valuer = () => {
  const [valuerForm, setValuerForm] = useState({
    propertyTypeCode: "condo",
    postalCode: "",
    addressUnit: "",
    addressNumber: "",
    addressStreet: "",
    projectName: "",
    valuationType: "sale",
  });
  const [retreivedData, setRetreivedData] = useState();

  const submitValuation = async () => {
    try {
      let item = {};
      if (
        valuerForm.propertyTypeCode !== null &&
        valuerForm.propertyTypeCode !== ""
      ) {
        item.propertyTypeCode = valuerForm.propertyTypeCode;
      }
      if (valuerForm.postalCode !== null && valuerForm.postalCode !== "") {
        item.postalCode = valuerForm.postalCode;
      }
      if (valuerForm.addressUnit !== null && valuerForm.addressUnit !== "") {
        item.addressUnit = valuerForm.addressUnit;
      }
      if (
        valuerForm.addressNumber !== null &&
        valuerForm.addressNumber !== ""
      ) {
        item.addressNumber = valuerForm.addressNumber;
      }
      if (
        valuerForm.addressStreet !== null &&
        valuerForm.addressStreet !== ""
      ) {
        item.addressStreet = valuerForm.addressStreet;
      }
      if (valuerForm.projectName !== null && valuerForm.projectName !== "") {
        item.projectName = valuerForm.projectName;
      }
      if (
        valuerForm.valuationType !== null &&
        valuerForm.valuationType !== ""
      ) {
        item.valuationType = valuerForm.valuationType;
      }

      const response = await fetch(
        `https://www.smart-lend.com/api/property/getPropertyValuation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      const json = await response.json();

      if (json.code === 200) {
        console.log("call was succesful");
        message.success("API call was succesful!");
        setRetreivedData(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div style={{ width: "100%", marginBottom: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
            }}
          >
            Property Valuation
          </div>
        </div>
        <div>This form is specifically for test purposes.</div>
      </div>
      <div style={{ width: "100%" }}>
        <div>Property Type:</div>
        <select
          value={valuerForm.propertyTypeCode}
          onChange={(e) => {
            setValuerForm({ ...valuerForm, propertyTypeCode: e.target.value });
          }}
          style={{
            borderRadius: "8px",
            border: "1px solid lightgrey",
            width: "350px",
            padding: "5px 8px",
          }}
        >
          <option value={"condo"}>Condo</option>
          <option value={"land"}>Landed</option>
          <option value={"hdb"}>HDB</option>
          <option value={"office"}>Office</option>
        </select>
      </div>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <div>Address Unit:</div>
        <input
          value={valuerForm.addressUnit}
          onChange={(e) => {
            setValuerForm({ ...valuerForm, addressUnit: e.target.value });
          }}
          style={{
            borderRadius: "8px",
            border: "1px solid lightgrey",
            width: "350px",
            padding: "5px 8px",
          }}
        />
      </div>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <div>Address Number:</div>
        <input
          value={valuerForm.addressNumber}
          onChange={(e) => {
            setValuerForm({ ...valuerForm, addressNumber: e.target.value });
          }}
          style={{
            borderRadius: "8px",
            border: "1px solid lightgrey",
            width: "350px",
            padding: "5px 8px",
          }}
        />
      </div>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <div>Address Street:</div>
        <input
          value={valuerForm.addressStreet}
          onChange={(e) => {
            setValuerForm({ ...valuerForm, addressStreet: e.target.value });
          }}
          style={{
            borderRadius: "8px",
            border: "1px solid lightgrey",
            width: "350px",
            padding: "5px 8px",
          }}
        />
      </div>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <div>Postal Code:</div>
        <input
          value={valuerForm.postalCode}
          onChange={(e) => {
            setValuerForm({ ...valuerForm, postalCode: e.target.value });
          }}
          style={{
            borderRadius: "8px",
            border: "1px solid lightgrey",
            width: "350px",
            padding: "5px 8px",
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => {
            submitValuation();
          }}
          style={{
            background: "rgb(17, 92, 166)",
            color: "white",
            padding: "7px 30px",
            border: "none",
            marginTop: "10px",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          Submit
        </div>
      </div>
    </div>
  );
};

export default Valuer;
