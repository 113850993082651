import { Form, Button, Modal, Table, Upload, message } from "antd";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import JSZip from "jszip";

const TableOfferedLoan = ({
  loan_id,
  add_document,
  onRowClick,
  onSelectionChange,
}) => {
  const [data, setData] = useState([]);
  const [addDocument, setAddDocument] = useState([]); // <- new state for add_document
  const [offerId, setOfferId] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState({});
  const [fileLists, setFileLists] = useState({});
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  const table2titleStyle = {
    textAlign: "left",
    width: "1%",
    color: "rgba(0,0,0,0.4)",
    fontFamily: "Manrope, sans-serif",
    fontSize: screenWidth > 1200 ? "11px" : "9px",
    fontWeight: screenWidth > 1200 ? "800" : "400",
  };

  const handleBeforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (isPdf) {
      setFileList((old) => [...old, file]); // add the new file to the existing file list
      return false; // prevent file from being uploaded automatically
    }
    message.error("Can only upload PDF files");
    return false; // prevent file from being uploaded
  };

  const handlePreview = (file) => {
    window.open(URL.createObjectURL(file));
  };

  useEffect(() => {
    console.log("add_document value", add_document);
  });

  const [modalStates, setModalStates] = useState({});

  useEffect(() => {
    const initialStates = {};

    data.forEach((item) => {
      initialStates[item.loan_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const showModal = (loanId) => {
    console.log("loanId Modal", loanId);
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // This function checks whether all keys in fileLists have at least one file
  const allUploadsFilled = () => {
    return (
      Array.isArray(addDocument) &&
      addDocument.every(
        (document) =>
          document.document ||
          (fileLists[document.document_name] &&
            fileLists[document.document_name].length > 0)
      )
    );
  };

  const handleDownloadAdditionalFile = async (documentName, event) => {
    console.log("parentData value", data);
    try {
      let item = {
        documents: [documentName],
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/viewApplicantDocument",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();

      // Download each file
      data.data.forEach((url, index) => {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "");
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, index * 1000); // 1 second delay between each download
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequestMoreDocument = async (loan_id, offer_id) => {
    for (const documentName in fileLists) {
      const fileList = fileLists[documentName];
      if (fileList.length > 1) {
        console.log("test", fileList);
        const zip = new JSZip();
        const filePromises = fileList.map(
          (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = function (event) {
                zip.file(file.name, event.target.result);
                resolve();
              };
              reader.onerror = function (err) {
                reject(err);
              };
              reader.readAsArrayBuffer(file.originFileObj);
            })
        );

        try {
          await Promise.all(filePromises);
          const content = await zip.generateAsync({ type: "blob" });

          const formData = new FormData();
          formData.append("loan_id", loan_id);
          formData.append("offer_id", offer_id);
          formData.append("document_name", documentName);
          formData.append("document", content, `${documentName}.zip`);

          await sendDocument(formData);
          setIsModalOpen(false);
        } catch (error) {
          console.error("Error generating ZIP:", error);
          message.error("Error generating ZIP");
        }
      } else if (fileList.length === 1) {
        const file = fileList[0];
        const formData = new FormData();
        formData.append("loan_id", loan_id);
        formData.append("offer_id", offerId);
        formData.append("document_name", documentName);
        formData.append("document", file.originFileObj, file.name);

        await sendDocument(formData);
        setIsModalOpen(false);
      }
    }
  };

  const sendDocument = async (formData) => {
    try {
      const uploadResponse = await fetch(
        "https://www.smart-lend.com/api/loan/uploadAddDocument",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload document");
      }

      const uploadData = await uploadResponse.json();
      message.success("Additional file uploaded successfully!");
    } catch (error) {
      console.error("Error uploading additional file:", error);
      message.error("Error uploading additional file");
    }
  };

  const columns2 = [
    {
      title: <span style={table2titleStyle}>Lender Name</span>,
      dataIndex: "lender_name",
      key: "lender_name",

      render: (text, record) => (
        <a
          style={{
            fontWeight: screenWidth > 1200 ? "400" : "600",
            fontFamily: "Manrope, sans-serif",
            fontSize: screenWidth > 1200 ? "11px" : "8px",
          }}
          onClick={() => {
            console.log(record);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: <span style={table2titleStyle}>Loan Amount</span>,
      dataIndex: "offer_amount",
      key: "offer_amount",

      render: (text) => (
        <a
          style={{
            fontWeight: screenWidth > 1200 ? "400" : "600",
            fontFamily: "Manrope, sans-serif",
            fontSize: screenWidth > 1200 ? "11px" : "8px",
          }}
        >
          {text.toLocaleString("en-US", { style: "currency", currency: "SGD" })}
        </a>
      ),
    },
    {
      title: <span style={table2titleStyle}>Interest Rate (Month)</span>,
      dataIndex: "offer_interest_rate",
      key: "offer_interest_rate",
      render: (text) => (
        <a
          style={{
            fontWeight: screenWidth > 1200 ? "400" : "600",
            fontFamily: "Manrope, sans-serif",
            fontSize: screenWidth > 1200 ? "11px" : "8px",
          }}
        >
          {(text * 100).toFixed(1) + "%"}
        </a>
      ),
    },
    {
      title: <span style={table2titleStyle}>Processing Fee</span>,
      dataIndex: "processing_fee",
      key: "processing_fee",
      render: (text) => (
        <a
          style={{
            fontWeight: screenWidth > 1200 ? "400" : "600",
            fontFamily: "Manrope, sans-serif",
            fontSize: screenWidth > 1200 ? "11px" : "8px",
          }}
        >
          {(text * 100).toFixed(1) + "%"}
        </a>
      ),
    },
    {
      title: <span style={table2titleStyle}>Period (Months)</span>,
      dataIndex: "offer_period",
      key: "offer_period",
      render: (text) => (
        <a
          style={{
            fontWeight: screenWidth > 1200 ? "400" : "600",
            fontFamily: "Manrope, sans-serif",
            fontSize: screenWidth > 1200 ? "11px" : "8px",
          }}
        >
          {text + " Months"}
        </a>
      ),
    },
    {
      title: <span style={table2titleStyle}>Status</span>,
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, data) => (
        <a
          style={{
            fontWeight: screenWidth > 1200 ? "400" : "600",
            fontFamily: "Manrope, sans-serif",
            fontSize: screenWidth > 1200 ? "11px" : "8px",
          }}
        >
          {text === "pending_document_request" ? (
            <div>
              <div>Document Requested</div>{" "}
              {data.add_document.map((doc, index) => (
                <>
                  <hr
                    style={{
                      width: "100%",
                      height: "1px",
                      outline: "none",
                      border: "none",
                      background: "lightgrey",
                      margin: "5px 0px",
                    }}
                  />
                  <div key={index}>{doc.document_name}</div>
                </>
              ))}
            </div>
          ) : text === "pending" ? (
            "Pending Acceptance"
          ) : text ? (
            text.charAt(0).toUpperCase() + text.slice(1)
          ) : (
            "-"
          )}
        </a>
      ),
    },

    {
      title: <span style={table2titleStyle}>Upload Additional File</span>,
      key: "action",
      width: "20%",
      fixed: "right",
      render: (text, record) =>
        record.status === "pending_document_request" &&
        record.add_document.some((item) => !item.document) ? (
          <Button
            type="primary"
            onClick={() => showModal(record.loan_id, record.offer_id)}
          >
            Upload
          </Button>
        ) : (
          <div
            style={{
              fontWeight: "400",
              fontFamily: "Manrope, sans-serif",
              fontSize: "11px",
            }}
          >
            -
          </div>
        ),
    },
  ];

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      const selectedLoanIds = selectedRows.map((row) => row.borrower_id);
      onSelectionChange(selectedLoanIds);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      const selectedLoanIds = selectedRows.map((row) => row.borrower_id);
      onSelectionChange(selectedLoanIds);
    },
    getCheckboxProps: (record) => ({
      borrower_id: record.borrower_id,
    }),
  };

  // Define the fetch data function
  async function fetchData() {
    console.log("loan_id useEffect", loan_id);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/viewOfferedLoan?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("Offered loans", responseData["Offered Loans"]);
      setData(responseData["Offered Loans"]);
      setAddDocument(responseData["Offered Loans"][0]["add_document"]); // <- new line
      setOfferId(responseData["Offered Loans"][0].offer_id);
    } catch (error) {
      console.error(error);
    }
  }

  // Call fetchData in useEffect and when a loan is rejected
  useEffect(() => {
    fetchData();
  }, [loan_id]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      console.log(data);
      onRowClick(data[0].loan_id, data[0].offer_id, data[0].status);
    }
  }, [data]);

  return (
    <div style={{ minHeight: "15vh", width: "100%", overflowX: "auto" }}>
      <Table
        columns={columns2}
        dataSource={data}
        pagination={false}
        onRow={(record) => ({
          onClick: () =>
            onRowClick(record.loan_id, record.offer_id, record.status),
        })}
        style={{
          width: "100%",
          cursor: "pointer",
          fontSize: "11px",
          borderRadius: "10px",
        }}
        bordered
      />
      <Modal
        width={isMobile ? "100%" : "40%"}
        open={modalStates[loan_id]}
        onCancel={() =>
          setModalStates((prevStates) => ({
            ...prevStates,
            [loan_id]: false,
          }))
        }
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => handleRequestMoreDocument(loan_id, offerId)}
              disabled={!allUploadsFilled()} // Button is disabled if not all uploads are filled
            >
              Submit
            </Button>
          </div>,
        ]}
        // open={isModalOpen}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        {" "}
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            marginTop: "10px",
          }}
        >
          Please upload all the document request
        </div>
        {addDocument === null
          ? "-"
          : addDocument.map((document, index) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "15px",
                }}
                key={index}
              >
                <div
                  style={{
                    width: isMobile ? "100%" : "20%",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {document.document_name}:
                  </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "55%",
                    marginTop: "15px",
                  }}
                >
                  <div>
                    <Upload
                      fileList={fileLists[document.document_name]}
                      beforeUpload={(file) => {
                        setFileLists((prevFileLists) => ({
                          ...prevFileLists,
                          [document.document_name]: [
                            ...(prevFileLists[document.document_name] || []), // <-- Check if array exists, otherwise use []
                            {
                              uid: "-1",
                              name: file.name,
                              status: "done",
                              url: URL.createObjectURL(file),
                              originFileObj: file, // <-- Preserve the original File object
                            },
                          ],
                        }));
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileLists((prevFileLists) => ({
                          ...prevFileLists,
                          [document.document_name]: prevFileLists[
                            document.document_name
                          ].filter((f) => f.url !== file.url),
                        }));
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        {document.document
                          ? `Replace Document`
                          : `Upload Document`}
                      </Button>
                      {document.document && (
                        <p>Current Document: {document.document}</p>
                      )}
                    </Upload>
                  </div>
                </div>
                {document.document && (
                  <div
                    style={{
                      width: isMobile ? "100%" : "25%",
                      marginTop: "15px",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={(event) =>
                        handleDownloadAdditionalFile(document.document, event)
                      }
                    >
                      Download File
                    </Button>
                  </div>
                )}
              </div>
            ))}
      </Modal>
    </div>
  );
};

export default TableOfferedLoan;
