import React, { useState, createContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import { useHistory } from "react-router-dom";
import { MobileStepper } from "@mui/material";
import GoogleLogin from "react-google-login";
import { useToken } from "antd/es/theme/internal";
import ConfirmPassword from "./ConfirmPassword.jsx";
import google from "../../assets/img/signup-google.png";
import Cookies from "js-cookie";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import { useLocation } from "react-router-dom";

export const TokenContext = createContext();

const Signup = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");
  const [employeeList, setEmployeeList] = useState([]);

  const getEmployee = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/employee/getEmployee`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setEmployeeList(json.employees);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  const responseGoogle = (response) => {
    console.log(response);
    // extract user information from the response
    // and send it to your server
  };

  const [formData, setFormData] = useState({
    email: "",
    user_account_type: "borrower",
    employee_id: null,
  });
  const location = useLocation();

  useEffect(() => {
    if (employeeList && employeeList.length > 0) {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get("ecid");
      console.log("employee list exist");
      if (code) {
        console.log("code exist", code);
        const employeeIndex = employeeList.findIndex(
          (emp) => emp.id === parseFloat(code)
        );
        if (employeeIndex > -1) {
          console.log("index exist");
          setFormData({ ...formData, employee_id: code });
          message.success(
            `Account link from ${employeeList[employeeIndex].name} detected`
          );
        }
      }
    }
  }, [employeeList]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch("https://www.smart-lend.com/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      Cookies.set("token", data.token);
      history.push("/auth/verify-email");
      console.log("token value", data.token);
      // handle successful sign up
    } catch (error) {
      console.log(error);
      setError("The email is already registered.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <React.Fragment>
          <div className="auth-header">
            <h1 className="auth-header-register">
              Welcome to
              <br />
              SmartLend
            </h1>
            <p className="auth-header-subtitle-register">
              Let's get you all set up!
            </p>
          </div>
          <div className="auth-body">
            <form className="auth-form-validation" onSubmit={handleSubmit}>
              <div className="input-field">
                <label htmlFor="email" className="input-label">
                  Email
                </label>
                <input
                  type="email"
                  className="input-control"
                  name="email"
                  placeholder=" example@gmail.com"
                  value={formData.email}
                  onChange={handleChange}
                  autoComplete="off"
                  required
                />
              </div>
              <div className="btn-position">
                {error && <p style={{ color: "red" }}>{error}</p>}

                <button
                  type="submit"
                  style={{
                    background: "#0e5aa5",
                    color: "white",
                    width: "100%",
                    fontSize: 15,
                    padding: "14px 20px",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                  }}
                >
                  Sign Up
                </button>
              </div>
              <div style={{ width: "100%", marginTop: "10px" }}>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid grey",
                    borderRadius: "10px",
                  }}
                >
                  <a
                    href="https://www.smart-lend.com/api/auth/login/google"
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={google}
                      alt="Google Sign-in button"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        maxHeight: "55px",
                      }}
                    />
                  </a>
                </div>
              </div>
            </form>
            {token && <ConfirmPassword token={token} />}
            <p className="text-center-register">
              Already have an account?{" "}
              <Link to={"/auth/signin"} className="link-text-center-signup">
                Sign In
              </Link>
            </p>
            <div style={{ width: 10 }}>
              <MobileStepper
                variant="dots"
                steps={6}
                position="static"
                activeStep={0}
                sx={{
                  maxWidth: 400,
                  flexGrow: 1,
                  marginLeft: 22.5,
                  marginTop: -4.5,
                }}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              width: "95%",
              marginLeft: "2.5%",
            }}
          >
            <div>
              <h1 className="auth-header-title" style={{ fontSize: "45px" }}>
                Welcome to SmartLend
              </h1>
              <p style={{ marginLeft: "1.5rem", color: "grey" }}>
                Let's get you all set up!
              </p>
            </div>
            <div style={{ marginLeft: "1.5rem", width: "calc(100% - 3rem)" }}>
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <div style={{ width: "100%" }}>
                  <label htmlFor="email" className="input-label">
                    Email
                  </label>
                  <input
                    type="email"
                    style={{
                      width: "100%",
                      padding: "9px 12px",
                      borderRadius: "10px",
                      border: "1px solid grey",
                      outline: "none",
                    }}
                    name="email"
                    placeholder=" example@gmail.com"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div style={{ marginTop: "30px" }}>
                  {error && <p style={{ color: "red" }}>{error}</p>}

                  <button
                    type="submit"
                    style={{
                      background: "#0e5aa5",
                      color: "white",
                      width: "100%",
                      fontSize: 15,
                      padding: "14px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    Sign Up
                  </button>
                </div>
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid grey",
                      borderRadius: "10px",
                    }}
                  >
                    <a
                      href="https://www.smart-lend.com/api/auth/login/google"
                      style={{
                        width: "100%",
                      }}
                    >
                      <img
                        src={google}
                        alt="Google Sign-in button"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          maxHeight: "55px",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </form>
              {token && <ConfirmPassword token={token} />}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      marginLeft: "0px",
                      marginRight: "50px",
                    }}
                  >
                    Already have an account? <br />
                    <Link
                      to={"/auth/signin"}
                      className="link-text-center-signup"
                    >
                      Sign In
                    </Link>
                  </p>
                </div>
                <div style={{}}>
                  <MobileStepper
                    variant="dots"
                    steps={6}
                    position="static"
                    activeStep={0}
                    sx={{ maxWidth: 300, flexGrow: 1 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
