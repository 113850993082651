import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import TableAdminLoanOffer from "../../components/TableAdminLoanOffer";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Avatar,
  Upload,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
  Modal,
  Button,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { format } from "date-fns";
import * as Icons from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";

const OfferedLoan = () => {
  const iconLibrary = Icons;
  const iconNames = Object.keys(iconLibrary);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [error, setError] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");
  const [statusChange, setStatusChange] = useState(false);

  const [lenderProfileData, setLenderProfileData] = useState(null);

  const handleGetLenderProfile = async (lender_id) => {
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/user/getLenderProfileDesign",
        {
          method: "POST",
          body: JSON.stringify({ user_id: lender_id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response1.json();
      if (json.design !== null) {
        if (isEqual(JSON.parse(json.design), lenderProfileData)) {
          setLenderProfileData(null);
        } else {
          setLenderProfileData(JSON.parse(json.design));
        }
      } else {
        setLenderProfileData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReceipt = async (loan_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewPaymentReceipt?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading receipt");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "receipt.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  const approveLoan = async (loan_id) => {
    console.log("fetching loan agreement");
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and pad with leading zero if needed
    const day = String(currentDate.getDate()).padStart(2, "0"); // Pad with leading zero if needed

    const loan_receive = `${year}-${month}-${day}`;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/createRepay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            loan_id: loan_id,
            loan_receive: loan_receive,
          }),
        }
      );

      if (response.status === 200) {
        console.log("Loan repayment generated");
        const changingData = [...data];
        changingData[0].status = "ongoing";
        setData(changingData);
        setStatusChange(!statusChange);
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  const handleRowClick = async (record) => {
    const offer_id = record.offer_id;
    setLenderProfileData(null);
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanOffer/viewAllOfferedLoanDetail?offer_id=${offer_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setData(json["Offered Loan Detail"]);

      console.log(json["Offered Loan Detail"]);
    } catch (error) {
      console.error("Error retrieving Offered Loan Detail Data:", error);
    }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log("Selected loan IDs:", selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) throw new Error("No date string provided");

      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error("Invalid date string");

      const formattedDate = format(date, "dd MMMM yyyy");

      return formattedDate;
    } catch (error) {
      console.error(error);
      return "-";
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 16,
    },
    vertical: true,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerActivity = ({ item, icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: "60px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "grey",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
            position: "relative",
          }}
          onClick={() => {
            handleGetLenderProfile(item.lender_id);
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "5px",
            width: "calc(100% - 70px)",
          }}
        >
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              fontSize: "18px",
              textAlign: "left",
              color: "rgba(20, 44, 68, 0.8)",
              margin: "0px 0px 0px 0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px 0px 0px 0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "12px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "rgba(20, 44, 68, 0.8)",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 9"
              gridRow="span 2"
              height="100vh"
              width="100%"
              borderRadius="10px"
              border="0px solid "
              opacity="0.95"
              style={{
                overflowY: "scroll",
                overflowX: "scroll",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                borderRadius="10px"
                margin="20px 20px 20px 20px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                >
                  Offered Loan
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "38px",
                  }}
                >
                  <label
                    htmlFor="sort-by"
                    style={{
                      width: "50%",
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "13px",
                      fontFamily: "Manrope, sans-serif",
                      marginRight: "-5px",
                    }}
                  >
                    Sort by:
                  </label>
                  <div style={{ width: "80%", marginRight: "10px" }}>
                    <select
                      id="sort-by"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "30px",
                        color: "#0E5AA5",
                        backgroundColor: "#ECF0F5",
                        border: "1px solid #3E536733",
                        marginRight: "15px",
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      <option
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                        value="default"
                      >
                        Date & Time
                      </option>
                      <option
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                        value="loan-amount"
                      >
                        Loan Amount
                      </option>
                      <option
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                        value="date"
                      >
                        Date
                      </option>
                    </select>
                  </div>
                  {/* <button onClick={(event) => showModal(selectedBorrowerIds, event)} style={{ backgroundColor: "white", borderRadius: "10px", color: "#B13434", border: "1px solid #B13434", fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px", width:"80%" }}>Warn Borrower</button> */}
                </div>
              </Box>

              <Box>
                <TableAdminLoanOffer
                  onRowClick={handleRowClick}
                  onSelectionChange={handleSelectionChange}
                  statusUpdate={statusChange}
                />
              </Box>
            </Box>

            <Box
              gridColumn="span 3"
              gridRow="span 2"
              height="89vh"
              borderRadius="10px"
              border="0px solid white"
              style={{
                background:
                  "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[910]}
                marginTop="-15px"
                marginBottom="10px"
                p="15px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                >
                  Offered Loan Details
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                backgroundColor="#FFFFFF"
                borderRadius="10px"
                margin="-10px 10px"
                style={{
                  overflowY: "scroll",
                  position: "relative",
                  overflow: "visible",
                }}
                p="15px"
                height="83.7vh"
              >
                <div
                  style={{
                    position: "absolute",
                    background: "white",
                    borderRadius: "10px",
                    padding: "20px",
                    top: 0,
                    right: lenderProfileData ? "calc(100% + 20px)" : "100%",
                    opacity: lenderProfileData ? 1 : 0,
                    width: "400px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                    transition: "0.4s all ease-in-out",
                    zIndex: 200,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "15px",
                    }}
                  >
                    <div>
                      <img
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "50%",
                          background: "lightgrey",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                          objectFit: "cover",
                        }}
                        src={lenderProfileData?.profile?.profile_photo}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          color: "rgb(14, 90, 165)",
                          fontSize: "20px",
                          fontFamily: "Manrope,sans-serif",
                          fontWeight: 800,
                          marginBottom: "5px",
                        }}
                      >
                        {lenderProfileData?.profile?.company_name}
                      </div>
                      {lenderProfileData?.profile?.additionals?.map(
                        (addi, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", gap: "10px" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                color: "rgba(0,0,0,0.6)",
                                marginTop: "-5px",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {addi?.text}
                            </div>
                          </div>
                        )
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          marginLeft: "-5px",
                          marginTop: "5px",
                        }}
                      >
                        {lenderProfileData?.profile?.social?.map(
                          (soci, index) => (
                            <div
                              key={index}
                              style={{
                                padding: "5px",
                                position: "relative",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={iconLibrary[soci?.icon]}
                                style={{
                                  fontSize: 20,
                                  color: "rgb(14, 90, 165)",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (soci.link !== "" && soci.link !== null) {
                                    window.location.href = soci.link;
                                  }
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      background: "grey",
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {lenderProfileData?.content?.map((cont, index) =>
                    cont.type === "text" ? (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          marginTop: cont.mt,
                          marginBottom: cont.mb,
                          textDecoration: cont.underline ? "underline" : "none",
                          fontWeight: cont.fontWeight === 400 ? 400 : 600,
                          fontSize: cont.fontWeight === 400 ? "12px" : "16px",
                          border: "none",
                          outline: "none",
                          color: "rgba(0,0,0,0.6)",
                          width: "100%",
                        }}
                      >
                        {cont?.text}
                      </div>
                    ) : cont?.type === "button" ? (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (cont.url !== "" && cont.url !== null) {
                            window.location.href = cont.url;
                          }
                        }}
                      >
                        <div
                          style={{
                            marginTop: cont.mt,
                            marginBottom: cont.mb,
                            fontWeight: 500,
                            fontSize: "14px",
                            border: "1px solid rgba(0,0,0,0.05)",
                            outline: "none",
                            color: "white",
                            background: "rgb(14, 90, 165)",
                            padding: "7px 30px",
                            borderRadius: "30px",
                          }}
                        >
                          {cont?.text}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          marginTop: cont.mt,
                          marginBottom: cont.mb,
                        }}
                      >
                        <img
                          style={{
                            objectFit: "cover",
                            width: "80%",
                            height: "150px",
                            border: "none",
                            outline: "none",
                          }}
                          src={cont?.url}
                        />
                      </div>
                    )
                  )}
                </div>
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start" // Updated to align to the top
                      colors={colors.grey[910]}
                      // borderBottom={`1px solid ${colors.primary[500]}`}
                      width="100%"
                      textAlign="center"
                      margin="0px"
                      flexDirection="column"
                    >
                      <ContainerActivity
                        item={item}
                        icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
                        title={item.lender_name || "Hello World"}
                        subtitle={""}
                      />
                      <div
                        style={{
                          borderBottom: "2px solid black",
                          width: "100%",
                          opacity: "0.3",
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="1px 1px 1px 0px"
                    flexDirection="column"
                    height="80vh"
                  >
                    <ContainerActivity
                      icon={warn_ico}
                      title="Please Click on Table Row to View Details"
                    />
                  </Box>
                )}
                {!data && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="0px"
                    flexDirection="column"
                  >
                    <ContainerActivity
                      icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                      title="NO VALUE HERE"
                      subtitle="NO VALUE HERE"
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "100%",
                        opacity: "0.3",
                      }}
                    />
                  </Box>
                )}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Loan ID"
                        label={item.loan_id || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Borrower"
                        label={item.borrower_name || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Created At"
                        label={formatDate(item.created_at) || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Amount"
                        label={
                          item.offer_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Interest Rate"
                        label={
                          item.offer_interest_rate
                            ? (item.offer_interest_rate * 100).toFixed(1) + "%"
                            : "-"
                        }
                      />
                    ))}
                </Box>
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Monthly Installment"
                        label={
                          item.offer_monthly_installment.toLocaleString(
                            "en-US",
                            { style: "currency", currency: "SGD" }
                          ) || "-"
                        }
                      />
                    ))}
                </Box> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.offer_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Principal"
                        label={
                          item.offer_principal.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box> */}
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Revenue"
                        label={
                          item.offer_revenue.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <ContainerTransaction
                        key={index}
                        title="Additional Document Requested"
                        label={
                          item.add_document && item.add_document.length > 0
                            ? JSON.parse(item.add_document) // Parse the JSON string into an array
                                .map((doc) =>
                                  doc.document_name
                                    ? doc.document_name
                                    : doc.document_name
                                )
                                .join(", ")
                            : "-"
                        }
                      />
                    ))}
                </Box>
                {/* {data &&
                  data.length > 0 &&
                  data.map((item) =>
                    item.status === "accepted" && item.loan_details.receipt ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        colors={colors.grey[910]}
                        marginLeft="0px"
                        marginTop="0px"
                      >
                        <div
                          style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            marginTop: "12px",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              textAlign: "left",
                              marginLeft: "0px",
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: "Manrope, sans-serif",
                                fontWeight: "600",
                                fontSize: "11px",
                                color: "rgba(0,0,0,0.4)",
                                margin: "0px",
                              }}
                            >
                              Receipt Validation
                            </h3>
                            <div style={{}}>
                              <button
                                onClick={() => fetchReceipt(item.loan_id)}
                                className="all_button"
                                style={{
                                  background: "transparent",
                                  color: "grey",
                                  width: "150px",
                                  border: "2px solid lightgrey",
                                  borderRadius: "50px",
                                  padding: "6px 0px",
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "10px 0px 0px 0px",
                                  cursor: "pointer",
                                }}
                              >
                                View Receipt
                              </button>
                              <button
                                onClick={() => approveLoan(item.loan_id)}
                                className="all_button"
                                style={{
                                  background: "#1557a9",
                                  color: "white",
                                  width: "150px",
                                  border: "none",
                                  borderRadius: "50px",
                                  padding: "6px 0px",
                                  fontSize: "12px",
                                  margin: "10px 0px 0px 0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Approve
                              </button>
                            </div>
                          </div>
                        </div>
                      </Box>
                    ) : null
                  )} */}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Offered Loan
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "10px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Sort by:
                  </label>

                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <TableAdminLoanOffer
                onRowClick={handleRowClick}
                onSelectionChange={handleSelectionChange}
              />
            </div>
            <div
              style={{
                height: "89vh",
                borderRadius: "20px",
                border: "0px solid white",
                background:
                  "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div>
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                  marginTop="10px"
                >
                  Offered Loan Details
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  overflowY: "scroll",
                  height: "83.7vh",
                }}
              >
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start" // Updated to align to the top
                      colors={colors.grey[910]}
                      // borderBottom={`1px solid ${colors.primary[500]}`}
                      width="45%"
                      textAlign="center"
                      margin="1px 1px 1px 30px"
                      flexDirection="column"
                    >
                      <ContainerActivity
                        icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
                        title={item.loan_id || "Hello World"}
                        subtitle={
                          item.type.charAt(0).toUpperCase() +
                            item.type.slice(1) || "-"
                        }
                      />
                      <div
                        style={{
                          borderBottom: "2px solid black",
                          width: "190%",
                          opacity: "0.3",
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="1px 1px 1px 0px"
                    flexDirection="column"
                    height="80vh"
                  >
                    <ContainerActivity
                      icon={warn_ico}
                      title="Please Click on Table Row to View Details"
                    />
                  </Box>
                )}
                {!data && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="45%"
                    textAlign="center"
                    margin="1px 1px 1px 30px"
                    flexDirection="column"
                  >
                    <ContainerActivity
                      icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                      title="NO VALUE HERE"
                      subtitle="NO VALUE HERE"
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "190%",
                        opacity: "0.3",
                      }}
                    />
                  </Box>
                )}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Lender"
                        label={item.lender_name || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Borrower"
                        label={item.borrower_name || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Created At"
                        label={formatDate(item.created_at) || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Amount"
                        label={
                          item.offer_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Interest Rate"
                        label={
                          item.offer_interest_rate
                            ? item.offer_interest_rate * 100 + "%"
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Monthly Installment"
                        label={
                          item.offer_monthly_installment.toLocaleString(
                            "en-US",
                            { style: "currency", currency: "SGD" }
                          ) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.offer_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Principal"
                        label={
                          item.offer_principal.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Revenue"
                        label={
                          item.offer_revenue.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <ContainerTransaction
                        key={index}
                        title="Additional Document Requested"
                        label={
                          item.add_document && item.add_document.length > 0
                            ? item.add_document
                                .map((doc) =>
                                  doc.document_name
                                    ? `${doc.name} - ${doc.document_name}`
                                    : doc.name
                                )
                                .join(", ")
                            : "-"
                        }
                      />
                    ))}
                </Box>
              </div>
              <div>
                <p style={{ color: "transparent" }}>-</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OfferedLoan;
