import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import cookies from "js-cookie";

import {
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  BarChartOutlined,
  PieChartOutlined,
  TeamOutlined,
  MenuOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { Box, IconButton, Typography, useTheme, Collapse } from "@mui/material";
import { tokens } from "../../theme";
import dashboard_pressed from "../../assets/img/Dahsboard_pressed.png";
import dashboard_ico from "../../assets/img/Dashboard.png";
import loan_ico from "../../assets/img/LoanLogo.png";
import loan_pressed from "../../assets/img/LoanLogo_pressed.png";
import analytic_ico from "../../assets/img/Analyticlogo.png";
import analytic_pressed from "../../assets/img/Analyticlogo_pressed.png";
import agreements_ico from "../../assets/img/Agreementlogo.png";
import agreements_pressed from "../../assets/img/Agreementlogo_pressed.png";
import subscription_ico from "../../assets/img/Subscription.png";
import subscription_pressed from "../../assets/img/SubscriptionLogo_pressed.png";
import profile_ico from "../../assets/img/Profile.png";
import profile_pressed from "../../assets/img/Profile_selected.png";
import logout_ico from "../../assets/img/Logout.png";
import LockIcon from "@mui/icons-material/Lock";
import Hamburger from "hamburger-react";
import { fallDown as BurgerMenu } from "react-burger-menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Cookies from "js-cookie";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";

const { Sider } = Layout;
const { SubMenu } = Menu;
const options = { day: "numeric", month: "short", year: "numeric" };

const Item3 = ({
  title,
  title2,
  to,
  icon,
  selected,
  setSelected,
  subMenu,
  icon2,
  onClick, // New prop for onClick function
  disabled, // New prop for disabling button
}) => {
  const match = useRouteMatch(to);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hovered, setHovered] = useState(false);

  return (
    <Menu.Item
      key={title}
      icon={hovered ? icon : icon2}
      style={{
        marginLeft: "15px",
        marginBottom: "20px",
        paddingLeft: "20px",
        background: hovered ? "#0E5AA5" : "#FFFFFF",
        opacity: 1,
        width: "80%",
        color: hovered ? "#FFFFFF" : "#0E5AA5",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
      onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
      onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
      onClick={(event) => onClick(event)} // Attaching onClick here
      disabled={disabled} // Attaching disabled here
    >
      <div>
        <Typography
          style={{
            color: hovered ? "#FFFFFF" : "rgba(0,0,0,0.6)",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            textAlign: "Left",
          }}
        >
          {title}
        </Typography>

        <Typography
          style={{
            color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "13px",
            textAlign: "Left",
            paddingLeft: "20px",
          }}
        >
          {title2}
        </Typography>
      </div>
      <Link to={to} />
    </Menu.Item>
  );
};

const Sidebar = (to) => {
  const match = useRouteMatch(to);
  const [isLoading, setIsLoading] = useState(false);

  const [anyUnread, setAnyUnread] = useState(false);
  const Item = ({
    title,
    title2,
    to,
    icon,
    selected,
    setSelected,
    subMenu,
    icon2,
  }) => {
    const match = useRouteMatch(to);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
      <Menu.Item
        // key={title}
        icon={match ? icon : icon2}
        style={{
          paddingLeft: match ? "24px" : "30px",
          borderLeft: match ? "6px solid #0E5AA5" : "none",
          marginLeft: "-0.03vw",
          borderRadius: "0px 8px 8px 0px",
          height: title ? "35px" : "30px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => setSelected(title)}
      >
        <div>
          {title ? (
            <div style={{ display: "flex", alignItems: "flex-start", gap: 30 }}>
              <p
                style={{
                  color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 700,
                  fontSize: "13px",
                  textAlign: "Left",
                  margin: "0px",
                }}
              >
                {title}
              </p>
              {anyUnread && title === "Messages" ? (
                <div
                  className="blink"
                  style={{
                    position: "absolute",
                    width: 7,
                    height: 7,
                    borderRadius: "50%",
                    background: "#0E5AA5",
                    bottom: 14,
                    right: 14,
                  }}
                />
              ) : null}
            </div>
          ) : (
            <></>
          )}

          {title2 ? (
            <div style={{ display: "flex", alignItems: "flex-start", gap: 30 }}>
              <p
                style={{
                  color: match ? "#0E5AA5" : "rgba(0,0,0,0.6)",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 700,
                  fontSize: "12px",
                  textAlign: "Left",
                  paddingLeft: "35px",
                  margin: "0px",
                }}
              >
                {title2}
              </p>
              {anyUnread && title === "Messages" ? (
                <div
                  className="blink"
                  style={{
                    position: "absolute",
                    width: 7,
                    height: 7,
                    borderRadius: "50%",
                    background: "#0E5AA5",
                    bottom: 14,
                    right: 14,
                  }}
                />
              ) : null}
            </div>
          ) : (
            <></>
          )}
        </div>
        <Link to={to} />
      </Menu.Item>
    );
  };
  const handleLogout = async () => {
    setIsLoading(true);
    cookies.remove("token");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/logout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Logout failed");
      }
      // Clear user session and navigate to login page
      cookies.remove("token");
      window.location.href = "/auth/signin";
    } catch (error) {
      console.error(error);
      window.location.href = "/auth/signin";
    }
    setIsLoading(false);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const toggleCollapsed = () => {
    setIsCollapsed(isCollapsed);
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const history = useHistory();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [subscriptionPlan, setSubsciptionPlan] = useState(
    Cookies.get("subscription")
      ? JSON.parse(JSON.parse(Cookies.get("subscription")))
      : null
  );

  function handleToggleMenu(event) {
    setMenuIsOpen(!menuIsOpen);
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://www.smart-lend.com/api/subcription/getSubscription",
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: "Bearer " + Cookies.get("token"),
  //             Accept: "application/json",
  //           },
  //         }
  //       );
  //       const json = await response.json();
  //       // console.log(JSON.parse(JSON.parse(Cookies.get("subscription"))));
  //       if (json.subscription) {
  //         console.log(JSON.parse(json.subscription));
  //         setSubsciptionPlan(JSON.parse(json.subscription));
  //         Cookies.set("subscription", JSON.stringify(json.subscription));
  //       } else {
  //         setSubsciptionPlan(null);
  //         Cookies.remove("subscription");
  //       }
  //     } catch (error) {
  //       setSubsciptionPlan(null);
  //       Cookies.remove("subscription");
  //     }
  //   };
  //   fetchData();
  // }, []);

  const currentDate = new Date();
  const checkReadId = (data) => {
    let anyUnread = false; // Variable to track if any message is unread

    for (const data1 of data) {
      const { read_id1, read_id2, user_id1, user_id2, receiver } = data1;

      if (user_id1 === receiver) {
        if (read_id1) {
          anyUnread = true;
        }
      } else {
        if (read_id2) {
          anyUnread = true;
        }
      }
    }

    // Set anyUnread based on the condition
    setAnyUnread(anyUnread);
  };

  const retrieveMessage = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getMessage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        const data = [...json.data];
        console.log("updated new data");
        checkReadId(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      retrieveMessage();
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    retrieveMessage();
  }, []);
  return (
    <>
      {screenWidth > 1200 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          style={{
            height: "100vh",
            backgroundColor: "#F1F6F9",
          }}
        >
          {/* LOGO AND MENU ICON */}
          <Menu
            // theme="dark"
            mode="inline"
            defaultSelectedKeys={[selected]}
            selectedKeys={[selected]}
            style={{
              background: "#F1F6F9",
              width: "250px",
            }}
          >
            <Menu.Item style={{ marginTop: "30px" }}>
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="34px 20px 20px 0px"
                >
                  <Typography
                    variant="h3"
                    color={colors.grey[930]}
                    opacity="1"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "800",
                      fontSize: "25px",
                      textAlign: "center",
                    }}
                  >
                    SmartLend.
                  </Typography>
                </Box>
              )}
            </Menu.Item>
            <div style={{ paddingBottom: "32px" }}></div>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={
                <img src={dashboard_pressed} alt="" width={12} height={12} />
              }
              icon2={<img src={dashboard_ico} alt="" width={12} height={12} />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Messages"
              to="/l-messages"
              icon={
                <img src={dashboard_pressed} alt="" width={12} height={12} />
              }
              icon2={<img src={dashboard_ico} alt="" width={12} height={12} />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Loan"
              to="/loan-request-grid"
              icon={<img src={loan_pressed} alt="" width={12} height={12} />}
              icon2={<img src={loan_ico} alt="" width={12} height={12} />}
              selected={selected}
              setSelected={setSelected}
              onClick={() => {
                setSelected("Loan");
              }}
            >
              <Link
                to="/loan"
                style={{ color: "inherit", textDecoration: "none" }}
              />
            </Item>

            <Item
              title2="Loan Request"
              match={"loan-request" || "loan-request-grid"}
              to="/loan-request-grid"
              icon={" "}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title2="Pending Acceptance"
              to="/pending-acceptance"
              icon={" "}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title2="Accepted Loans"
              to="/accepted-loans"
              icon={" "}
              selected={selected}
              setSelected={setSelected}
            />
            {screenWidth > 800 ? (
              <Item
                title2="Delayed Payments"
                to="/delayed-payments"
                icon={" "}
                selected={selected}
                setSelected={setSelected}
              />
            ) : null}
            {/* </SubMenu> */}

            {screenWidth > 800 ? (
              <Item
                title="Analytics"
                to="/analytics"
                icon={
                  <img src={analytic_pressed} alt="" width={17} height={17} />
                }
                icon2={<img src={analytic_ico} alt="" width={17} height={17} />}
                selected={selected}
                setSelected={setSelected}
              />
            ) : null}
            <Item
              title="Agreements"
              to="/agreements"
              icon={
                <img src={agreements_pressed} alt="" width={17} height={17} />
              }
              icon2={<img src={agreements_ico} alt="" width={17} height={17} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="My Profile"
              to="/l-profile"
              icon={
                <AccountBoxOutlinedIcon
                  style={{ fontSize: 18, marginLeft: "-2px", color: "rgb(14, 90, 165)" }}
                />
              }
              icon2={
                <AccountBoxOutlinedIcon
                  style={{ fontSize: 18, marginLeft: "-2px", color: "rgba(0,0,0,0.4)" }}
                />
              }
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Subscription"
              to="/subscription"
              icon={
                <img src={subscription_pressed} alt="" width={17} height={17} />
              }
              icon2={
                <img src={subscription_ico} alt="" width={17} height={17} />
              }
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Menu>
          <Menu
            mode="inline"
            defaultSelectedKeys={[selected]}
            selectedKeys={[selected]}
            style={{
              background: "#F1F6F9",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              height: "100%",
            }}
          >
            {/* <div
              style={{
                marginLeft: "15px",
                marginBottom: "10px",
                paddingLeft: "20px",
                background: "#FFFFFF",
                opacity: 1,
                width: "80%",
                color: "lightgrey",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",

                padding: "7px 25px",
                borderRadius: "10px",
                textAlign: "left",
                position: "relative",
                height: "80px",
                lineHeight: "20px",
              }}
            >
              {subscriptionPlan ? (
                <div
                  className="blink"
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 15,
                    background:
                      subscriptionPlan.validity &&
                      new Date(subscriptionPlan.validity) < currentDate
                        ? "red"
                        : "green",
                    boxShadow:
                      subscriptionPlan.validity &&
                      new Date(subscriptionPlan.validity) < currentDate
                        ? "0px 0px 10px red"
                        : "0px 0px 10px green",
                    height: "7px",
                    width: "7px",
                    borderRadius: "10px",
                  }}
                ></div>
              ) : null}
              <div style={{ margin: "5px 0px 0px 0px" }}>Subscription</div>
              <div
                style={{
                  width: "100%",
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  margin: "0px 0px 0px 0px",
                }}
              >
                {subscriptionPlan
                  ? `${subscriptionPlan.plan} Plan`
                  : "You have no plans"}
              </div>
              {subscriptionPlan ? (
                subscriptionPlan.validity &&
                new Date(subscriptionPlan.validity) < currentDate ? (
                  <div
                    style={{
                      color: "red",
                      margin: "18px 0px 0px 0px",
                      fontSize: "12px",
                      fontWeight: "600",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Subscription Expired
                  </div>
                ) : (
                  <div
                    style={{
                      color: "grey",
                      margin: "18px 0px 0px 0px",
                      fontSize: "12px",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    Valid till:{" "}
                    {new Date(subscriptionPlan.validity).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </div>
                )
              ) : null}
            </div> */}
            {/* <Item
              title="Profile"
              to="/profile"
              icon={
                <img
                  className="odd_icon_setting"
                  src={profile_pressed}
                  alt=""
                  width={12}
                  height={12}
                />
              }
              icon2={
                <img
                  className="odd_icon_setting"
                  src={profile_ico}
                  alt=""
                  width={12}
                  height={12}
                />
              }
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item3
              title="Log Out"
              icon={
                <LockIcon
                  className="colored_white_icon"
                  style={{ fontSize: "18px", marginLeft: "-3px" }}
                />
              }
              icon2={
                <LockIcon
                  style={{
                    fontSize: "18px",
                    marginLeft: "-3px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                />
              }
              to="#"
              onClick={(event) => handleLogout(event)}
              disabled={isLoading}
            />
          </Menu>
        </Box>
      ) : (
        <BurgerMenu
          left
          width={"100%"}
          isOpen={menuIsOpen}
          customCrossIcon={false}
          customBurgerIcon={
            <Hamburger
              toggled={menuIsOpen}
              toggle={handleToggleMenu}
              size={24}
            />
          }
        >
          <div>
            <div
              style={{
                marginTop: "20px",
                fontSize: "35px",
                fontWeight: "800",
                color: "#142C44CC",
              }}
            >
              SmartLend
            </div>
            <div
              onClick={() => setMenuIsOpen(false)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <div
                onClick={() => {
                  history.push("/dashboard");
                  window.scrollTo(0, 0);
                }}
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Dashboard
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => setMenuIsOpen(false)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <div
                onClick={() => {
                  history.push("/l-messages");
                  window.scrollTo(0, 0);
                }}
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Message
              </div>
              <NavigateNextIcon />
            </div>
            <hr
              style={{
                width: "100%",
                height: "0.01rem",
                color: "black",
                margin: "5px 0px",
              }}
            />
            <div
              onClick={() => {
                history.push("/loan-request-grid");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px",
                }}
              >
                Loan
              </div>
              <NavigateNextIcon />
            </div>

            <div
              onClick={() => {
                history.push("/loan-request-grid");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Loan Request
              </div>
              <NavigateNextIcon />
            </div>

            <div
              onClick={() => {
                history.push("/accepted-loans");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Accepted Loan
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/pending-acceptance");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Pending Acceptance
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/delayed-payments");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Delayed Payments
              </div>
              <NavigateNextIcon />
            </div>
            <div
              onClick={() => {
                history.push("/agreements");
                window.scrollTo(0, 0);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "rgba(0,0,0,0.7)",
                  textDecoration: "none",
                  padding: "5px 0px 5px 25px",
                }}
              >
                Agreements
              </div>
              <NavigateNextIcon />
            </div>
            {screenWidth > 800 ? (
              <>
                <div
                  onClick={() => {
                    history.push("/analytics");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      textDecoration: "none",
                      padding: "5px 0px",
                    }}
                  >
                    Analytics
                  </div>
                  <NavigateNextIcon />
                </div>
                <hr
                  style={{
                    width: "100%",
                    height: "0.01rem",
                    color: "black",
                    margin: "5px 0px",
                  }}
                />{" "}
              </>
            ) : null}
            {screenWidth > 800 ? (
              <>
                {/* <hr
                  style={{
                    width: "100%",
                    height: "0.01rem",
                    color: "black",
                    margin: "5px 0px",
                  }}
                />
                <div
                  onClick={() => {
                    history.push("/subscription");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      textDecoration: "none",
                      padding: "5px 0px",
                    }}
                  >
                    Subscriptions
                  </div>
                  <NavigateNextIcon />
                </div> */}
              </>
            ) : null}

            <div
              style={{
                width: "90%",
                textAlign: "center",
                padding: "15px 20px",
                background: "#51A22E",
                color: "white",
                marginTop: "35px",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={(event) => {
                handleLogout(event);
                history.push("#");
                window.scrollTo(0, 0);
              }}
            >
              Logout
            </div>
          </div>
        </BurgerMenu>
      )}
    </>
  );
};
export default Sidebar;
