import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import macbook_borrower from "../../../assets/img/macbook_borrower.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// import { useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import SgIcon from "./img/197496.png";
import CloseIcon from "@mui/icons-material/Close";
import singpassLogo from "./img/singpass_logo.png";
import myInfoLogo from "./img/myinfo_logo.png";
import bank_image from "../../../assets/img/bank_image.png";
import IphoneImage from "./img/iphone_mockup.png";
import verified from "../../../assets/img/verified.png";
import disbursement from "../../../assets/img/macbook_mockup2.png";
import mutual_agreement from "../../../assets/img/mutual_agreement.png";
import one from "../../../assets/img/one.png";
import two from "../../../assets/img/two.png";
import three from "../../../assets/img/three.png";
import four from "../../../assets/img/four.png";
import { Modal, Button, message } from "antd";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";
import HomeHeader from "./Headesr.jsx";
import singpassmobilepage from "../../../assets/img/Singpasslogin-screenshot.png";
import platformFee from "../src/img/0platformfee.png";
import coinShadow from "../src/img/coin shadow.png";
import StarIcon from "@mui/icons-material/Star";
import ArticleIcon from "@mui/icons-material/Article";
import youImage from "../src/img/you.png";
import thinkingImage from "../src/img/thinking.png";
import icon1 from "../src/img/icon1.png";
import icon2 from "../src/img/icon2.png";
import icon3 from "../src/img/icon3.png";
import icon4 from "../src/img/icon4.png";
import icon5 from "../src/img/icon5.png";
import icon6 from "../src/img/icon6.png";
import icon7 from "../src/img/icon7.png";
import icon8 from "../src/img/icon8.png";
import icon9 from "../src/img/icon9.png";
import icon10 from "../src/img/icon10.png";
import icon11 from "../src/img/icon11.png";
import icon12 from "../src/img/icon12.png";
import icon13 from "../src/img/icon13.png";
import icon14 from "../src/img/icon14.png";
import icon15 from "../src/img/icon15.png";

const Homepage = () => {
  // const navigate = useHistory();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
    }, 4500);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);
  const [pageloaded, setpageloaded] = useState(false);
  const imagesForIcon = [
    { img: icon1 },
    { img: icon2 },
    { img: icon3 },
    { img: icon4 },
    { img: icon5 },
    { img: icon6 },
    { img: icon7 },
    { img: icon8 },
    { img: icon9 },
    { img: icon10 },
    { img: icon11 },
    { img: icon12 },
    { img: icon13 },
    { img: icon14 },
  ];

  const faqs = [
    {
      id: 1,
      title: "How do I apply for a Business Loan?",
      answer: (
        <p>
          Get started by clicking on the sign up button above and it will take
          you through a step by step process to sign up for a smartlend account.
          Once you’re done with your sign up, you can head on into the dashboard
          and click on the apply loan button located on the menu bar on the
          left.
        </p>
      ),
    },
    {
      id: 2,
      title: "Can anyone apply for a Business Loan?",
      answer: (
        <p>
          Currently, only Singapore locally incorporated LLP (Limited Liability
          Partnership) or Pte. Ltd. (Private Limited) entities are eligible to
          apply for a business loan. However, if you have a different type of
          corporate entity, please reach out to us at support@smart-lend.com,
          and we'll explore how we might be able to assist you.
        </p>
      ),
    },
    {
      id: 3,
      title: "Why can’t I apply for a loan?",
      answer: (
        <p>
          If you're unable to apply for a loan, it usually means that you
          haven't synchronized your Corp Pass account with your Singpass yet.
          This synchronization process is necessary for accessing and applying
          for loans.
        </p>
      ),
    },
    {
      id: 4,
      title: "Can I speak to the lenders of the platform?",
      answer: (
        <p>
          Yes, you can speak to the lenders on our platform, but only if the
          lender has expressed interest in your loan request. In such cases, a
          chatbot request feature will be enabled on our platform. However,
          please note that we do not provide users with the private contact
          information of our lenders due to our privacy policies.
        </p>
      ),
    },
    {
      id: 5,
      title: "How can I talk to my private lender?",
      answer: (
        <p>
          If you would like to reach your lender on the loan offer that they
          have offered, you can click on the offer in the requested loan page,
          select the lender, and proceed to click on the negotiation button
          where you’ll be taken to the messaging page where you can start a
          conversation with your lender.
        </p>
      ),
    },
    {
      id: 6,
      title: "My loan has disappeared, where did it go?",
      answer: (
        <p>
          If you can't find your loan, don't worry! Once a loan is approved by
          both the borrower and lender, it is moved to the accepted loan page.
          Here, you can check the status of your loan, which will indicate the
          stage it's in before being disbursed. After your loan is disbursed, it
          will be moved to the "loan in progress" page. Here, you'll find
          detailed information about your loan and can make payments
          accordingly.
        </p>
      ),
    },
    {
      id: 7,
      title: "How long does it take for me to get a loan approved?",
      answer: (
        <div>
          <p>
            The approval time for your loan depends on several factors. First,
            you need to receive an offer from a lender. At Smart-Lend, we have a
            wide network of lenders who review your financial profile and the
            documents you've provided before making an offer. This process
            usually takes between 1 to 5 working days.
          </p>
          <p>
            Keep an eye out for our upcoming Service Guarantee feature! Soon,
            borrowers will enjoy even faster turnaround times, with approvals
            possible within 24 hours. Stay tuned for more details when we
            introduce this new feature.
          </p>
        </div>
      ),
    },
  ];

  const [isCollapsed, setIsCollapsed] = useState(null);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const FAQItem = ({ id, title, answer, index }) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "0.01rem solid #d5dee0",
            cursor: "pointer",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
          onClick={() => {
            console.log(index);
            if (isCollapsed === index) {
              setIsCollapsed(null);
            } else {
              setIsCollapsed(index);
            }
          }}
          className="mui-button"
        >
          <div
            variant="subtitle1"
            style={{
              fontWeight: "600",
              fontSize: screenWidth > 1200 ? "17px" : "14px",
            }}
          >
            {title}
          </div>
          <IconButton
            onClick={() => {
              console.log(index);
              if (isCollapsed === index) {
                setIsCollapsed(null);
              } else {
                setIsCollapsed(index);
              }
            }}
          >
            <ChevronRight
              style={{
                rotate: isCollapsed !== index ? "0deg" : "90deg",
                transition: "all 0.3s ease-in-out",
              }}
            />
          </IconButton>
        </div>
        {isCollapsed === index ? (
          <div
            style={{
              marginLeft: "40px",
              paddingTop: "8px",
              fontSize: screenWidth > 1200 ? "13px" : "12px",
              fontWeight: "400",
              paddingBottom: "10px",
            }}
          >
            {answer}
          </div>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    setpageloaded(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [emailSubscription, setEmailSubscription] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailSubscription = async (event) => {
    event.preventDefault();

    try {
      let item = {
        email: emailSubscription,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/guest/subscribeEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      let data;
      if (
        response.ok &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        data = await response.json();
        // Display success message
        message.success(data.message);
        setEmailSubscription("");
      } else {
        // If response is not ok or not JSON, consider it as an email already subscribed case
        message.error("Your email already subscribed!");
        // throw new Error('Your email already subscribed!');
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
      message.error(error);
    }
  };
  // Simple email validation function
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle input change
  const handleInputChange = (e) => {
    const email = e.target.value;
    setEmailSubscription(email);
    setIsEmailValid(validateEmail(email));
  };

  const [privacyBar, setPrivacybar] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
    if (screenWidth > 1200) {
      setPrivacybar(true);
    }
  }, []);
  useEffect(() => {
    if (screenWidth > 1200) {
      setPrivacybar(false);
    }
  }, [screenWidth]);

  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      {
        threshold: 0, // Adjust this threshold as needed
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  const [isInView1, setIsInView1] = useState(false);
  const sectionRef1 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView1(true);
        }
      },
      {
        threshold: 0, // Adjust this threshold as needed
      }
    );

    if (sectionRef1.current) {
      observer.observe(sectionRef1.current);
    }

    return () => {
      if (sectionRef1.current) {
        observer.unobserve(sectionRef1.current);
      }
    };
  }, []);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [newProductLoc, setNewProductLoc] = useState({
    px: 0,
    py: 0,
    rx: 0,
    ry: 0,
  });

  const [var1, setVar1] = useState(0);
  const [var2, setVar2] = useState(0);
  const [var3, setVar3] = useState(0);
  const [var4, setVar4] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomValue = () => Math.floor(Math.random() * 5) + 1; // Generates random value between 1 and 5
      const addOrReduce = () => (Math.random() < 0.5 ? -1 : 1); // Randomly choose to add or reduce

      // Function to update each variable and keep them within -10 and 10
      const updateValue = (prev) => {
        let newValue = prev + randomValue() * addOrReduce();
        if (newValue > 10) newValue = 10;
        if (newValue < -10) newValue = -10;
        return newValue;
      };

      // Update all four variables
      setVar1((prevVar1) => updateValue(prevVar1));
      setVar2((prevVar2) => updateValue(prevVar2));
      setVar3((prevVar3) => updateValue(prevVar3));
      setVar4((prevVar4) => updateValue(prevVar4));
    }, 4300); // Updates every second

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  useEffect(() => {
    const randomValue = () => Math.floor(Math.random() * 5) + 1; // Generates random value between 1 and 5
    const addOrReduce = () => (Math.random() < 0.5 ? -1 : 1); // Randomly choose to add or reduce

    // Function to update each variable and keep them within -10 and 10
    const updateValue = (prev) => {
      let newValue = prev + randomValue() * addOrReduce();
      if (newValue > 10) newValue = 10;
      if (newValue < -10) newValue = -10;
      return newValue;
    };

    // Update all four variables
    setVar1((prevVar1) => updateValue(prevVar1));
    setVar2((prevVar2) => updateValue(prevVar2));
    setVar3((prevVar3) => updateValue(prevVar3));
    setVar4((prevVar4) => updateValue(prevVar4));
  }, []);

  return (
    <div
      style={{ width: "100%", overflowX: "hidden", position: "relative" }}
      onMouseMove={(e) => {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        // console.log(`Mouse X: ${mouseX}, Mouse Y: ${mouseY}`);
        setMousePosition({ x: mouseX, y: mouseY });
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: mousePosition.y,
          left: mousePosition.x,

          width: 350,
          height: 350,
          background:
            "radial-gradient(circle, rgba(45,128,195, 0.35) 0%, rgba(45,128,195, 0.2) 20%, rgba(45,128,195, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
          opacity: 0.5,
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "10%",
          left: 0,
          width: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          height: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          background:
            "radial-gradient(circle, rgba(45,128,195, 0.4) 0%, rgba(45,128,195, 0.2) 20%, rgba(45,128,195, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "100%",
          left: "30%",
          width: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          height: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          background:
            "radial-gradient(circle, rgba(237,182,0, 0.4) 0%, rgba(237,182,0, 0.2) 20%, rgba(237,182,0, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
          opacity: 0.6,
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "80%",
          left: "100%",
          width: `calc(300vh - ${mousePosition.x * 1}px)`,
          height: `calc(300vh - ${mousePosition.x * 1}px)`,
          background:
            "radial-gradient(circle, rgba(45,128,195, 0.4) 0%, rgba(45,128,195, 0.2) 20%, rgba(45,128,195, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "0%",
          left: "70%",
          width: `calc(300vh - ${mousePosition.x * 1}px)`,
          height: `calc(300vh - ${mousePosition.x * 1}px)`,
          background:
            "radial-gradient(circle, rgba(237,182,0, 0.4) 0%, rgba(237,182,0, 0.2) 20%, rgba(237,182,0, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
          opacity: 0.6,
        }}
      />
      <div
        style={{
          background: "white",
          position: "fixed",
          width: "100%",
          height: "40px",
          display: privacyBar ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: "49",
          background: "rgb(37, 97, 153)",
        }}
      >
        <div
          style={{
            marginLeft: "20px",
            color: "rgba(0,0,0,0.7)",
            fontSize: "14px",
            color: "white",
          }}
        >
          Information is collected in accordance with our Privacy Policy. By
          continuing you agree to our terms.
        </div>
        <CloseIcon
          style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
          onClick={() => setPrivacybar(false)}
        />
      </div>
      {/* Header bar */}
      <div
        style={{
          position: "fixed",
          background: "rgba(256,256,256,0.4)",
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
          width: "100%",
          top: privacyBar ? "40px" : "0px",
          zIndex: 201,
          backdropFilter: "blur(20px)",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <div style={{ width: "30%", display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "35px",
                color: "#256199",
              }}
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              SmartLend.
            </div>
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
              style={{ fontWeight: "700", color: "#256199" }}
            >
              Home
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/whysmartlend");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Why SmartLend?
            </div>

            <div
              className="menu_button"
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Partner With Us
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              News & Events
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Contact Us
            </div>
            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: "5px 40px",
                borderRadius: "15px",
              }}
            >
              Sign In
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#256199",
                border: "none",
                background: "white",
                padding: "5px 15px 5px 5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={SgIcon}
                style={{ height: "18px", marginRight: "10px" }}
              />
              <div>SG</div>
            </div>
          </div>
        </div> */}
        <HomeHeader />
      </div>

      {/* First section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "110%",
          marginTop: screenWidth > 1200 ? "90px" : "140px",
          height: screenWidth > 1200 ? "90vh" : "70vh",
          marginLeft: "-5%",
          transform: "scale(1.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: screenWidth > 1200 ? "column" : "column",
            justifyContent: "space-between",
            width: "80%",
            height: "100%",
            position: "relative",
          }}
        >
          <div style={{ display: screenWidth > 1200 ? "default" : "none" }}>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(40% + ${var2 * 6}px)`
                    : `calc(45% + ${var2 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(23% + ${var3 * 6}px)`
                    : `calc(12% + ${var3 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(-30deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 70 : 200,
                height: currentIndex === 1 ? 70 : 200,
              }}
            >
              <img
                src={imagesForIcon[0].img}
                style={{
                  width: currentIndex === 1 ? 70 : 200,
                  height: currentIndex === 1 ? 70 : 200,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(30% + ${var1 * 6}px)`
                    : `calc(28% + ${var1 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(32% + ${var3 * 6}px)`
                    : `calc(0% + ${var3 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(20deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 40 : 80,
                height: currentIndex === 1 ? 40 : 80,
              }}
            >
              <img
                src={imagesForIcon[1].img}
                style={{
                  width: currentIndex === 1 ? 40 : 80,
                  height: currentIndex === 1 ? 40 : 80,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(60% + ${var3 * 6}px)`
                    : `calc(80% + ${var3 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(29% + ${var1 * 6}px)`
                    : `calc(19% + ${var1 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(20deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 40 : 80,
                height: currentIndex === 1 ? 40 : 80,
              }}
            >
              <img
                src={imagesForIcon[2].img}
                style={{
                  width: currentIndex === 1 ? 40 : 80,
                  height: currentIndex === 1 ? 40 : 80,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(30% + ${var2 * 6}px)`
                    : `calc(20% + ${var2 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(24% + ${var2 * 6}px)`
                    : `calc(8% + ${var2 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(35deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 35 : 80,
                height: currentIndex === 1 ? 35 : 80,
              }}
            >
              <img
                src={imagesForIcon[3].img}
                style={{
                  width: currentIndex === 1 ? 35 : 80,
                  height: currentIndex === 1 ? 35 : 80,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(53% + ${var1 * 6}px)`
                    : `calc(75% + ${var1 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(24% + ${var3 * 6}px)`
                    : `calc(8% + ${var3 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(55deg)"
                    : "translate(-50%, -50%) rotate(90deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 23 : 65,
                height: currentIndex === 1 ? 23 : 65,
              }}
            >
              <img
                src={imagesForIcon[4].img}
                style={{
                  width: currentIndex === 1 ? 23 : 65,
                  height: currentIndex === 1 ? 23 : 65,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(26% + ${var1 * 6}px)`
                    : `calc(15% + ${var1 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(38% + ${var2 * 6}px)`
                    : `calc(20% + ${var2 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(-35deg)"
                    : "translate(-50%, -50%) rotate(10deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 48 : 110,
                height: currentIndex === 1 ? 48 : 110,
              }}
            >
              <img
                src={imagesForIcon[5].img}
                style={{
                  width: currentIndex === 1 ? 48 : 110,
                  height: currentIndex === 1 ? 48 : 110,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 1
                    ? `calc(60% + ${var3 * 6}px)`
                    : `calc(75% + ${var3 * 6}px)`,
                left:
                  currentIndex === 1
                    ? `calc(35% + ${var2 * 6}px)`
                    : `calc(30% + ${var2 * 6}px)`,
                opacity: currentIndex === 1 ? 0.8 : 1,
                transform:
                  currentIndex === 1
                    ? "translate(-50%, -50%) rotate(85deg)"
                    : "translate(-50%, -50%) rotate(20deg)",
                transition:
                  currentIndex === 1
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 1 ? 23 : 65,
                height: currentIndex === 1 ? 23 : 65,
              }}
            >
              <img
                src={imagesForIcon[6].img}
                style={{
                  width: currentIndex === 1 ? 23 : 65,
                  height: currentIndex === 1 ? 23 : 65,

                  transition:
                    currentIndex === 1
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(40% + ${var1 * 6}px)`
                    : `calc(45% + ${var1 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(77% + ${var1 * 6}px)`
                    : `calc(88% + ${var1 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(-30deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 70 : 200,
                height: currentIndex === 0 ? 70 : 200,
              }}
            >
              <img
                src={imagesForIcon[7].img}
                style={{
                  width: currentIndex === 0 ? 70 : 200,
                  height: currentIndex === 0 ? 70 : 200,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(30% + ${var3 * 6}px)`
                    : `calc(28% + ${var3 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(68% + ${var3 * 6}px)`
                    : `calc(100% + ${var3 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(20deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 40 : 80,
                height: currentIndex === 0 ? 40 : 80,
              }}
            >
              <img
                src={imagesForIcon[8].img}
                style={{
                  width: currentIndex === 0 ? 40 : 80,
                  height: currentIndex === 0 ? 40 : 80,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(60% + ${var2 * 6}px)`
                    : `calc(80% + ${var2 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(71% + ${var3 * 6}px)`
                    : `calc(81% + ${var3 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(20deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 40 : 80,
                height: currentIndex === 0 ? 40 : 80,
              }}
            >
              <img
                src={imagesForIcon[9].img}
                style={{
                  width: currentIndex === 0 ? 40 : 80,
                  height: currentIndex === 0 ? 40 : 80,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(30% + ${var3 * 6}px)`
                    : `calc(20% + ${var3 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(76% + ${var2 * 6}px)`
                    : `calc(92% + ${var2 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(35deg)"
                    : "translate(-50%, -50%) rotate(-10deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 35 : 80,
                height: currentIndex === 0 ? 35 : 80,
              }}
            >
              <img
                src={imagesForIcon[10].img}
                style={{
                  width: currentIndex === 0 ? 35 : 80,
                  height: currentIndex === 0 ? 35 : 80,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(53% + ${var1 * 6}px)`
                    : `calc(75% + ${var1 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(76% + ${var3 * 6}px)`
                    : `calc(92% + ${var3 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(55deg)"
                    : "translate(-50%, -50%) rotate(90deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 23 : 65,
                height: currentIndex === 0 ? 23 : 65,
              }}
            >
              <img
                src={imagesForIcon[11].img}
                style={{
                  width: currentIndex === 0 ? 23 : 65,
                  height: currentIndex === 0 ? 23 : 65,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(26% + ${var3 * 6}px)`
                    : `calc(15% + ${var3 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(62% + ${var1 * 6}px)`
                    : `calc(80% + ${var1 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(-35deg)"
                    : "translate(-50%, -50%) rotate(10deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 48 : 110,
                height: currentIndex === 0 ? 48 : 110,
              }}
            >
              <img
                src={imagesForIcon[12].img}
                style={{
                  width: currentIndex === 0 ? 48 : 110,
                  height: currentIndex === 0 ? 48 : 110,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top:
                  currentIndex === 0
                    ? `calc(62% + ${var3 * 6}px)`
                    : `calc(73% + ${var3 * 6}px)`,
                left:
                  currentIndex === 0
                    ? `calc(65% + ${var3 * 6}px)`
                    : `calc(67% + ${var3 * 6}px)`,
                opacity: currentIndex === 0 ? 0.8 : 1,
                transform:
                  currentIndex === 0
                    ? "translate(-50%, -50%) rotate(85deg)"
                    : "translate(-50%, -50%) rotate(20deg)",
                transition:
                  currentIndex === 0
                    ? "0.3s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out"
                    : "0.4s all ease-in-out, transform 6s ease-in-out, top 6s ease-in-out, left 6s ease-in-out",
                width: currentIndex === 0 ? 23 : 65,
                height: currentIndex === 0 ? 23 : 65,
              }}
            >
              <img
                src={imagesForIcon[13].img}
                style={{
                  width: currentIndex === 0 ? 23 : 65,
                  height: currentIndex === 0 ? 23 : 65,

                  transition:
                    currentIndex === 0
                      ? "0.3s all ease-in-out, transform 4s ease-in-out"
                      : "0.4s all ease-in-out, transform 4s ease-in-out",
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "calc(75vh - 50px)",
              right: pageloaded ? "50%" : "-10%",
              transition: "2s all ease-in-out",
              zIndex: 100,
              display: screenWidth > 1200 ? "default" : "none",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: 0,
                left: 5,
                transform: "translate(-50%, -50%)",
                height: "250px",
                width: "250px",
                opacity: 0.8,
              }}
              src={coinShadow}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "calc(75vh - 50px)",
              right: pageloaded ? "50%" : "-10%",
              transform: pageloaded ? "rotate(0deg)" : "rotate(720deg)",
              transition: "2s all ease-in-out",
              zIndex: 100,
              display: screenWidth > 1200 ? "default" : "none",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
                height: "200px",
                width: "200px",
              }}
              src={platformFee}
            />
          </div>
          <div
            style={{
              width: screenWidth > 1200 ? "100%" : "100%",
              textAlign: "left",
              marginLeft: pageLoad ? "0px" : "-300px",
              opacity: pageLoad ? "1" : "0",
              transition: "all 0.8s ease-in-out",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              position: "absolute",
              top: screenWidth > 1200 ? "45%" : "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "100%" : "70%",
                marginLeft: screenWidth > 1200 ? 0 : "15%",
              }}
            >
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "40px",
                  fontWeight: "800",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: screenWidth > 1200 ? "55px" : "40px",
                }}
              >
                Need a
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "70px" : "35px",
                  fontWeight: "800",
                  color: "#256199",
                  lineHeight: screenWidth > 1200 ? "55px" : "40px",
                  position: "absolute",
                  transition: "0.3s all ease-in-out",
                  opacity: currentIndex === 0 ? 1 : 0,
                  width: "100%",
                  marginLeft:
                    screenWidth > 1200
                      ? currentIndex === 2
                        ? "-200px"
                        : currentIndex === 1
                        ? "200px"
                        : "0px"
                      : currentIndex === 2
                      ? "-200px"
                      : currentIndex === 1
                      ? "200px"
                      : "-50px",
                }}
              >
                Business Loan,
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "70px" : "35px",
                  fontWeight: "800",
                  color: "#256199",
                  lineHeight: screenWidth > 1200 ? "55px" : "40px",
                  position: "absolute",
                  transition: "0.3s all ease-in-out",
                  opacity: currentIndex === 1 ? 1 : 0,
                  width: "100%",
                  marginLeft:
                    screenWidth > 1200
                      ? currentIndex === 0
                        ? "-200px"
                        : currentIndex === 2
                        ? "200px"
                        : "0px"
                      : currentIndex === 0
                      ? "-200px"
                      : currentIndex === 2
                      ? "200px"
                      : "-50px",
                }}
              >
                Working Capital,
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "20px",
                  fontWeight: "800",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: screenWidth > 1200 ? "50px" : "20px",
                  marginTop: screenWidth > 1200 ? "70px" : "45px",
                }}
              >
                We bring the{" "}
                <span style={{ color: "#256199", fontWeight: 800 }}>
                  Capital
                </span>{" "}
                to you.
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "14px" : "10px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  marginTop: screenWidth > 1200 ? "30px" : "20px",
                }}
              >
                Smart financing for smart businesses. Find your ideal lender
                with SmartLend.
              </div>
              {/* <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  marginTop: "10px",
                }}
              >
                Elevate your business's financial potential with SmartLend.
                Simplify your application process seamlessly, unlocking a
                variety of loan possibilities with minimal data input. Your
                choices, your empowerment – and all at no cost. Apply now to
                define the success of your business.
              </div> */}
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "#256199",
                    border: "2px solid #256199",
                    background: "transparent",
                    fontSize: screenWidth > 1200 ? "17px" : "10px",
                    padding: screenWidth > 1200 ? "5px 25px" : "7px 20px",
                    margin:
                      screenWidth > 1200
                        ? "0px 20px 0px 0px"
                        : "0px 10px 0px 0px",
                    borderRadius: "30px",
                    fontWeight: "800",
                  }}
                >
                  Apply now
                </div>
                <div
                  className="menu_button"
                  onClick={() => {
                    window.location.href = "https://wa.me/6588288147";
                  }}
                  style={{
                    background: "#256199",
                    color: "white",
                    fontSize: screenWidth > 1200 ? "17px" : "10px",
                    padding: screenWidth > 1200 ? "5px 25px" : "7px 20px",
                    margin: screenWidth > 1200 ? "0px 20px 0px 0px" : "0px",
                    borderRadius: "30px",
                    fontWeight: "800",
                  }}
                >
                  Talk to an Expert
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              border: "5px solid rgb(37, 97, 153)",
              background: "rgba(256,256,256,0.2)",
              borderRadius: 10,
              marginTop: screenWidth > 1200 ? 35 : 20,
              padding: 15,
              color: "rgba(0,0,0,0.7)",
              width: "280px",
              position: "absolute",
              zIndex: 300,
              bottom: screenWidth > 1200 ? 70 : 40,
              right: screenWidth > 1200 ? 30 : -20,
              transform: "scale(0.7)",
              backdropFilter: "blur(20px)",
            }}
          >
            <div
              style={{
                fontWeight: 800,
                color: "rgb(37, 97, 153)",
                fontSize: 20,
              }}
            >
              Now enabled by:
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={myInfoLogo}
                style={{ height: 70, width: 100, objectFit: "cover" }}
              />
              <div
                style={{ height: 50, width: 2, background: "rgb(37, 97, 153)" }}
              />
              <img
                src={singpassLogo}
                style={{ height: 70, width: 100, objectFit: "cover" }}
              />
            </div>
          </div>
          {/* {screenWidth > 1200 ? (
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={macbook_borrower}
                style={{
                  objectFit: "contain",
                  width: "150%",
                  opacity: pageLoad ? "1" : "0",
                  marginRight: pageLoad ? "-200px" : "-500px",
                  transition: "all 0.8s ease-in-out",
                }}
              />
            </div>
          ) : null} */}
        </div>
      </div>

      <div
        ref={sectionRef}
        style={{
          position: "relative",
          height: screenWidth > 1200 ? "250px" : "80vh",
          marginTop: "20px",
          boxShadow: isInView
            ? "0px -4px 15px rgba(0,0,0,0.1)"
            : "0px -4px 15px rgba(0,0,0,0)",
          transition: "1s all ease-in-out",
          transitionDelay: "1s",
        }}
      >
        <div
          style={{
            background: "#2d80c3",
            width: "100%",
            height: screenWidth > 1200 ? "250px" : "80vh",
            position: "absolute",
            top: 0,
            left: isInView ? 0 : "100%",
            transition: "1s all ease-in-out",
          }}
        >
          <div
            style={{
              background: "rgba(256,256,256,0.8)",
              width: "100%",
              height: screenWidth > 1200 ? "250px" : "80vh",
              position: "absolute",
              top: 0,
              transition: "1s all ease-in-out",
              transitionDelay: "1s",
              display: screenWidth > 1200 ? "flex" : "flex",
              flexWrap: screenWidth > 1200 ? "default" : "wrap",
              alignItems: screenWidth > 1200 ? "flex-start" : "center",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              padding: "60px 10%",
              gap: screenWidth > 1200 ? "20px" : "10px",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "18%" : "calc(50% - 5px)",
                opacity: isInView ? 1 : 0,
                marginLeft: isInView ? -30 : 0,
                transition: "0.4s all ease-in-out",
                transitionDelay: "1s",
              }}
            >
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 800,
                  fontSize: "60px",
                }}
              >
                20+
              </div>
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "14px",
                  width: "80%",
                }}
              >
                And more alternative lenders on-board
              </div>
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "22%" : "calc(50% - 5px)",
                opacity: isInView ? 1 : 0,
                marginLeft: isInView ? -30 : 0,
                transition: "0.4s all ease-in-out",
                transitionDelay: "1.2s",
              }}
            >
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 800,
                  fontSize: "60px",
                }}
              >
                2000+
              </div>
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "14px",
                  width: "80%",
                }}
              >
                SME's Success Stories
              </div>
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "15%" : "calc(50% - 5px)",
                opacity: isInView ? 1 : 0,
                marginLeft: isInView ? -30 : 0,
                transition: "0.4s all ease-in-out",
                transitionDelay: "1.4s",
              }}
            >
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 800,
                  fontSize: "60px",
                }}
              >
                5<StarIcon style={{ color: "#edb600", fontSize: "80px" }} />
              </div>
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
              >
                Google Review
              </div>
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "18%" : "calc(50% - 5px)",
                opacity: isInView ? 1 : 0,
                marginLeft: isInView ? -30 : 0,
                transition: "0.4s all ease-in-out",
                transitionDelay: "1.4s",
              }}
            >
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 800,
                  fontSize: "60px",
                  height: "70px",
                  overflow: "hidden",
                  marginTop: "5px",
                  marginBottom: "15px",
                }}
              >
                <ArticleIcon
                  style={{ color: "rgb(37, 97, 153)", fontSize: "80px" }}
                />
              </div>
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
              >
                Complementary{" "}
                <span style={{ fontWeight: 800, color: "#edb600" }}>
                  CBS Report
                </span>{" "}
                &{" "}
                <span style={{ fontWeight: 800, color: "#edb600" }}>
                  Free Home Valuation
                </span>
              </div>
            </div>
            <div
              style={{
                opacity: isInView ? 1 : 0,
                marginLeft: screenWidth > 1200 ? (isInView ? 30 : 60) : 0,
                transition: "0.4s all ease-in-out",
                transitionDelay: "1.6s",
                height: "130px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: screenWidth > 1200 ? "auto" : "100%",
              }}
            >
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
              >
                And most importantly, it's
              </div>
              <div
                style={{
                  color: "rgb(37, 97, 153)",
                  fontWeight: 800,
                  fontSize: "45px",
                  lineHeight: "45px",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Completely Free!
              </div>
              <button
                className="menu_button"
                onClick={() => {
                  history.push("/auth/signin");
                  window.scrollTo(0, 0);
                }}
                style={{
                  background: "#256199",
                  color: "white",
                  fontSize: screenWidth > 1200 ? "17px" : "14px",
                  padding: screenWidth > 1200 ? "5px 25px" : "7px 20px",
                  margin: "0px 20px 0px 0px",
                  borderRadius: "30px",
                  fontWeight: "800",
                  border: "none",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={sectionRef1}
        style={{
          position: "relative",
          height: "650px",
          boxShadow: isInView1
            ? "0px -6px 15px rgba(0,0,0,0.2)"
            : "0px -4px 15px rgba(0,0,0,0)",
          transition: "1s all ease-in-out",
          transitionDelay: "1s",
        }}
      >
        <div
          style={{
            background: "#2d80c3",
            width: "100%",
            height: "650px",
            position: "absolute",
            top: 0,
            left: isInView1 ? 0 : "-100%",
            transition: "1s all ease-in-out",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "1200px",
              width: "40px",
              position: "absolute",
              top: isInView1 ? 30 : -20,
              left: isInView1 ? 70 : -20,
              opacity: isInView1 ? 1 : 0,
              transform: "translate(-50%, -50%) rotate(30deg)",
              background: "white",
              transition: "0.3s all ease-in-out",
              transitionDelay: "1s",
            }}
          />
          <div
            style={{
              height: "1200px",
              width: "40px",
              position: "absolute",
              top: isInView1 ? 30 : -20,
              left: isInView1 ? 150 : 100,
              opacity: isInView1 ? 1 : 0,
              transform: "translate(-50%, -50%) rotate(30deg)",
              background: "white",
              transition: "0.3s all ease-in-out",
              transitionDelay: "1.2s",
            }}
          />
          <div
            style={{
              height: "1200px",
              width: "40px",
              position: "absolute",
              top: isInView1 ? "95%" : "105%",
              left: isInView1 ? "85%" : "105%",
              opacity: isInView1 ? 1 : 0,
              transform: "translate(-50%, -50%) rotate(30deg)",
              background: "white",
              transition: "0.3s all ease-in-out",
              transitionDelay: "1.4s",
            }}
          />
          <div
            style={{
              height: "1200px",
              width: "40px",
              position: "absolute",
              top: isInView1 ? "95%" : "105%",
              left: isInView1 ? "89.9%" : "105%",
              opacity: isInView1 ? 1 : 0,
              transform: "translate(-50%, -50%) rotate(30deg)",
              background: "white",
              transition: "0.3s all ease-in-out",
              transitionDelay: "1.6s",
            }}
          />
          <div
            style={{
              width: "350px",
              position: "absolute",
              top: isInView1 ? "60%" : "50%",
              left: isInView1 ? "10%" : "10%",
              transform: "translate(0, -50%)",
              background: "white",
              transition: "30s all ease-in-out",
              transitionDelay: "1.6s",
              borderRadius: "20px",
              boxShadow: "5px 5px 15px rgba(0,0,0,0.2)",
              padding: "30px",
            }}
          >
            <img
              src={youImage}
              style={{
                position: "absolute",
                top: "170px",
                left: "40%",
                transform: "translate(-50%, -100%)",
                zIndex: 100,
                height: "300px",
                width: "300px",
              }}
            />
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 800,
                fontSize: "25px",
                lineHeight: "25px",
                marginTop: "150px",
                marginBottom: 10,
              }}
            >
              Empowering SMEs with More Than Just Matching
            </div>
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14px",
              }}
            >
              At SmartLend, we're creating an ecosystem to empower SMEs with
              essential tools and resources. Our innovative Business Loan
              Aggregator addresses your financing needs, helping you achieve
              your goals faster. This is just the start—exciting new products
              are on the horizon!
            </div>
          </div>
          <div
            style={{
              width: "350px",
              position: "absolute",
              top: isInView1 ? "50%" : "60%",
              left: isInView1 ? "calc(10% + 420px)" : "calc(10% + 420px)",
              transform: "translate(0, -50%)",
              background: "white",
              transition: "30s all ease-in-out",
              transitionDelay: "1.6s",
              borderRadius: "20px",
              boxShadow: "5px 5px 15px rgba(0,0,0,0.2)",
              padding: "30px",
            }}
          >
            <img
              src={thinkingImage}
              style={{
                position: "absolute",
                top: "170px",
                left: "40%",
                transform: "translate(-50%, -100%)",
                zIndex: 100,
                height: "330px",
                width: "330px",
              }}
            />
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 800,
                fontSize: "25px",
                lineHeight: "25px",
                marginTop: "150px",
                marginBottom: 10,
              }}
            >
              Why Only Alternative Lenders?
            </div>
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14px",
              }}
            >
              Onboarding alternative lenders empowers our platform to cater to
              the diverse financing needs of SMEs, providing accessible credit
              solutions when traditional banks reject them or they require
              short- term bridging loans, ensuring they avoid exploitative rates
              by streamlining their access to alternative lending options.
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              left: "calc(10% + 840px)",
              transform: "translate(0, -50%)",
              top: "50%",
              fontWeight: 800,
              fontSize: "60px",
              lineHeight: "60px",
              color: "white",
            }}
          >
            <div>Your</div>
            <div style={{ color: "#edb600" }}>Financial Partner</div>
            <div>
              Not Just <span style={{ color: "#edb600" }}>Matching.</span>
            </div>

            <button
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                color: "white",
                background: "#edb600",
                fontSize: screenWidth > 1200 ? "17px" : "14px",
                lineHeight: screenWidth > 1200 ? "20px" : "17px",
                padding: screenWidth > 1200 ? "10px 25px" : "7px 20px",
                margin: "0px 20px 0px 0px",
                borderRadius: "30px",
                fontWeight: "800",
                border: "none",
                marginTop: "20px",
                cursor: "pointer",
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: screenWidth > 1200 ? "80px" : "0px",
          background: "white",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: "0px 10%",
              marginTop: screenWidth > 1200 ? "30px" : "0px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: screenWidth > 1200 ? "30px" : "0px",
                // alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={one}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "40px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div style={{ width: screenWidth > 1200 ? "70%" : "100%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Unlock the Power of Smart Borrowing
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Say goodbye to the headaches of dealing with Relationship
                    Managers and brokers for your business loan needs. We get it
                    – navigating the financing landscape can be a maze. How can
                    you be certain you're securing the best deals?
                    <br />
                    <br />
                    Introducing{" "}
                    <span
                      style={{
                        fontWeight: 800,
                        color: "rgba(0,0,0,1)",
                        fontSize: 17,
                      }}
                    >
                      SMARTLEND
                    </span>
                    . , your compass in the world of SME financing. We've
                    revolutionized the lending game, matching you with
                    alternative lending options tailored for your business. No
                    more guessing, no more endless negotiations.
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "#256199",
                    border: " 2px solid #256199",
                    background: "white",
                    fontSize: screenWidth > 1200 ? "20px" : "15px",
                    padding: "7px 35px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "10px",
                    fontWeight: "600",
                  }}
                >
                  Register as Borrower
                </div>
              </div>
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "40%" : "100%",
                marginTop: screenWidth > 1200 ? "0px" : "70px",
              }}
            >
              <img
                src={verified}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          background: "white",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              marginTop: "0px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "40%" : "100%" }}>
              <img
                src={mutual_agreement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  marginTop: screenWidth > 1200 ? "0px" : "70px",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // marginTop: "30px",
                // alignItems: "flex-start",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      // background: "#256199",
                      // color: "white",
                      // height: "60px",
                      // width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // fontSize: "25px",
                      // borderRadius: "10px",
                      // marginRight: "15px",
                    }}
                  >
                    <img
                      src={two}
                      style={{
                        height: "600px",
                        position: "absolute",
                        objectFit: "contain",
                        marginTop: "-250px",
                        right: "600px",
                      }}
                    />
                    {/* 1 */}
                  </div>
                  <div
                    style={{
                      width: "90%",
                      marginTop: screenWidth > 1200 ? "0px" : "20px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "30px",
                        fontWeight: "800",
                        color: "#256199",
                      }}
                    >
                      Empowering Your Business: Bringing Negotiating Power Back
                      to You!
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,0.8)",
                        marginTop: "5px",
                      }}
                    >
                      Tired of feeling at the mercy of lenders and brokers? We
                      hear you. It's time to take control of your business
                      financing.
                      <br /> <br />
                      SMARTLEND. is here to revolutionize your borrowing
                      experience, putting the negotiating power back where it
                      belongs – in your hands. No more one-sided deals, no more
                      uncertainty.
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    className="menu_button"
                    onClick={() => {
                      history.push("/auth/signin");
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      color: "#256199",
                      border: " 2px solid #256199",
                      background: "white",
                      fontSize: screenWidth > 1200 ? "20px" : "15px",
                      padding: "7px 35px",
                      margin: screenWidth > 1200 ? "0px 0px 0px 0px" : "0px",
                      borderRadius: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Register as Borrower
                  </div>
                  {/* <div
            style={{
              background: "#256199",
              color: "white",
              fontSize: "20px",
              padding: "7px 35px",
              margin: "0px 20px 0px 0px",
              borderRadius: "30px",
            }}
          >
            Be a Lender
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "50px 0px",
          background: "white",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "50%" : "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={three}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "-200px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div
                  style={{
                    width: screenWidth > 1200 ? "90%" : "100%",
                    marginTop: screenWidth > 1200 ? "0px" : "20px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Mutual Agreement
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Your Loan Experience Elevated.
                    <br /> <br />
                    Introducing our revolutionary single dashboard, designed to
                    simplify and elevate your loan journey. Now, effortlessly
                    track your loan application progress, monitor monthly
                    installments, and enjoy the convenience of timely reminders
                    for upcoming repayments.
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div
                      className="menu_button"
                      onClick={() => {
                        history.push("/auth/signin");
                        window.scrollTo(0, 0);
                      }}
                      style={{
                        color: "#256199",
                        border: " 2px solid #256199",
                        background: "white",
                        fontSize: screenWidth > 1200 ? "20px" : "15px",
                        padding: "7px 35px",
                        margin: screenWidth > 1200 ? "0px 20px 0px 0px" : "0px",
                        borderRadius: "10px",
                        fontWeight: "600",
                      }}
                    >
                      Register as Borrower
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: screenWidth > 1200 ? "50%" : "100%" }}>
              <img
                src={disbursement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "120%",
                  objectFit: "contain",
                  marginTop: screenWidth > 1200 ? "-190px" : "30px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*Moving Image Section*/}
      <div
        style={{
          width: "100%",
          backgroundColor: "rgb(45, 128, 195)",
          padding: "10px 0px",
          position: "relative",
          zIndex: 201,
        }}
      >
        <div
          className="move-right"
          style={{
            display: "flex",
            width: "200%",
            fontWeight: 800,
            fontSize: "20px",
          }}
        >
          <div style={{ color: "white", marginRight: 7 }}>
            Apply Once, Access All.{" "}
          </div>
          <div style={{ color: "white", marginRight: 7 }}>
            Apply Once, Access All.{" "}
          </div>
          <div style={{ color: "white", marginRight: 7 }}>
            Apply Once, Access All.{" "}
          </div>
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
          {screenWidth > 1200 ? (
            <div style={{ color: "white", marginRight: 7 }}>
              Apply Once, Access All.{" "}
            </div>
          ) : null}
        </div>
      </div>

      {/* Second section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingBottom: "80px",
          paddingTop: "80px",
          background: "white",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "85%" : "90%" }}>
          <div
            style={{
              textAlign: "center",
              fontSize: screenWidth > 1200 ? "55px" : "50px",
              fontWeight: "700",
              color: "rgba(0,0,0,0.8)",
              lineHeight: screenWidth > 1200 ? "100px" : "50px",
            }}
          >
            How SmartLend Works
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: screenWidth > 1200 ? "0px" : "30px",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "70%" : "100%",
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "400",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              <span
                style={{
                  fontWeight: 800,
                  color: "rgba(0,0,0,1)",
                  fontSize: 17,
                }}
              >
                5 Step{" "}
              </span>{" "}
              &
              <span
                style={{
                  fontWeight: 800,
                  color: "rgba(0,0,0,1)",
                  fontSize: 17,
                }}
              >
                {" "}
                under 5 minutes
              </span>{" "}
              Seamless application. Our goal is to reduce the need for users to
              manually input information that can be obtained through Myinfo
              Business.
            </div>
          </div>
          <div
            style={{
              padding: "0px 10%",
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  1
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Register your account:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Create your SmartLend account quickly and securely with 2FA
                    to begin your seamless borrowing journey.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  2
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Apply via Singpass:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Use MyInfo Business for a quick, hassle-free process—no data
                    entry, just a few clicks.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  3
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Upload basic financial documents:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Attached your business's Latest 6 Months Bank Statements
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  4
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Complimentary Credit Bureau Report
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Access and Upload your free Personal Credit Bureau Report
                    seamlessly through our secure platform
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  5
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Viola! Choose your offer:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Enjoy the simplicity of selecting from multiple tailored
                    loan offers, empowering you to make the best financial
                    decision for your business with SmartLend.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: screenWidth > 1200 ? "flex-start" : "center",
                  width: "100%",
                }}
              >
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "#256199",
                    border: " 2px solid #256199",
                    background: "white",
                    fontSize: screenWidth > 1200 ? "20px" : "13px",
                    padding: screenWidth > 1200 ? "7px 35px" : "7px 20px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "20px",
                    fontWeight: "600",
                  }}
                >
                  Apply now
                </div>
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    background: "#256199",
                    color: "white",
                    fontSize: screenWidth > 1200 ? "20px" : "13px",
                    padding: screenWidth > 1200 ? "7px 35px" : "7px 20px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "20px",
                  }}
                >
                  Be a Borrower
                </div>
              </div>
            </div>
            {screenWidth > 1200 ? (
              <div
                style={{
                  width: "30%",
                  height: "100%",
                  objectFit: "contain",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={singpassmobilepage}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    maxHeight: "700px",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: screenWidth > 1200 ? "row" : "column",
          padding:
            screenWidth > 1200 ? "60px 15% 180px 15%" : "60px 10% 180px 10%",
          position: "relative",
          zIndex: 201,
          background: "white",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "45%" : "100%" }}>
          <div
            style={{
              color: "rgb(37, 97, 153)",
              fontWeight: 800,
              fontSize: screenWidth > 1200 ? "55px" : "40px",
              lineHeight: screenWidth > 1200 ? "60px" : "45px",
              marginTop: screenWidth > 1200 ? "15px" : "5px",
            }}
          >
            You asked, we answered.
          </div>
          <div
            style={{
              color: "rgb(120,120,120)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
              marginTop: "15px",
            }}
          >
            Can't find your question? View all our{" "}
            <span
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                textDecoration: "underline",
                color: "#9bc7eb",
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              FAQ’s
            </span>
            , chat with us on
            <span
              onClick={() => {
                window.location.href = "https://wa.me/6588288147";
              }}
              style={{
                textDecoration: "underline",
                color: "#9bc7eb",
                fontWeight: 600,
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              WhatsApp
            </span>
            , or email us via: hello@smart-lend.com
          </div>
        </div>
        <div
          style={{
            width: screenWidth > 1200 ? "45%" : "100%",
            marginTop: screenWidth > 1200 ? "0px" : "15px",
          }}
        >
          {" "}
          {faqs.map((faq, index) => (
            <FAQItem
              key={faq.id}
              id={faq.id}
              title={faq.title}
              answer={faq.answer}
              index={index}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#dcebf7",
          padding: "70px 0px 70px 0px",
          zIndex: 201,
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            color: "rgb(37, 97, 153)",
            fontWeight: 800,
            fontSize: "30px",
            paddingTop: "15px",
          }}
        >
          We Compare Singapore’s Most Reputable Alternative Lenders
        </div>

        <div
          className="move-right"
          style={{ display: "flex", width: "200%", marginTop: "20px" }}
        >
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              marginLeft: "20px",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              marginLeft: "20px",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              marginLeft: "20px",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: screenWidth > 1200 ? "row" : "column",
          alignItems: "center",
          background: "white",
          position: "relative",
          zIndex: 201,
          padding: "60px 10%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ width: "45%", height: screenWidth > 1200 ? "65vh" : "80vw" }}
        >
          <img
            src={icon15}
            style={{
              objectFit: "cover",
              height: screenWidth > 1200 ? "65vh" : "80vw",
              width: screenWidth > 1200 ? "45%" : "80vw",
              position: "absolute",
              top: `calc(50px + ${var1 * 5}px)`,
              left: `calc(5% + ${var2 * 5}px)`,
              transform: `rotate(${var3 * 1}deg)`,
              transition: "3s all",
            }}
          />
        </div>
        <div style={{ width: screenWidth > 1200 ? "45%" : "100%" }}>
          <div
            style={{
              color: "#9bc7eb",
              fontWeight: 800,
              fontSize: "25px",
              lineHeight: "25px",
            }}
          >
            Coming soon
          </div>
          <div
            style={{
              color: "rgb(37, 97, 153)",
              fontWeight: 800,
              fontSize: "55px",
              lineHeight: "60px",
              marginTop: "15px",
            }}
          >
            Property Equity
          </div>
          <div
            style={{
              color: "rgb(37, 97, 153)",
              fontWeight: 800,
              fontSize: "55px",
              lineHeight: "60px",
            }}
          >
            Term Loan.
          </div>
          <div
            style={{
              color: "rgb(120,120,120)",
              marginTop: "15px",
              fontSize: "18px",
              lineHeight: "30px",
              fontWeight: 700,
            }}
          >
            Access Up to 85% of Your Home Equity in Cash for Your
          </div>
          <div
            style={{
              color: "rgb(120,120,120)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
            }}
          >
            Business—No TDSR Assessment Required! Unlock the potential of your
            property with our Property Equity Term Loan! You can access up to
            85% of your home equity in cash to fuel your business growth, all
            without
          </div>
          <div
            style={{
              color: "rgb(120,120,120)",
              fontSize: "18px",
              lineHeight: "30px",
              fontWeight: 700,
              marginTop: "10px",
            }}
          >
            the hassle TDSR assessment.
          </div>
          <div
            style={{
              color: "rgb(120,120,120)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
            }}
          >
            Instant Equity Access at Your FingertipsWith our Smart Valuation
            tools, determining your cash-out equity is a breeze. Simply scan
            your SingPass, and get an instant assessment of how much you can
            unlock from your property.
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#dcebf7",
          padding: "60px 0px",
          width: "100%",
          textAlign: "center",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: screenWidth > 1200 ? "25px" : "20px",
              fontWeight: "700",
              marginBottom: "10px",
              color: "rgb(37, 97, 153)",
            }}
          >
            Weekly newsletter subscription
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
              color: "rgb(37, 97, 153)",
              fontSize: screenWidth > 1200 ? "inherit" : "14px",
            }}
          >
            Subscibe to Smartlend's weekly newsletter for exclusive insights and
            tips to help your SME unlock financing opportunities!
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              marginRight: "0px",
              width: screenWidth > 1200 ? "15%" : "50%",
            }}
          >
            <input
              type="email"
              placeholder="abc@gmail.com"
              id="promoCode"
              value={emailSubscription}
              onChange={handleInputChange}
              style={{
                borderColor: isEmailValid ? "initial" : "red",
                outlineColor: isEmailValid ? "initial" : "red",
                borderRadius: "15px 0px 0px 15px",
                padding: "5px 10px",
                fontSize: "14px",
                width: "100%",
              }} // Change border color based on validation
            />
          </div>
          <div
            className="menu_button"
            onClick={handleEmailSubscription}
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "white",
              border: "2px solid #256199",
              background: "#256199",
              padding: "5px 40px",
              borderRadius: "0px 15px 15px 0px",
            }}
          >
            Subscribe
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: screenWidth > 1200 ? "inherit" : "14px",
          }}
        >
          {!isEmailValid && (
            <p style={{ color: "red" }}>
              Please enter a valid email address. "@gmail.com"
            </p>
          )}{" "}
          {/* Show error message if email is invalid */}
          {/* {isResponseStatusValid && <p style={{ color: 'red' }}>{responseErrorMessage}</p>} */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: screenWidth > 1200 ? "inherit" : "14px",
            color: "rgb(37, 97, 153)",
          }}
        >
          By clicking subscribe, I agree to SmartLend terms and conditions.
        </div>
      </div>

      {/* Section three */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "150px",
        }}
      >
        <div
          style={{
            width: "85%",
            background: "#256199",
            borderRadius: "20px",
            padding: "40px 0px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "55px",
              fontWeight: "700",
              color: "white",
              lineHeight: "100px",
            }}
          >
            How does it works?
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "40px",
              textAlign: "center",
              padding: "30px 40px",
            }}
          >
            <div style={{ width: "24%" }}>
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 1
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "24%" }}>
              {" "}
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 2
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "24%" }}>
              {" "}
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 3
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "24%" }}>
              {" "}
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 4
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                color: "#256199",
                border: " 2px solid #256199",
                background: "white",
                fontSize: "20px",
                padding: "7px 35px",
                margin: "0px 20px 0px 0px",
                borderRadius: "30px",
                fontWeight: "700",
              }}
            >
              Get Started
            </div>
          </div>
        </div>
      </div> */}

      {/* Section four testimonial */}
      {/* <div
        style={{
          background: "#f2f2f2",
          padding: "40px 0px",
          marginTop: "50px",
          width: "100%",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "55px",
            fontWeight: "700",
            color: "rgba(0,0,0,0.8)",
            lineHeight: "100px",
          }}
        >
          Don't take our word for it.
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "60%",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "400",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "25px",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "800",
                fontSize: "25px",
                color: "rgba(0,0,0,0.8)",
                padding: "0px 10px",
              }}
            >
              #SmartLend
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "20px",
                color: "rgba(0,0,0,0.2)",
                padding: "0px 10px",
              }}
            >
              #SmartBorrowers
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "20px",
                color: "rgba(0,0,0,0.2)",
                padding: "0px 10px",
              }}
            >
              #SmartLenders
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexWrap: "wrap", width: "80%" }}>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Blog section */}
      {/* <div style={{ padding: "50px 0px" }}> */}
      {/* first segment */}
      {/* <div
          style={{
            padding: "35px 0px",
            textAlign: "center",
            background: "#256199",
            padding:"60px 0px"
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "55px",
              fontWeight: "700",
              color: "white",
            //   lineHeight: "100px",
            }}
          >
            Our articles
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%", marginTop:"10px" }}
          >
            <div style={{ fontSize: "15px", fontWeight: "300", width: "70%", color:"white", width:"60%" }}>
              Here you will find answers to some of the most commonly asked
              questions about our work management platform, Premelo. If you have
              any additional questions that are not covered here, please do not
              hesitate to contact our support team for further assistance.
            </div>
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="🔍 Search by title, news and more"
            style={{
              border: "none",
              outline: "none",
              padding: "8px 20px",
              textAlign: "center",
              marginTop: "20px",
              borderRadius: "25px",
              height: "30px",
              width: "30%",
            }}
          />
        </div> */}
      {/* <div
          style={{
            textAlign: "center",
            fontSize: "55px",
            fontWeight: "700",
            color: "rgba(0,0,0,0.8)",
            lineHeight: "100px",
          }}
        >
          Latest news
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {filteredFaqs.slice(0, 8).map((faq) => (
              <FAQItem
                key={faq.id}
                id={faq.id}
                title={faq.title}
                answer={faq.answer}
              />
            ))}
          </div>
        </div> */}
      {/* </div> */}

      {/* Footer section */}
      <div
        style={{
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
          background: "white",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            Smart Towkay Ventures Pte. Ltd., SmartLend Financial Pte. Ltd., are
            part of the Smart Towkay Brand.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "3px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <img
              className="menu_button"
              src={tiktokIcon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              Enterprise
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "15px" }}
            >
              SmartLend for Business
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              SmartAds
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              Express Loan
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
          position: "relative",
          zIndex: 200,
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "30%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Pte Ltd 2023</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};
export default Homepage;
