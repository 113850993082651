import { Space, Table, Tag, Radio, Button } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const TableAdminLoanRequest = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [loanIdFilters, setLoanIdFilters] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewLoanRequest",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const flatData = json["Requested Loan"].flat();
        let loans = [];
        for (const data of flatData) {
          if (data.status === "pending") {
            loans.push(data);
          }
        }
        setData(loans);

        const uniqueLoanIds = new Set();
        flatData.forEach((loan) => {
          uniqueLoanIds.add(loan.loan_id);
        });

        const filters = Array.from(uniqueLoanIds).map((loanId) => ({
          text: loanId,
          value: loanId,
        }));

        setLoanIdFilters(filters);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isExpired = (dateString) => {
    // Split the date string into its components
    const [year, month, day] = dateString.split('-').map(Number);
  
    // Create a new Date object using the components
    const expiredDate = new Date(year, month - 1, day); // month is 0-indexed
  
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
  
    return currentDate >= expiredDate;
  };
  
  
  useEffect(() => {
    console.log(isExpired("2023-10-02")); // This should return true or false based on the current date.

  });

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Loan ID
        </span>
      ),
      dataIndex: "loan_id",
      key: "loan_id",
      // filters: loanIdFilters,
      // filterMode: 'tree',
      // filterSearch: true,
      // onFilter: (value, record) => record.loan_id.startsWith(value),
      // width: '24%',
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Borrower Name
        </span>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Amount
        </span>
      ),
      dataIndex: "request_amount",
      key: "request_amount",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Period
        </span>
      ),
      dataIndex: "request_period",
      key: "request_period",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text + " Months"}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Interest Rate
        </span>
      ),
      dataIndex: "request_interest_rate",
      key: "request_interest_rate",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${(text * 100).toFixed(1)}%` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Total Interest
        </span>
      ),
      dataIndex: "total_interest",
      key: "total_interest",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Admin Fee
        </span>
      ),
      dataIndex: "admin_fee",
      key: "admin_fee",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${(text * 100).toFixed(1)}%`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Monthly Installment
        </span>
      ),
      dataIndex: "request_monthly_installment",
      key: "request_monthly_installment",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Count Loan Offered
        </span>
      ),
      dataIndex: "loan_offer_count",
      key: "loan_offer_count",
      align: "center",
      fixed: "right",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text + " Offering"}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="loan_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        rowClassName={(record) => (isExpired(record.expired_date) ? 'expired-row' : '')}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        scroll={{
          x: 1100,
        }}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "100%",
        }}
      />
    </div>
  );
};

export default TableAdminLoanRequest;
